
const edit = {
    fields: [
        {
            type: "mixed",
            name: "android",
            label: "Android",
            elements: [
                {
                    type: "text",
                    name: "main_app",
                    label: "Android Main App Minimum Version",
                }, {
                    type: "text",
                    name: "main_app_link",
                    label: "Android Main Play Store Link",
                },
                {
                    type: "text",
                    name: "driver_app",
                    label: "Android Driver App Minimum Version",
                }, {
                    type: "text",
                    name: "driver_app_link",
                    label: "Android Driver Play Store Link",
                },
            ],
        }, {
            type: "mixed",
            name: "ios",
            label: "IOS",
            elements: [
                {
                    type: "text",
                    name: "main_app",
                    label: "IOS Main App Minimum Version",
                }, {
                    type: "text",
                    name: "main_app_link",
                    label: "IOS Main App Store Link",
                },
                {
                    type: "text",
                    name: "driver_app",
                    label: "IOS Driver App Minimum Version",
                }, {
                    type: "text",
                    name: "driver_app_link",
                    label: "IOS Driver App Store Link",
                },
            ],
        }
    ],
    main: true,
    breadcrumb: 'Application Version Settings',
    title: 'Application Version Settings',
};

const data = { edit }

export default data;