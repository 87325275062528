const list = {
    header: [
        {
            id: 'title.en',
            label: 'Class Name',
            orderBy: 'title.en',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'title.ar',
            label: 'Ar Name',
            orderBy: 'title.ar',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'image',
            display: true,
            label: 'Image',
            align: 'left',
        },
       
        {
            id: 'luggage_capacity',
            label: 'Luggage capacity',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true,
        }
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image",
        },
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: 'number',
            name: 'seat_number',
            label: 'Seat number'
        },
        {
            type: 'number',
            name: 'luggage_capacity',
            label: 'Luggage capacity'
        }
    ],
    permission: 'show'
};
const create = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image",
            required: true,
        }, {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: 'number',
            name: 'seat_number',
            label: 'Seat number',
            required: true
        },
        {
            type: 'number',
            name: 'luggage_capacity',
            label: 'Luggage capacity',
            required: true
        }
    ],
    permission: 'create'
};
const edit = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image",
        },
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: 'number',
            name: 'seat_number',
            label: 'Seat number',
            required: true
        },
        {
            type: 'number',
            name: 'luggage_capacity',
            label: 'Luggage capacity',
            required: true
        }
    ],
    permission: 'edit'
};


const search = {
    fields: [
        {
            type: "text",
            name: "title.en",
        }
    ]
}

const filter = {
    fields: [
      
        {
            type: 'text',
            name: 'title:en',
            label: 'Class Name'
        },
        {
            type: 'text',
            name: 'title:ar',
            label: 'Ar Name'
        },
        {
            type: 'text',
            name: 'luggage_capacity',
            label: 'Luggage Capacity'
        },

        {
            type: 'select',
            name: 'publish',
            label: 'Publish',
            values: [
                {value: true, label: 'Published'},
                {value: false, label: 'Not Published'},
            ]
        },
    ]
}

const csv = {
    headers: list.header.map(item => ({
      key: item.id,
      label: item.label,
    }))
  };


const data = { list, create, edit, show, search, csv, filter }

export default data;