import React, { Suspense } from "react";

const DynamicComponent = ({ name, parent }) => {
  // Dynamically import the component
  const DynamicComponent = React.lazy(() =>
    import(`../pages/${parent}/${name}.js`)
  );

  return (
    <Suspense fallback={<div></div>}>
      <DynamicComponent />
    </Suspense>
  );
};

export default DynamicComponent;
