// other imports
import Icon from '../../layouts/Icon';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import PermissionsManager from '../../layouts/PermissionsManager';
import { list, create, remove } from '../../../features/blackout/actions';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';

// imports for calendar
import dayjs from 'dayjs';
import ServerDay from '../../inputs/ServerDay';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const initialValue = dayjs();


const Index = () => {
    // other varibles
    const page = 'Blackout';
    const navigate = useNavigate();

    // Dialog variables
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    // redux variables
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { items, itemsLoading } = useSelector((state) => state[page.toString().toLowerCase()]);

    // add Blackout form variables
    const [newBlackout, setNewBlackout] = useState(dayjs());

    // Calendar variables
    const requestAbortController = useRef(null);
    const [highlightedDays, setHighlightedDays] = useState([]);

    // retrieve Data on
    // screen load
    useEffect(() => {
        if (itemsLoading) {
            dispatch(list({ filter: { startDate: dayjs().startOf('month'), endDate: dayjs().endOf('month') } }));
        }
    }, [itemsLoading])

    // map data to be as needed for the calendar component
    useEffect(() => {
        const controller = new AbortController();
        if (items && items?.length) {
            const daysToHighlight = items.map(el => parseInt(dayjs(el.blackout_day).format('DD')));
            setHighlightedDays(daysToHighlight);
            requestAbortController.current = controller;
        }
        return () => requestAbortController.current?.abort();
    }, [items]);

    // Function that handles Calendar
    // month change
    const handleDateChange = (date) => {
        if (requestAbortController.current) {
            // make sure that you are aborting useless requests
            // because it is possible to switch between months pretty quickly
            requestAbortController.current.abort();
        }
        setHighlightedDays([]);
        dispatch(list({ filter: { startDate: date.startOf('month'), endDate: date.endOf('month') } }));
    };

    // Function that handles 
    // adding a new Blackout day
    const handleAdd = (e) => {
        e.preventDefault();
        const blackout_day = newBlackout.format('YYYY-MM-DD');
        const data = {
            blackout_day,
            startDate: dayjs().startOf('month'),
            endDate: dayjs().endOf('month')
        };
        dispatch(create({ data, navigate }));
        setNewBlackout(dayjs());
    }

    const handleDayclick = (day) => {
        setOpen(true);
        setSelectedItem(day);
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedItem(null);
    }

    const handleConfirm = () => {
        const selectedDay = dayjs(selectedItem.blackout_day);
        const startDate = selectedDay.startOf('month');
        const endDate = selectedDay.endOf('month');
        dispatch(remove({ id: selectedItem.id, startDate, endDate }));
        setOpen(false);
        setSelectedItem(null);
    }

    return user?.Permissions && <PermissionsManager action="list" name={page.toLowerCase()} permissions={user?.Permissions}>
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            {/* Delete modal */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 0 }}
            >
                <DialogTitle id="alert-dialog-title" >
                    Confirm
                </DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this Blackout day?
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button onClick={handleClose}>Keep</Button>
                    <Button onClick={handleConfirm} autoFocus color="error">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Screen */}
            <Box height='100%'>
                <Breadcrumbs page={page.toString()} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={2}>

                        {/* Title */}
                        <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: 'start', sm: 'center' }}>
                            <Typography variant='h5' mr={1}>
                                {page}
                            </Typography>
                        </Stack>

                        {/* Add new Blackout day */}
                        {user?.Permissions &&
                            <PermissionsManager action="form" name={page.toLowerCase()} permissions={user?.Permissions} isComponent={true}>
                                <Stack direction="row" spacing={2} mt={{ xs: 2, sm: 0 }} alignSelf={{ xs: 'end', sm: 'center' }}>
                                    <Stack className='form-control' mb={2} direction='row' justifyContent="flex-end" alignItems="center" component="form" onSubmit={handleAdd}>
                                        <DatePicker
                                            required
                                            value={newBlackout}
                                            className='form-input'
                                            disablePast
                                            disabled
                                            label="New blackout day"
                                            format="YYYY-MM-DD"
                                        />
                                        <Button type="submit" variant="contained" startIcon={<Icon type={'Add'} />} sx={{ borderRadius: 0, textTransform: "none", marginLeft: 2 }}>
                                            Add
                                        </Button>
                                    </Stack>
                                </Stack>
                            </PermissionsManager>
                        }
                    </Stack>

                    {/* Calendar */}
                    <div style={{ position: 'absolute', width: '55%', height: '70%' }}>
                        <DateCalendar
                            defaultValue={initialValue}
                            loading={itemsLoading}
                            className={[{
                                width: '100% !important',
                                height: '80% !important'
                            }]}
                            disablePast
                            onYearChange={handleDateChange}
                            onMonthChange={handleDateChange}
                            onChange={(date) => {setNewBlackout(date)}}
                            renderDay={(day, _, options) => (
                                <div style={{ background: 'blue', color: 'white' }}>
                                    {options.dayLabel}
                                </div>
                            )}
                            renderLoading={() => <DayCalendarSkeleton />}
                            slots={{
                                day: (params) => <ServerDay
                                    items={items}
                                    handleDayclick={handleDayclick}
                                    {...params}
                                />,
                            }}
                            slotProps={{
                                day: {
                                    highlightedDays,
                                },
                            }}
                        />
                    </div>
                </LocalizationProvider>

            </Box>
        </div>
    </PermissionsManager>
}

export default Index;