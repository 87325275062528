const list = {
    header: [
        {
            id: 'title.en',
            label: 'Title',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'lat',
            label: 'Latitude',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'lng',
            label: 'Longitude',
            align: 'left',
            fallback: 'N/A'
        }
    ],
    permissions: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: 'mixed',
            name: 'title',
            label: 'Title',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                }
            ]
        },
        {
            type: 'number',
            name: 'lat',
            label: 'Latitude'
        },
        {
            type: 'number',
            name: 'lng',
            label: 'Longitude',
        },
    ],
    permission: 'show'
};
const create = {
    fields: [
        {
            type: 'mixed',
            name: 'title',
            label: 'Title',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    required: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    required: true
                }
            ]
        },
        {
            type: "map",
            name: "location",
            label: "Location",
            required: true,
            long: {
                name: 'long',
                label: 'Longitude'
            },
            apiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4'
        },
    ],
    permission: 'create'
};
const edit = {
    fields: [
        {
            type: 'mixed',
            name: 'title',
            label: 'Title',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    required: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    required: true
                }
            ]
        },
        {
            type: "map",
            name: "location",
            label: "Location",
            required: true,
            long: {
                name: 'long',
                label: 'Longitude'
            },
            apiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4'
        },
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: 'text',
            name: 'title.en'
        }
    ]
};

const csv = {
    headers: [
        {
            label: 'Title',
            key: 'title.en',
        },
        {
            label: 'Latitude',
            key: 'lat',
        },
        {
            label: 'Longitude',
            key: 'lng',
        }
    ]
}

const data = { list, show, create, edit,  search, csv };

export default data;