import { CSVLink } from 'react-csv';
import Icon from '../../layouts/Icon';
import Filter from '../default/Filter';
import Search from '../default/Search';
import { useEffect, useState } from 'react';
import DataTable from '../../layouts/DataTable';
import { authorized } from '../../../utils/utils';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { list, edit,remove } from '../../../features/registrations/actions';
import PermissionsManager from '../../layouts/PermissionsManager';
import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';
import Footer from '../../layouts/Footer';
import useQueryParams from '../../../hooks/useQueryParams';

const COLORS = ["#0088FE", "#FFBB28"];

const RADIAN = Math.PI / -180;

const renderCustomizedLabel = ({
    cx,
    cy,
    payload,
    midAngle,
    outerRadius,
    percent,
}) => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius) * cos;
    const sy = cy + (outerRadius) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <>
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={'#444'}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={'#444'} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#777"
            >{`${payload.name}: ${(percent * 100).toFixed(2)}%`}</text>
        </>
    );
};

const Index = (props) => {
    //other variables
    const page = 'Registrations';
    const dispatch = useDispatch();
    const { params, getQueryParam, query } = useQueryParams();

    // redux variables
    const user = useSelector((state) => state.auth.user);
    const csv = useSelector((state) => state[page.toString().toLowerCase()].properties.csv);
    const filter = useSelector((state) => state[page.toString().toLowerCase()].properties.filter);
    const search = useSelector((state) => state[page.toString().toLowerCase()].properties.search);
    const hide = useSelector((state) => state[page.toString().toLowerCase()].properties.list.hide);
    const columns = useSelector((state) => state[page.toString().toLowerCase()].properties.list.header);
    const actions = useSelector((state) => state[page.toString().toLowerCase()].properties.list.actions);
    const { items, itemsLoading, count, page_number, limit, charts } = useSelector((state) => state[page.toString().toLowerCase()]);

    // state variables
    const [bulk, setBulk] = useState([]);
    const [piChart, setPiChart] = useState([]);
    const [barChart, setBarChart] = useState([]);
    const [orderData, setOrderData] = useState([]);


    useEffect(() => {
        if (params)
            dispatch(
                list({
                    page: 0,
                    limit: 10,
                    orderBy: null,
                    orderDirection: null,
                    filter: params,
                    search: null,
                })
            );
        else dispatch(list());
    }, []);

    useEffect(() => {
        if (charts) {
            setPiChart(charts.pi_chart);
            setBarChart(charts.bar_chart);
        }
    }, [charts])

    const bulkToggle = (value, group) => {
        if (group) {
            setBulk(value)
        } else {
            if (bulk.includes(value))
                setBulk(bulk.filter(item => item !== value))
            else
                setBulk([...bulk, value])
        }
    }

    const onDelete = (id) => {
        dispatch(remove(id));
    }

    /* Filter Code */
    const [filterFormData, setFilterFormData] = useState(null);

    /* Search Code */
    const [searchFormData, setSearchFormData] = useState('');

    return user?.Permissions && <PermissionsManager action="list" name={page.toLowerCase()} permissions={user?.Permissions}>
        <Box height="100%" mt={10} >
            {hide?.breadcrumbs !== true && <Breadcrumbs page={page.toString()} text={'List'} />}
            {/* Header */}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={2}>
                {/* Title */}
                <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: "start", sm: "center" }}>
                    {hide?.title !== true && <Typography variant="h5" mr={1}>
                        {page}
                    </Typography>}
                    {hide?.count !== true && <Chip label={count} size="small" />}
                </Stack>

                {/* Buttons */}
                <Stack direction="row" spacing={2} mt={{ xs: 2, sm: 0 }} alignSelf={{ xs: "end", sm: "center" }}>

                    {filter && <Filter
                        filter={filter}
                        dispatch={dispatch}
                        list={list}
                        formData={filterFormData}
                        setFormData={setFilterFormData}
                        search={searchFormData === '' ? undefined : { keys: search?.fields.map((item) => item.name).join(','), value: searchFormData }}
                        order={orderData}
                        setOrder={setOrderData}
                    />}

                    {csv && <CSVLink
                        data={items}
                        filename={`${page}.csv`}
                        headers={csv.headers}
                    >
                        <Button variant="outlined" startIcon={<Icon type={'SaveAlt'} color={'primary'} />} sx={{ borderRadius: 0, textTransform: "none" }}>
                            Export excel
                        </Button>
                    </CSVLink>}
                </Stack>
            </Stack>
            <Search
                filter={filterFormData}
                search={search}
                dispatch={dispatch}
                list={list}
                formData={searchFormData}
                setFormData={setSearchFormData}
                order={orderData}
                setOrder={setOrderData} />

            {/* Body */}
            <DataTable
                columns={columns}
                rows={items}
                onDelete={onDelete}
                dispatch={dispatch}
                edit={edit}
                count={count}
                list={list}
                permissions={user?.Permissions}
                authorized={authorized}
                name={page.toLowerCase()}
                actions={actions}
                bulk={bulk}
                bulkToggle={bulkToggle}
                filter={filterFormData}
                search={searchFormData === '' ? undefined : { keys: search?.fields.map((item) => item.name).join(','), value: searchFormData }}
                order={orderData}
                page={page_number}
                rowsPerPage={limit}
                setOrder={setOrderData}
                hide_footer={true}
            />

            <Typography sx={{ fontWeight: 'bold' }} >REGISTRATIONS</Typography>
            <BarChart
                width={1000}
                height={300}
                data={barChart}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="registrations" fill="#8884d8" />
            </BarChart>

            <Grid
                container
                xs={5}
                style={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <Typography sx={{ fontWeight: 'bold' }} >APP INSTALL STATISTICS</Typography>
                <PieChart width={500} height={400}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={piChart}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        labelLine={false}
                        label={renderCustomizedLabel}

                    >
                        {piChart.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </Grid>
            <Footer />
        </Box>
    </PermissionsManager>
}

export default Index;