const list = {
    header: [
        {
            id: 'title.en',
            label: 'Title',
            align: 'left'
        },
        {
            id: 'airport.title.en',
            label: 'Airport',
            align: 'left'
        },
    ],
    permissions: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: 'mixed',
            name: 'title',
            label: 'Title',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                }
            ]
        },
        {
            type: 'mixed',
            name: 'airport.title',
            label: 'Airport',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                }
            ]
        },
        
    ],
    permission: 'show'
};
const create = {
    fields: [
        {
            type: 'mixed',
            name: 'title',
            label: 'Title',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    required: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    required: true
                }
            ]
        },
        {
            type: 'select',
            async: true,
            name: 'airport_id',
            label: 'Airport',
            required: true,
            route: 'airports'
        }
    ],
    permission: 'create'
};
const edit = {
    fields: [
        {
            type: 'mixed',
            name: 'title',
            label: 'Title',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    required: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    required: true
                }
            ]
        },
        {
            type: 'select',
            async: true,
            name: 'airport_id',
            label: 'Airport',
            required: true,
            route: 'airports'
        }
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: 'text',
            name: 'title.en'
        }
    ]
};

const csv = {
    headers: [
        {
            key: 'title.en',
            label: 'Title',
        },
        {
            key: 'airport.title.en',
            label: 'Airport',
        },
    ]
}

const data = { list, show, create, edit, search, csv };

export default data;