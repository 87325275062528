// react imports
import Box from '@mui/material/Box';
import { Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

// Global imports
import List from './components/pages/default/List';
import Show from './components/pages/default/Show';
import Form from './components/pages/default/Form';
import Order from './components/pages/default/Order';

// Custom Pages
import Main from './components/pages/Main';
import SMSPage from './components/pages/sms';
import Login from './components/pages/Login';
import SMTPPage from './components/pages/smtp';
import CarsPage from './components/pages/cars';
import FuelPage from './components/pages/fuel';
import ItemPage from './components/pages/item';
import WalkIn from './components/pages/walk-in';
import Alerts from './components/layouts/Alerts';
import UsersPage from './components/pages/users';
import RolesPage from './components/pages/roles';
import GatesPage from './components/pages/gates';
import CovidPage from './components/pages/covid';
import PricePage from './components/pages/prices';
import AdminsPage from './components/pages/admins';
import FindUsPage from './components/pages/findus';
import SplashPage from './components/pages/splash';
import SocialPage from './components/pages/social';
import OffersPage from './components/pages/offers';
import HotelsPage from './components/pages/hotels';
import NotFound from './components/layouts/NotFound';
import VersionPage from './components/pages/version';
import DriversPage from './components/pages/drivers';
import SupportPage from './components/pages/support';
import StandByPage from './components/pages/standby';
import MileagePage from './components/pages/mileage';
import Dashboard from './components/pages/dashboard';
import RolesForm from './components/pages/roles/Form';
import Profile from './components/pages/auth/Profile';
import VoucherPage from './components/pages/vouchers';
import BlackoutPage from './components/pages/blackout';
import AuthRoute from './components/layouts/AuthRoute';
import PackagesPage from './components/pages/packages';
import FeedbackPage from './components/pages/feedback';
import ServicesPage from './components/pages/services';
import TrackingPage from './components/pages/tracking';
import PaymentsPage from './components/pages/payments';
import NrtTripsPage from './components/pages/nrttrips';
import CashPage from './components/pages/cash-payments';
import POSPage from './components/pages/pos-payments';
import UserPushPage from './components/pages/user-push';
import AirportesPage from './components/pages/airports';
import ReceiversPage from './components/pages/receivers';
import NRTReasonPage from './components/pages/nrtreasons';
import PromocodePage from './components/pages/promocodes';
import CorporatePage from './components/pages/corporates';
import UserLoginPage from './components/pages/user-login';
import HotelVoucher from './components/pages/hotel-voucher';
import DriverPushPage from './components/pages/driver-push';
import CarClassesPage from './components/pages/car-classes';
import WalkthroughPage from './components/pages/walkthrough';
import PermissionsPage from './components/pages/permissions';
import OutstandingPage from './components/pages/outstanding';
import DriverLoginPage from './components/pages/driver-login';
import AppointmentPage from './components/pages/appointments';
import CarFeaturesPage from './components/pages/car-features';
import NotAuthorized from './components/layouts/NotAuthorized';
import TimeSettingsPage from './components/pages/time-settings';
import ServicesInfoPage from './components/pages/services-info';
import VoucherPayment from './components/pages/voucher-payment';
import NotificationsPage from './components/pages/notifications';
import RegistrationsPage from './components/pages/registrations';
import FixedSectionsPage from './components/pages/fixed-sections';
import HotelPaymentsPage from './components/pages/hotel-payments';
import FeedbackTypesPage from './components/pages/feedback-types';
import RejectReasonsPage from './components/pages/reject-reasons';
import CancelReasonsPage from './components/pages/cancel-reasons';
import LaterRequestsPage from './components/pages/later-requests';
import CorporateBooking from './components/pages/corporate-booking';
import FeaturedPlacesPage from './components/pages/featured-places';
import RequestsReportPage from './components/pages/requests-report';
import ApprovedRequestsPage from './components/pages/approved-requests';
import AppointmentRequestPage from './components/pages/appointment-request';
import RejectedAppointments from './components/pages/rejected-appointments';
import CancelledAppointments from './components/pages/canceled-appointments';
import DeleteAccount from './components/pages/delete-account-request';
const THEME = createTheme({
  typography: {
    "fontFamily": `Open Sans,sans-serif`,
  }
});

const App = ({ props }) => {
  return (
    <ThemeProvider theme={THEME}>
      <Box sx={{ display: 'flex' }} color="black">
        <Alerts />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/cms/login" element={<Login />} />
          <Route path="/cms" element={
            <AuthRoute redirectTo="login">
              <Main props={props} />
            </AuthRoute>
          }>
            <Route path="dashboard" element={<Dashboard />} exact />
            {/* Users page */}
            <Route path="profile" element={<Profile />} exact />

            <Route path="admins" element={<AdminsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="walkthrough" element={<WalkthroughPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="packages" element={<PackagesPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="car classes" element={<CarClassesPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="permissions" element={<PermissionsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="feedback types" element={<FeedbackTypesPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="reject reasons" element={<RejectReasonsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="cancel-reasons" element={<CancelReasonsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="feedback" element={<FeedbackPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>

            <Route path="fixed sections" element={<FixedSectionsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="find us" element={<FindUsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="smtp" element={<SMTPPage />} exact>
              <Route path="" element={<Form type="edit" />} exact />
            </Route>

            <Route path="sms" element={<SMSPage />} exact>
              <Route path="" element={<Form type="edit" />} exact />
            </Route>

            <Route path="social media" element={<SocialPage />} exact>
              <Route path="" element={<Form type="edit" />} exact />
            </Route>

            <Route path="support" element={<SupportPage />} exact>
              <Route path="" element={<Form type="edit" />} exact />
            </Route>

            <Route path="splash screen" element={<SplashPage />} exact>
              <Route path="" element={<Form type="edit" />} exact />
            </Route>

            <Route path="application version" element={<VersionPage />} exact>
              <Route path="" element={<Form type="edit" />} exact />
            </Route>

            <Route path="roles" element={<RolesPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<RolesForm type="create" />} exact />
              <Route path=":id/edit" element={<RolesForm type="edit" />} exact />
            </Route>

            <Route path="users" element={<UsersPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="drivers" element={<DriversPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="cars" element={<CarsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="prices" element={<PricePage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="services" element={<ServicesPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="services info" element={<ServicesInfoPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="Offers" element={<OffersPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="requests" element={<AppointmentRequestPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            <Route path="appointments" element={<AppointmentPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>
            <Route path="walk-in" element={<WalkIn />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>
            <Route path="corporate-booking" element={<CorporateBooking />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>
            <Route path="cancelled-appointments" element={<CancelledAppointments />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>
            <Route path="rejected-appointments" element={<RejectedAppointments />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>

            <Route path="time settings" element={<TimeSettingsPage />} exact>
              <Route path="" element={<Form type="edit" />} exact />
            </Route>

            <Route path="blackout" element={<BlackoutPage />} exact />

            <Route path="tracking" element={<TrackingPage />} exact />

            <Route path="vouchers" element={<VoucherPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path='userpush' element={<UserPushPage />} />

            <Route path='driverpush' element={<DriverPushPage />} />

            <Route path="featured places" element={<FeaturedPlacesPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="fuel" element={<FuelPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>

            <Route path="airports" element={<AirportesPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            
            <Route path="gates" element={<GatesPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="promocodes" element={<PromocodePage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            
            <Route path="carfeatures" element={<CarFeaturesPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            
            <Route path="nrtreasons" element={<NRTReasonPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            
            <Route path="hotels" element={<HotelsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            
            <Route path="notifications" element={<NotificationsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>

            <Route path="registrations" element={<RegistrationsPage />} exact />
            <Route path="requestsreport" element={<RequestsReportPage />} exact />
           
            <Route path="payments" element={<PaymentsPage />} exact>
              <Route path="" element={<List />} exact />
            </Route>

            <Route path="standby" element={<StandByPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            
            <Route path="corporates" element={<CorporatePage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            
            <Route path="outstanding" element={<OutstandingPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>
            <Route path="hotel-voucher" element={<HotelVoucher />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>

            <Route path="voucher-payment" element={<VoucherPayment />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>

            <Route path="mileage" element={<MileagePage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>

            <Route path="user-login" element={<UserLoginPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="driver-login" element={<DriverLoginPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="later-requests" element={<LaterRequestsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            
            <Route path="approved-requests" element={<ApprovedRequestsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            <Route path="hotel-payments" element={<HotelPaymentsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>

            <Route path="nrttrips" element={<NrtTripsPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path=":id" element={<Show />} exact />
            </Route>

            <Route path="covid" element={<CovidPage />} exact>
              <Route path="" element={<Form type="edit" />} exact />
            </Route>

            <Route path="item" element={<ItemPage />} exact>
              <Route path="" element={<List />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id" element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>
            
            <Route path="receivers" element={<ReceiversPage />} exact>
              <Route path="" element={<List />} exact />
            </Route>
            
            <Route path="cash" element={<CashPage />} exact>
              <Route path="" element={<List />} exact />
            </Route>
            
            <Route path="pos" element={<POSPage />} exact>
              <Route path="" element={<List />} exact />
            </Route>

          </Route>
          <Route path="unauthorized" element={<NotAuthorized />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box></ThemeProvider>
  );
}



export default App;