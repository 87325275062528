// other imports
import Breadcrumbs from '../../layouts/Breadcrumbs';
import { Box, Stack, Typography } from '@mui/material';
import PermissionsManager from '../../layouts/PermissionsManager';

// redux + api imports
import { useSelector } from 'react-redux';

// maps imports
import Tracking from '../../layouts/Tracking';


const Index = () => {
    // other variables
    const page = 'Tracking';

    // redux variables
    const user = useSelector((state) => state.auth.user);

    return user?.Permissions && <PermissionsManager action="list" name={page.toLowerCase()} permissions={user?.Permissions}>
        <div style={{ height: '100%', paddingTop: '4rem' }}>

            {/* Screen */}
            <Box height='100%'>
                {/* Header */}
                <Breadcrumbs page={page.toString()} />

                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={2}>
                    <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: 'start', sm: 'center' }}>
                        <Typography variant='h5' mr={1}>
                            {page}
                        </Typography>
                    </Stack>
                </Stack>

                {/* Maps */}
                <Tracking page={page} />
            </Box>
        </div>
    </PermissionsManager>
}

export default Index;