import React from 'react'
import { Outlet } from 'react-router-dom'
import { list, get } from '../../../features/appointments/actions';

const index = () => {
    const auto_sync = true;
    const page = "Appointments";
    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Outlet context={[{ page, list, get, auto_sync }]} />
        </div>
    )
}

export default index