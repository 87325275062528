import { createSlice } from "@reduxjs/toolkit";

// State on app load and refresh
const initialState = {
    list: [
        {
            label: 'Dashboard',
            icon: 'Home',
            route: 'dashboard'
        },
        {
            label: 'Adminstration',
            icon: 'Shield',
            children: [
                {
                    label: "Admins",
                    icon: 'SupervisorAccount',
                    route: 'admins'
                },
                {
                    label: 'Roles',
                    icon: 'AddModerator',
                    route: 'roles'
                },
                {
                    label: 'Permissions',
                    icon: 'AdminPanelSettings',
                    route: 'permissions'
                }
            ]
        },
        {
            label: 'Fleet Managment',
            icon: 'LocalTaxi',
            open: false,
            children: [
                {
                    label: "Drivers",
                    icon: "Groups2",
                    route: 'drivers'
                },
                {
                    label: "Car Classes",
                    icon: 'LabelImportant',
                    route: 'car classes',
                },
                {
                    label: "Cars",
                    icon: "LocalTaxi",
                    route: 'cars'
                },
                {
                    label: 'Tracking',
                    icon: 'GpsFixed',
                    route: 'tracking',
                    permission: 'list'
                },
                {
                    label: 'Fuel',
                    icon: 'LocalGasStation',
                    route: 'fuel',
                },
                {
                    label: 'Mileage',
                    icon: 'DeliveryDining',
                    route: 'mileage',
                },
                {
                    label: 'Car features',
                    icon: 'Style',
                    route: 'carfeatures'
                }
            ]
        },
        {
            label: 'Bookings',
            icon: 'BookOnline',
            open: false,
            children: [
                {
                    label: "Booking Requests",
                    icon: "RequestPage",
                    route: 'requests'
                },
                {
                    label: "Book Later Requests",
                    icon: "ManageHistory",
                    route: 'later-requests',
                    indicator: true
                },
                {
                    label: "Approved Later Requests",
                    icon: "DoneAll",
                    route: 'approved-requests',
                },
                {
                    label: "Booking History",
                    icon: "History",
                    route: 'appointments'
                },
                {
                    label: "Corporate Booking",
                    icon: "BookOnline",
                    route: 'corporate-booking'
                },
                {
                    label: "Cancelled Bookings",
                    icon: "History",
                    route: 'cancelled-appointments'
                },
                {
                    label: "Canceled Active Bookings",
                    icon: "History",
                    route: 'rejected-appointments'
                },
                {
                    label: "NRT History",
                    icon: "Block",
                    route: 'nrttrips'
                },
                {
                    label: "Booking Walk In",
                    icon: "DirectionsWalk",
                    route: 'walk-in'
                },
            ]
        },
        {
            label: 'Services',
            icon: 'MiscellaneousServices',
            open: false,
            children: [
                {
                    label: 'Packages',
                    icon: 'LocalOffer',
                    route: 'packages',
                },
                {
                    label: 'Prices',
                    icon: 'PriceChange',
                    route: 'prices',
                },
                {
                    label: 'Services',
                    icon: 'MiscellaneousServices',
                    route: 'services',
                },
                {
                    label: 'Service Info',
                    icon: 'Info',
                    route: 'services info',
                },
                {
                    label: 'Special Offers',
                    icon: 'CardGiftcard',
                    route: 'offers',
                },
            ]
        },
        {
            label: 'Airports',
            icon: 'Airlines',
            open: false,
            children: [
                {
                    label: 'Airport',
                    icon: 'Flight',
                    route: 'airports'
                },
                {
                    label: 'Gate',
                    icon: 'DoorSliding',
                    route: 'gates'
                }
            ]
        },
        {
            label: 'Corporates',
            icon: 'CorporateFare',
            route: 'corporates'
        },
        {
            label: 'Customers',
            icon: 'Groups',
            route: 'users'
        },
        {
            label: 'Hotels',
            icon: 'Hotel',
            route: 'hotels'
        },
        {
            label: 'Vouchers',
            icon: 'Percent',
            route: 'vouchers'
        },
        // {
        //     label: 'Promo codes',
        //     icon: 'Percent',
        //     route: 'promocodes'
        // },
        {
            label: 'Featured places',
            icon: 'PinDrop',
            route: 'featured places'
        },
        {
            label: 'Fixed Sections',
            icon: 'Newspaper',
            open: false,
            children: [
                {
                    label: "Pages",
                    icon: 'Article',
                    route: 'fixed sections',
                },
                {
                    label: "Walkthrough",
                    icon: 'PlayCircleFilled',
                    route: 'walkthrough'
                },
                {
                    label: "Covid List item",
                    icon: 'Coronavirus',
                    route: 'item'
                },
                {
                    label: 'Covid',
                    icon: 'Coronavirus',
                    route: 'covid',
                    permission: 'form'
                },
            ]
        },
        {
            label: 'Login Screens',
            icon: 'LockOpen',
            open: false,
            children: [
                {
                    label: "User",
                    icon: 'Person',
                    route: 'user-login',
                },
                {
                    label: "Driver",
                    icon: 'Groups2',
                    route: 'driver-login'
                }
            ]
        },
        {
            label: 'Feedback',
            icon: 'Feedback',
            open: false,
            children: [
                {
                    label: "Feedback Types",
                    icon: 'ChatBubble',
                    route: 'feedback types',
                },
                {
                    label: "Feedback Requests",
                    icon: 'Chat',
                    route: 'feedback',
                }
            ]
        },
        {
            label: 'Notifications',
            icon: 'Notifications',
            route: 'notifications'
        },
        {
            label: 'Push Notification',
            icon: 'NotificationsActive',
            open: false,
            children: [
                {
                    label: 'Users',
                    icon: 'Groups',
                    route: 'userpush',
                    permission: 'form'
                },
                {
                    label: 'Drivers',
                    icon: 'Groups2',
                    route: 'driverpush',
                    permission: 'form'
                }
            ]
        },
        {
            label: 'Reports',
            icon: 'BarChart',
            open: false,
            children: [
                {
                    label: 'Registration Report',
                    icon: 'ContentPaste',
                    route: 'registrations'
                },
                {
                    label: 'Requests Reports',
                    icon: 'ExitToApp',
                    route: 'requestsreport'
                }
            ]
        },

        {
            label:'Payments',
            icon: 'Payment',
            open: false,
            children: [
                {
                    label: 'Payments',
                    icon: 'Paid',
                    route: 'payments'
                },
                {
                    label: 'Corporate payments',
                    icon: 'AttachMoney',
                    route: 'outstanding'
                },
                {
                    label: 'Hotel payments',
                    icon: 'Receipt',
                    route: 'hotel-payments'
                },
                {
                    label: 'Hotel Vouchers',
                    icon: 'Hotel',
                    route: 'hotel-voucher'
                },
                {
                    label: 'Voucher payments',
                    icon: 'Percent',
                    route: 'voucher-payment'
                },
                {
                    label: 'Cash payments',
                    icon: 'Payments',
                    route: 'cash'
                },
                {
                    label: 'POS payments',
                    icon: 'Payment',
                    route: 'pos'
                },
            ],
        },
        {
            label: 'Reasons',
            icon: 'MarkAsUnread',
            open: false,
            children: [
                {
                    label: 'Reject Reasons',
                    route: 'reject reasons',
                    icon: 'MarkAsUnread',
                },
                {
                    label: 'Cancel Reasons',
                    route: 'cancel-reasons',
                    icon: 'MarkAsUnread',
                },
            ]
        },
       
        {
            label: 'Settings',
            icon: 'Settings',
            open: true,
            children: [
                {
                    label: 'SMTP',
                    icon: 'Mail',
                    route: 'smtp',
                    permission: 'form',
                },
                {
                    label: 'SMS',
                    route: 'sms',
                    icon: 'Sms',
                    permission: 'form',
                },
                {
                    label: 'Splash screen',
                    icon: 'Timer',
                    route: 'splash screen',
                    permission: 'form',
                },
                {
                    label: 'Find Us',
                    icon: 'Directions',
                    route: 'find us',
                },
                {
                    label: 'Support',
                    icon: 'SupportAgent',
                    route: 'support',
                    permission: 'form',
                },
                {
                    label: 'Social Media',
                    icon: 'Share',
                    route: 'social media',
                    permission: 'form',
                },
                {
                    label: 'Time Settings',
                    icon: 'ManageHistory',
                    route: 'time settings',
                    permission: 'form'
                },
                {
                    label: 'Blackout days',
                    icon: 'DoNotDisturb',
                    route: 'blackout',
                    permission: 'list'
                },
                {
                    label: 'Applications Version',
                    route: 'application version',
                    icon: 'Smartphone',
                    permission: 'form',
                },
                

                
                {
                    label: 'NRT Reasons',
                    icon: 'Warning',
                    route: 'nrtreasons'
                }
            ]
        },
    ], // will hold the list of items
};

// Create the Users Slice
const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
    }

});



// Export the slice reducer
export default sidebarSlice.reducer;
