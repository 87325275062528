const list = {
    header: [
        {
            id: 'driver.fullName',
            label: 'Driver',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'class.title.en',
            label: 'Class',
            align: 'left',
            order: false
        },
        {
            id: 'plate_number',
            label: 'Plate Number',
            align: 'left',
            fallback: 'N/A',
            order: false
        },
        {
            id: 'odo_start',
            label: 'Start ODO',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'odo_end',
            label: 'End ODO',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'nrtreason.reason.en',
            label: 'Reason',
            align: 'left',
            order: false,
        },
        {
            id: 'mileage',
            label: 'mileage',
            align: 'left',
            order: false
        },
        {
            id: 'start_time',
            label: 'Date',
            align: 'left',
            order: false
        },
        {
            id: 'status',
            label: 'Status',
            align: 'left',
            order: false,
            capitalize: true
        },
    ],
    hide: {
        create: true
    },
    permission: 'list',
    actions: ['show']
};

const show = {
    fields: [
        {
            type: "mixed",
            name: "driver.first_name",
            label: "Driver",
            elements : [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                },
            ]
        },
        {
            type: 'number',
            name: 'odo_start',
            label: 'Start ODO'
        },
        {
            type: 'number',
            name: 'odo_end',
            label: 'End ODO'
        },
        {
            type: 'number',
            name: 'mileage',
            label: 'Mileage'
        },
        {
            type: "mixed",
            name: "class.title",
            label: "Class",
            elements : [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                },
            ]
        },
        {
            type: 'text',
            name: 'nrtreason.reason.en',
            label: 'Reason'
        },
        {
            type: "text",
            name: "status",
            label: "Status"
        },
    ],
    permission: 'show'
};

const filter = {
    fields: [
        {
            type: 'select',
            name: 'status',
            label: 'Status',
            values: [
                {value: 'done', label: 'Done'},
                {value: 'in progress', label: 'In Progress'},
            ]
        },
        {
            type: 'select',
            async: true,
            name: 'driver_id',
            route: 'drivers-list',
            label: 'Driver'
        },
        {
            type: 'select',
            name: 'class_id',
            label: 'Car Class',
            async: true,
            route: 'classes-list'
        },
        {
            type: 'select',
            name: 'plate_number',
            label: 'Plate Number',
            async: true,
            route: 'car-plates'
        },
        {
            type: 'select',
            name: 'reason_id',
            label: 'Reason',
            async: true,
            route: 'nrt-reasons'
        },
        {
            type: 'date',
            name: 'date.from',
            label: 'Date From',
        },
        {
            type: 'date',
            name: 'date.to',
            label: 'Date To',
        }
    ]
};

const csv = {
    headers: [
        {
            label: 'User',
            key: 'user.fullName',
        },
        {
            label: 'Driver',
            key: 'driver.fullName',
        },
        {
            label: 'Class',
            key: 'class.title.en',
        },
        {
            label: 'Start ODO',
            key: 'odo_start',
        },
        {
            label: 'End ODO',
            key: 'odo_end',
        },
        {
            label: 'Status',
            key: 'status',
        },
    ],
}

const data = { list, show, filter, csv };

export default data;