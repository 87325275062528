const edit = {
    fields: [
        {
            type: "number",
            name: "auto_assign",
            label: "Time before auto assign Booking later request to driver (min)"
        },
        {
            type: "number",
            name: "auto_reject",
            label: "Time before auto reject Booking later request (min)"
        },
        {
            type: "number",
            name: "cancelation_free_time",
            label: "Cancelation free time (min)"
        },
        {
            type: "number",
            name: "free_waiting",
            label: "Free waiting time (min)"
        },
        {
            type: "number",
            name: "reminder_time",
            label: "Time before sending reminder to User (min)"
        },
        {
            type: "number",
            name: 'price_per_waiting_min',
            label: 'Price of extra waiting minutes (Qar)'
        },
        {
            type: 'number',
            name: 'time_before_hourly',
            label: 'Time before hourly package booking (min)'
        },
        {
            type: 'number',
            name: 'max_waiting',
            label: 'Maximum waiting minutes from driver leaving the location (min)'
        },
        {
            type: 'number',
            name: 'time_to_accept',
            label: 'Time For driver to Accept Trip (min)'
        },
        {
            type: 'number',
            name: 'propagation_max_distance',
            label: 'Propagation max distance (Km)'
        },
        {
            type: 'number',
            name: 'second_wave_propagation_max_distance',
            label: 'Second wave Propagation max distance (Km)'
        },
        {
            type: 'number',
            name: 'propagation_max_cars',
            label: 'Propagation max cars (Cars)'
        },
        {
            type: 'number',
            name: 'propagation_time',
            label: 'Time before starting the second request propagation for driver (Seconds)'
        },
        {
            type: 'switch',
            name:'auto_assign_enabled',
            label: 'Auto Assign enabled'
        },
        {
            type: 'switch',
            name:'auto_reject_enabled',
            label: 'Auto Reject enabled'
        },      
    ],
    main: true,
    breadcrumb: "Time Settings",
    title: "Time Settings"
}

const data = { edit };

export default data;