import { createSlice } from "@reduxjs/toolkit";
import properties from "./properties"
import { Single, Bulk } from "./actions";

// State on app load and refresh
const initialState = {
    items: [], // will hold the list of items
    count: null,
    itemsLoading: true,
    item: null, // will hold one item for edit
    itemLoading: true,
    page_number: 0,
    limit: 10,
    properties
};

// Create the Users Slice
const driverpushSlice = createSlice({
    name: "driverpush",
    initialState,
    reducers: {
        // Resets the state but keeps the data
        reset: (state) => {
            state.items = null// will hold the list of items
            state.itemsLoading = false
            state.item = null// will hold one item for edit
            state.itemLoading = false
        },
    },
    // pending: request send but response hasn't returned yet
    // fullfilled: response returned with successfull status code
    // rejected: request failed or response returned with error status code
    extraReducers: (builder) => {
        builder.addCase(Single.pending, (state) => {
            // state.itemsLoading = true
        })
        .addCase(Single.fulfilled, (state, { payload }) => {
        })
        .addCase(Single.rejected, (state) => {
        })

        .addCase(Bulk.pending, (state) => {
        })
        .addCase(Bulk.fulfilled, (state, { payload }) => {  
        })
        .addCase(Bulk.rejected, (state) => {
        })
    },
});

// Export the slice actions
export const { reset } = driverpushSlice.actions;

// Export the slice reducer
export default driverpushSlice.reducer;
