import React from 'react'
import { Outlet } from 'react-router-dom'
import { list, create, get, edit, remove, bulk_remove } from '../../../features/cancel-reasons/actions';

const index = () => {

  
    const page = "cancel-reasons";
    const title = "Cancel Reason";
    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Outlet context={[{ page, list, create, get, edit, remove, bulk_remove,title }]} />
        </div>
    )
}

export default index