const edit = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image"
        },
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                },
            ]
        },
        {
            type: "mixed",
            name: "body",
            label: "Body",
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    rich: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    rich: true
                },
            ]
        },
        {
            type: 'switch',
            name:'publish',
            label: 'Publish'
        },
    ],
    main: true,
    breadcrumb: "Covid",
    title: "Covid"
}

const data = { edit };

export default data;