import React from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

const  ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, items, handleDayclick, ...other } = props;
    const isSelected =
      !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

      const onDayClick = () => {
        if(!isSelected) return;
        if(dayjs().isAfter(day)) return;
        const blackout = items.find(el => day.isSame(dayjs(el.blackout_day), 'day'));
        handleDayclick(blackout)
      }
  
    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        sx={{ width: '13%',  height: 45, fontSize: '1.2rem', justifyContent: 'center', alignItems: 'center' }}
        onClick={onDayClick}
        badgeContent={isSelected ? <div style={{ fontSize: '1.2rem' }}>🌚</div> : undefined}
        
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
}

export default ServerDay;