
const edit = {
    fields: [
        {
            type: "text",
            name: "facebook",
            label: "Facebook",
        }, {
            type: "text",
            name: "instagram",
            label: "Instagram",
        },
        {
            type: "text",
            name: "linkedin",
            label: "Linkedin",
        }, {
            type: "text",
            name: "twitter",
            label: "Twitter",
        },
        {
            type: "text",
            name: "link",
            label: "Website",
        },
    ],
    main: true,
    breadcrumb: 'Social Media Settings',
    title: 'Social Media Settings',
};

const data = { edit }

export default data;