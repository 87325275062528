const list = {
    header: [
        {
            id: 'name',
            label: 'Name',
            align: 'left',
        },
        {
            id: 'long',
            label: 'Longitude',
            align: 'left',
        },
        {
            id: 'lat',
            label: 'Latitude',
            align: 'left',
        }
    ], 
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "map",
            name: "map",
            required: true,
            long: {
                name: 'long',
                label: 'Longitude',
            },
            apiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4'
        },

    ]
};
const create = {
    fields: [
        {
            type: "map",
            name: "map",
            label: "Location",
            required: true,
            long: {
                name: 'long',
                label: 'Longitude',
            },
            apiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4'
        },
    ]
};
const edit = {
    fields: [
        {
            type: "map",
            name: "map",
            label: "Location",
            required: true,
            long: {
                name: 'long',
                label: 'Longitude',
            },
            apiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4'
        },
    ]
};

const search = {
    fields: [

        {
            type: "text",
            name: "name",
        },
        {
            type: "text",
            name: "long",
        },
        {
            type: "text",
            name: "lat",
        },
    ]
}

const csv = {
    headers: [
        {
            key: 'name',
            label: 'Name',
        },
        {
            key: 'long',
            label: 'Longitude',
        },
        {
            key: 'lat',
            label: 'Latitude',
        }
    ]
}

const data = { list, create, edit, show, search,csv }

export default data;