import { useNavigate } from "react-router-dom";
import { authorized } from "../../utils/utils";

const PermissionsManager = ({ children, action, name, permissions, isComponent = false }) => {

    const navigate = useNavigate();
    if (!authorized(permissions, name, action)) {
        if(isComponent) return null;
        navigate('/unauthorized')
    }
    return children
}

export default PermissionsManager