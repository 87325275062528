const list = {
    header: [
        {
            id: 'first_name',
            label: 'First name',
            align: 'left',
        },
        {
            id: 'last_name',
            label: 'Last name',
            align: 'left',
        },
        {
            id: 'registered_at',
            label: 'Registration Date',
            align: 'left'
        },
        {
            id: 'email',
            label: 'Email',
            align: 'left',
        },
        {
            id: 'country_code',
            label: 'Country code',
            align: 'left',
        },
        {
            id: 'mobile_number',
            label: 'Mobile',
            align: 'left',
        },
       
      
        {
            id: 'corporate.name.en',
            label: 'Corporate',
            align: 'left'
        },
        {
            id: 'dob',
            label: 'DOB',
            align: 'left'
        },
        
        {
            id: 'last_login',
            label: 'Last Login',
            align: 'left'
        },
      
        {
            id: 'block',
            label: 'Blocked',
            align: 'center',
            switch: true
        },
    ],
    permission: 'list',
    // hide: {
    //     create: true
    // },
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "text",
            name: "first_name", 
            label: "First name"
        },
        {
            type: "text",
            name: "last_name", 
            label: "Last name"
        },
        {
            type: "text",
            name: "country_code", 
            label: "Country code"
        },
        {
            type: "text",
            name: "mobile_number", 
            label: "Mobile"
        },
        {
            type: "text",
            name: "email", 
            label: "Email"
        },
        {
            type: "date",
            name: "dob", 
            label: "Date of birth",
            future_disabled: true
        },
        {
            type: 'text',
            name: 'corporate.name.en',
            label: 'Corporate'
        }
    ],
     permission: 'show'
};

const create = {
    fields: [
        {
            type: "text",
            name: "country_code", 
            label: "Country code",
            required: true
        },
        {
            type: "text",
            name: "mobile_number", 
            label: "Mobile number",
            required: true
        },
        {
            type: "text",
            name: "first_name", 
            label: "First name",
            required: true
        },
        {
            type: "text",
            name: "last_name", 
            label: "Last name",
            required: true
        },
        {
            type: "email",
            name: "email", 
            label: "Email",
            required: true
        },
        {
            type: "date",
            name: "dob", 
            label: "Date of birth",
            future_disabled: true
        },
        {
            type: 'select',
            async: true,
            name: 'corporate_id',
            route: 'corporates',
            label: 'Corporate'
        },
        {
            type: 'password',
            name: 'password',
            label: 'Password',
        },
        {
            type: 'password',
            name: 'confirm_password',
            label: 'Confirm Password',
        }
    ]
};

const edit = {
    fields: [
        {
            type: "text",
            name: "first_name", 
            label: "First name",
            required: true
        },
        {
            type: "text",
            name: "last_name", 
            label: "Last name",
            required: true
        },
        {
            type: 'password',
            name: 'password',
            label: 'Password',
        },
        {
            type: 'password',
            name: 'confirm_password',
            label: 'Confirm Password',
        },
        {
            type: 'select',
            async: true,
            name: 'corporate_id',
            route: 'corporates',
            label: 'Corporate'
        },
    ]
};

const filter = {
    fields: [
      
        {
            type: 'text',
            name: 'first_name',
            label: 'First Name'
        },
        {
            type: 'text',
            name: 'last_name',
            label: 'Last Name'
        },
        {
            type: 'text',
            name: 'email',
            label: 'Email'
        },
        {
            type: 'text',
            name: 'mobile_number',
            label: 'Mobile'
        },
        {
            type: 'select',
            async: true,
            name: 'corporate_id',
            route: 'corporates',
            label: 'Corporate'
        },
        {
            type: 'date',
            name: 'date.from',
            label: 'Registration Date From',
        },
        {
            type: 'date',
            name: 'date.to',
            label: 'Registration Date To',
        }
    ],
};

const search = {
    fields: [
        {
            type: 'text',
            name: 'first_name',
        },
        {
            type: 'text',
            name: 'last_name',
        },
        {
            type: 'text',
            name: 'email',
        },
        {
            type: 'text',
            name: 'mobile_number',
        },
    ]
}

const csv = {
    headers: list.header.map(item => ({
      key: item.id,
      label: item.label,
    }))
  };

const data = { list, create, edit, show, filter, search, csv }

export default data;