import React from 'react';
import Map from '../inputs/Map';
import Text from '../inputs/Text';
import Date from '../inputs/Date';
import Email from '../inputs/Email';
import Phone from '../inputs/Phone';
import Image from '../inputs/Image';
import Mixed from '../inputs/Mixed';
import Empty from '../inputs/Empty';
import Number from '../inputs/Number';
import Select from '../inputs/Select';
import Switch from '../inputs/Switch';
import Password from '../inputs/Password';
import Price from '../inputs/Prices';

const Input = ({ field, onChange, value, updateForm, confirm, show, id=null, filter = null, formData = null, initial_update = false }) => {

    if (show && value === '') {
        return <Empty label={field.label} value={value} />
    }

    switch (field.type) {
        case 'text':
            return <Text field={field} onChange={onChange} value={value} updateForm={updateForm} show={show} />
        case 'email':
            return <Email field={field} onChange={onChange} value={value} show={show} />
        case 'number':
            return <Number field={field} onChange={onChange} value={value} show={show} />
        case 'mixed':
            return <Mixed field={field} onChange={onChange} value={value} show={show} updateForm={updateForm} />
        case 'select':
            return <Select field={field} onChange={onChange} value={value} show={show} updateForm={updateForm} id={id} filter={filter}  initial_update={initial_update} />
        case 'image':
            return <Image field={field} onChange={onChange} value={value} show={show} updateForm={updateForm} />
        case 'switch':
            return <Switch field={field} onChange={onChange} value={value} show={show} updateForm={updateForm} />
        case 'date':
            return <Date field={field} onChange={onChange} value={value} show={show} updateForm={updateForm} />
        case 'password':
            return <Password field={field} onChange={onChange} value={value} show={show} confirm={confirm} />
        case 'tel':
            return <Phone field={field} onChange={onChange} value={value} show={show} />
        case 'map':
            return <Map field={field} onChange={onChange} value={value} show={show} updateForm={updateForm} />
        case 'price':
            return <Price formData={formData} field={field} onChange={onChange} show={show} updateForm={updateForm} />
        default:
            return "Invalid Input Type";
    }
}

export default Input