import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, CssBaseline } from '@mui/material'

import Navbar from '../layouts/Navbar';
import Sidebar from '../layouts/Sidebar';



const drawerWidth = 299;

const Main = () => {

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
            <CssBaseline />
            <Navbar drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
            <Sidebar drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                backgroundColor="#F6F7FB"
                height="100vh"
                width="100%"
                sx={{ p: 3, marginLeft: { lg: `${drawerWidth}px` } }}
            >
                <Outlet />
            </Box>
        </>
    )
}

export default Main