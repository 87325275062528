const list = {
    header: [
        {
            id: 'user.fullName',
            label: 'Customer Name',
            orderBy: 'user.first_name',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'guest.fullname',
            label: 'Guest Name',
            orderBy: 'guest.fullname',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'pickup.location_name',
            label: 'Pickup Location' ,
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'destination.location_name',
            label: 'Drop off Location',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'pickupTime',
            label: 'Request Date & Time',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
     
        {
            id: 'driver.fullName',
            label: 'Driver Name',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'guest.fullname',
            label: 'Guest',
            align: 'left',
            fallback: 'N/A',
            order: false,
        },
       
        {
            id: 'class.title.en',
            label: 'Car Class/Type',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'package.title.en',
            label: 'Package',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
       
    
        {
            id: 'total_distance',
            label: 'Total Distance',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
       
        
        {
            id: 'price.base_fare',
            label: 'Base fare',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'total_time',
            label: 'Total Time',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'total',
            label: 'Trip total',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'status',
            label: 'Request Status',
            align: 'left',
            orderBy: 'status',
            capitalize: true,
            fallback: 'N/A'
        },

        {
            id: 'payment_type.type.en',
            label: 'Payment Method',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'driver_rating',
            label: 'Rating',
            align: 'left',
            rate: true,
            order: false,
            fallback: 'N/A'
        },
    ],
    permission: 'list',
    actions: ['show', 'update']
};

const show = {
    fields: [
        {
            type: "text",
            name: "user.fullName",
            label: "User"
        },
        {
            type: "text",
            name: "user.fullMobile",
            label: "Mobile"
        },
        {
            type: "mixed",
            name: "driver.first_name",
            label: "Driver",
            elements : [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                },
            ]
        },
        {
            type: "mixed",
            name: "class.title",
            label: "Class",
            elements : [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                },
            ]
        },
        {
            type: "mixed",
            name: "package.title",
            label: "Package",
            elements : [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                },
            ]
        },
        {
            type: "text",
            name: "pickupTime",
            label: "Pickup time"
        },
        {
            type: "map",
            name: "pickup",
            label: "Pickup",
            long: {
                name: 'lng',
                label: 'Longitude'
            },
            apiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4'
        },
        {
            type: "map",
            name: "destination",
            label: "Destination",
            long: {
                name: 'lng',
                label: 'Longitude'
            },
            apiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4'
        },
        {
            type: "mixed",
            name: "guest",
            label: "Guest info",
            elements : [
                {
                    type: 'text',
                    name: 'luggage_number',
                    label: 'Luggage',
                    nullable: true
                },
                {
                    type: 'text',
                    name: 'fullname',
                    label: 'Full Name',
                    nullable: true
                },
                {
                    type: 'text',
                    name: 'mobile_number',
                    label: 'Mobile number',
                    nullable: true
                }
            ]
        },
        {
            type: 'text',
            name: 'gate.airport.title.en',
            label: 'Airport',
        },
        {
            type: 'text',
            name: 'gate.title.en',
            label: 'Gate',
        },
        {
            type: "text",
            name: "status",
            label: "Status"
        },
    ],
    permission: 'show'
};

const create = {
    fields: [
        {
            type: 'select',
            async: true,
            name: 'package_id',
            route: 'packages',
            label: 'Package',
            with_type: true,
            required: true
        },
        {
            type: 'select',
            name: 'now',
            label: 'Booking time',
            required: true,
            values: [
                {value: true, label: 'Now'},
                {value: false, label: 'Later'},
            ]
        },
        {
            type: "map",
            name: "pickup_point",
            label: "Pickup",
            required: true,
            long: {
                name: 'long',
                label: 'Longitude'
            },
            apiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4'
        },
        {
            type: "map",
            name: "destination_point",
            label: "Destination",
            long: {
                name: 'long',
                label: 'Longitude'
            },
            apiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4',
            depend_on: 'type',
            depend_value: 'hourly',
            depend_action: 'hide'
        },
        {
            type: "text",
            name: "full_name",
            label: "User full name"
        },
        {
            type: 'select',
            async: true,
            name: 'user_id',
            route: 'users-mobile',
            label: 'User'
        },
        {
            type: 'date',
            name: 'pickup_time',
            label: 'Pickup time',
            past_disabled: true,
            with_time: true,
        },
        {
            type: 'select',
            async: true,
            name: 'class_id',
            route: 'classes',
            label: 'Class',
            required: true
        },
        {
            type: 'select',
            name: 'payment_id',
            label: 'Payment method',
            required: true,
            values: [
                {value: 2, label: 'Voucher'},
                {value: 3, label: 'Cash'},
                {value: 4, label: 'POS'}
            ]
        },
        {
            type: "text",
            name: "signage_name",
            label: "Signage name"
        },
        {
            type: "text",
            name: "flight_number",
            label: "Flight number"
        },
        {
            type: "text",
            name: "luggage_number",
            label: "Luggage number"
        },
        {
            type: "text",
            name: "voucher",
            label: "Voucher number"
        },
        {
            type: 'select',
            async: true,
            name: 'gate_id',
            route: 'gates',
            label: 'Gate',
        },
    ],
    permission: 'create'
};

const edit = {
    fields: [
        {
            type: 'select',
            async: true,
            name: 'driver_id',
            label: 'Driver',
            required: true,
            route: 'available-drivers?trip_id=',
            with_id: true
        }
    ],
    permission: 'show'
}

const filter = {
    fields: [
       
        {
            type: 'select',
            async: true,
            name: 'user_id',
            route: 'users-mobile',
            label: 'Customer Name'
        },

     
        {
            type: 'select',
            async: true,
            name: 'driver_id',
            route: 'drivers-list',
            label: 'Driver'
        },
        {
            type: 'select',
            name: 'class_id',
            label: 'Car Class',
            async: true,
            route: 'classes-list'
        },
        {
            type: 'text',
            name: 'total_distance',
            label: 'Total Distance',
            async: true,
            
        },
        {
            type: 'text',
            name: '$price.base_fare$',
            label: 'Base fare',
            async: true,            
        },
   
        {
            type: 'select',
            async: true,
            name: 'package_id',
            route: 'packages',
            label: 'Packages'
        },
        {
            type: 'select',
            async: true,
            name: 'payment_id',
            route: 'payment_methods',
            label: 'Payment Methods'
        },
        {
            type: 'date',
            name: 'date.from',
            label: 'Date From',
        },
        {
            type: 'date',
            name: 'date.to',
            label: 'Date To',
        }
    ]
}

const search = {
    fields: [
        {
            type: 'text',
            name: '$user.first_name$'
        },
        {
            type: 'text',
            name: '$user.last_name$'
        },
        {
            type: 'text',
            name: 'status'
        },
    ]
}

const csv = {
  headers: list.header.map(item => ({
    key: item.id,
    label: item.label,
  }))
};

const data = { list, show, create, edit, filter, search, csv };

export default data;