import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';

import Filter from './Filter';
import Search from './Search';
import Icon from '../../layouts/Icon';
import DataTable from '../../layouts/DataTable';
import { CSVLink, CSVDownload } from 'react-csv';
import { authorized } from '../../../utils/utils';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import useQueryParams from '../../../hooks/useQueryParams';
import PermissionsManager from '../../layouts/PermissionsManager';

import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';

const List = ({ permission }) => {

    const [{ page, list, create, get, edit, remove, order, bulk_remove, title, auto_sync = false }] = useOutletContext()

    const dispatch = useDispatch();
    const { items, itemsLoading, count, page_number, limit } = useSelector((state) => state[page.toString().toLowerCase()]);
    const user = useSelector((state) => state.auth.user);
    const columns = useSelector((state) => state[page.toString().toLowerCase()].properties.list.header)
    const actions = useSelector((state) => state[page.toString().toLowerCase()].properties.list.actions)
    const hide = useSelector((state) => state[page.toString().toLowerCase()].properties.list.hide)
    const filter = useSelector((state) => state[page.toString().toLowerCase()].properties.filter)
    const search = useSelector((state) => state[page.toString().toLowerCase()].properties.search)
    const csv = useSelector((state) => state[page.toString().toLowerCase()].properties.csv)
    const [orderData, setOrderData] = useState([]);
    const [bulk, setBulk] = useState([]);
    /* Filter Code */
    const [filterFormData, setFilterFormData] = useState(null);

    /* Search Code */
    const [searchFormData, setSearchFormData] = useState('');
    const [open, setOpen] = useState(false);

    const { params, getQueryParam, query } = useQueryParams();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        dispatch(bulk_remove({ data: { ids: bulk } }))
    };

    useEffect(() => {
    
        if (auto_sync && page_number === 0) {
            dispatch(list());
            const intervalId = setInterval(() => {
                if (filterFormData && searchFormData) {
                    const search = { keys: search?.fields.map((item) => item.name).join(','), value: searchFormData };
                    dispatch(list({ filter: filterFormData, search }));
                } else if (filterFormData) {
                    dispatch(list({ filter: filterFormData }));
                } else if (searchFormData) {
                    const search = { keys: search?.fields.map((item) => item.name).join(','), value: searchFormData };
                    dispatch(list({ search }));
                } else {
                    dispatch(list());
                }
            }, 5000);

            return () => {
                clearInterval(intervalId);
            }
        }
    }, [auto_sync, page_number]);

    useEffect(() => {
        if (params)
            dispatch(
                list({
                    page: 0,
                    limit: 10,
                    orderBy: null,
                    orderDirection: null,
                    filter: params,
                    search: null,
                })
            );
        else dispatch(list());
    }, []);

    const bulkToggle = (value, group) => {
        if (group) {
            setBulk(value)
        } else {
            if (bulk.includes(value))
                setBulk(bulk.filter(item => item !== value))
            else
                setBulk([...bulk, value])
        }

    }

    const onDelete = (id) => {
        dispatch(remove(id));
    }

    return user?.Permissions && <PermissionsManager action="list" name={page.toLowerCase()} permissions={user?.Permissions}>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ borderRadius: 0 }}
        >
            <DialogTitle id="alert-dialog-title" >
                Confirm
            </DialogTitle>
            <DialogContent >
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove all these items?
                </DialogContentText>
            </DialogContent>
            <DialogActions >
                <Button onClick={handleClose}>Keep</Button>
                <Button onClick={handleConfirm} autoFocus color="error">
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
        <Box height="100%" >
            {hide?.breadcrumbs !== true && <Breadcrumbs page={title ?? page.toString()} text={'List'} />}
            {/* Header */}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={2}>
                {/* Title */}
                <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: "start", sm: "center" }}>
                    {hide?.title !== true && <Typography variant="h5" mr={1}>
                        {title ?? page}
                    </Typography>}
                    {hide?.count !== true && <Chip label={count} size="small" />}
                </Stack>

                {/* Buttons */}
                <Stack direction="row" spacing={2} mt={{ xs: 2, sm: 0 }} alignSelf={{ xs: "end", sm: "center" }}>
                    {(hide?.create !== true && authorized(user.Permissions, page.toLowerCase(), 'form')) && <Button component={RouterLink} to="add" variant="contained" startIcon={<Icon type={'Add'} />} sx={{ borderRadius: 0, textTransform: "none" }}>
                        Create new
                    </Button>}

                    {(order && authorized(user.Permissions, page.toLowerCase(), 'order')) && <Button component={RouterLink} to="order" variant="contained" startIcon={<Icon type={'Sort'} />} sx={{ borderRadius: 0, textTransform: "none" }}>
                        Order
                    </Button>}

                    {(actions?.includes('bulk remove') && authorized(user.Permissions, page.toLowerCase(), 'remove')) && <Button color="error" onClick={() => handleClickOpen()} variant="contained" startIcon={<Icon type={'DeleteSweep'} />} sx={{ borderRadius: 0, textTransform: "none" }}>
                        Bulk Remove
                    </Button>}

                    {filter && <Filter
                        filter={filter}
                        dispatch={dispatch}
                        list={list}
                        formData={filterFormData}
                        setFormData={setFilterFormData}
                        search={searchFormData === '' ? undefined : { keys: search?.fields.map((item) => item.name).join(','), value: searchFormData }}
                        order={orderData}
                        setOrder={setOrderData}
                    />}

                    {csv && <CSVLink
                        data={items}
                        filename={`${page}.csv`}
                        headers={csv.headers}
                    >
                        <Button variant="outlined" startIcon={<Icon type={'SaveAlt'} color={'primary'} />} sx={{ borderRadius: 0, textTransform: "none" }}>
                            Export excel
                        </Button>
                    </CSVLink>}
                </Stack>
            </Stack>
            {search && <Search
                filter={filterFormData}
                search={search}
                dispatch={dispatch}
                list={list}
                formData={searchFormData}
                setFormData={setSearchFormData}
                order={orderData}
                setOrder={setOrderData} />}

            {/* Body */}
            <DataTable
                columns={columns}
                rows={items}
                onDelete={onDelete}
                dispatch={dispatch}
                edit={edit}
                count={count}
                list={list}
                permissions={user?.Permissions}
                authorized={authorized}
                name={page.toLowerCase()}
                actions={actions}
                bulk={bulk}
                bulkToggle={bulkToggle}
                filter={filterFormData}
                search={searchFormData === '' ? undefined : { keys: search?.fields.map((item) => item.name).join(','), value: searchFormData }}
                order={orderData}
                page={page_number}
                rowsPerPage={limit}
                setOrder={setOrderData} />
        </Box>
    </PermissionsManager>
}

export default List