import { configureStore } from "@reduxjs/toolkit";

import smsReducer from "./features/sms/slice";
import authReducer from "./features/auth/slice";
import smtpReducer from "./features/smtp/slice";
import carsReducer from "./features/cars/slice";
import fuelReducer from "./features/fuel/slice";
import itemRecucer from "./features/item/slice";
import gatesReducer from "./features/gates/slice";
import rolesReducer from "./features/roles/slice";
import covidReducer from "./features/covid/slice";
import alertsReducer from "./features/alerts/slice";
import socialReducer from "./features/social/slice";
import adminsReducer from "./features/admins/slice";
import splashReducer from "./features/splash/slice";
import offersReducer from "./features/offers/slice";
import findusReducer from "./features/findus/slice";
import pricesReducer from "./features/prices/slice";
import hotelsReducer from "./features/hotels/slice";
import WalkInReducer from "./features/walk-in/slice";
import supportReducer from "./features/support/slice";
import driversReducer from "./features/drivers/slice";
import sidebarReducer from "./features/sidebar/slice";
import versionReducer from "./features/version/slice";
import standbyReducer from "./features/standby/slice";
import mileageReducer from "./features/mileage/slice";
import usersReducer from "./features/users/usersSlice";
import voucherReducer from "./features/vouchers/slice";
import airportsReducer from "./features/airports/slice";
import feedbackReducer from "./features/feedback/slice";
import packagesReducer from "./features/packages/slice";
import servicesReducer from "./features/Services/slice";
import blackoutReducer from "./features/blackout/slice";
import trackingReducer from "./features/tracking/slice";
import paymentsReducer from "./features/payments/slice";
import nrttripsReducer from "./features/nrttrips/slice";
import userPushReducer from "./features/user-push/slice";
import receiversSlice from  "./features/receivers/slice";
import dashboardReducer from "./features/dashboard/slice";
import userLoginReducer from "./features/user-login/slice";
import promocodesReducer from "./features/promocodes/slice";
import nrtreasonsReducer from "./features/nrtreasons/slice";
import corporatesReducer from "./features/corporates/slice";
import driverPushReducer from "./features/driver-push/slice";
import carClassesReducer from "./features/car-classes/slice";
import permissionsReducer from "./features/permissions/slice";
import walkthroughReducer from "./features/walkthrough/slice";
import outstandingReducer from "./features/outstanding/slice";
import VoucherPayment from "./features/voucher-payment/slice";
import posPaymentReducer from "./features/pos-payments/slice";
import carFeaturesReducer from "./features/car-features/slice";
import appointmentReducer from "./features/appointments/slice";
import driverLoginReducer from "./features/driver-login/slice";
import HotelVoucherSlice from "./features/hotel-voucher/slice";
import timeSettingsReducer from "./features/time-setting/slice";
import servicesInfoReducer from "./features/ServicesInfo/slice";
import cashPaymentReducer from "./features/cash-payments/slice";
import registrationsReducer from "./features/registrations/slice";
import notificationsReducer from "./features/notifications/slice";
import hotelPaymentsReducer from "./features/hotel-payments/slice";
import laterRequestsReducer from "./features/later-requests/slice";
import fixedSectionsReducer from "./features/fixed-sections/slice";
import feedbackTypesReducer from "./features/feedback-types/slice";
import rejectReaosnsReducer from "./features/reject-reasons/slice";
import cancelReasonsReducer from "./features/cancel-reasons/slice";
import featuredPlacesReducer from "./features/featured-places/slice";
import requestsReportReducer from "./features/requests-report/slice";
import approvedRequestRducer from "./features/approved-requests/slice";
import corporateBookingReducer from "./features/corporate-booking/slice";
import appointmentRequestReducer from "./features/appointment-request/slice";
import rejectedAppointmentReducer from "./features/rejected-appointments/slice";
import cancelledAppointmentReducer from "./features/cancelled-appointments/slice";

export const store = configureStore({
    // Add the slices to the store so they can be accessed by any components on the app
    reducer: {
        sms: smsReducer,
        cars: carsReducer,
        auth: authReducer,
        smtp: smtpReducer,
        fuel: fuelReducer,
        item: itemRecucer,
        users: usersReducer,
        roles: rolesReducer,
        gates: gatesReducer,
        covid: covidReducer,
        prices: pricesReducer,
        admins: adminsReducer,
        alerts: alertsReducer,
        offers: offersReducer,
        hotels: hotelsReducer,
        pos: posPaymentReducer,
        sidebar: sidebarReducer,
        support: supportReducer,
        drivers: driversReducer,
        standby: standbyReducer,
        mileage: mileageReducer,
        vouchers: voucherReducer,
        'find us': findusReducer,
        'walk-in': WalkInReducer,
        cash: cashPaymentReducer,
        services: servicesReducer,
        feedback: feedbackReducer,
        packages: packagesReducer,
        blackout: blackoutReducer,
        tracking: trackingReducer,
        userpush: userPushReducer,
        airports: airportsReducer,
        payments: paymentsReducer,
        nrttrips: nrttripsReducer,
        receivers: receiversSlice,
        dashboard: dashboardReducer,
        promocodes: promocodesReducer,
        driverpush: driverPushReducer,
        'social media': socialReducer,
        nrtreasons: nrtreasonsReducer,
        corporates: corporatesReducer,
        'user-login': userLoginReducer,
        'splash screen': splashReducer,
        carfeatures: carFeaturesReducer,
        permissions: permissionsReducer,
        walkthrough: walkthroughReducer,
        outstanding: outstandingReducer,
        'car classes': carClassesReducer,
        appointments: appointmentReducer,
        'voucher-payment': VoucherPayment,
        'hotel-voucher': HotelVoucherSlice,
        'driver-login': driverLoginReducer,
        notifications: notificationsReducer,
        requests: appointmentRequestReducer,
        registrations: registrationsReducer,
        'services info': servicesInfoReducer,
        'time settings': timeSettingsReducer,
        'application version': versionReducer,
        'later-requests': laterRequestsReducer,
        'hotel-payments': hotelPaymentsReducer,
        'fixed sections': fixedSectionsReducer,
        'feedback types': feedbackTypesReducer,
        'reject reasons': rejectReaosnsReducer,
        'cancel-reasons': cancelReasonsReducer,
        'requestsreport': requestsReportReducer,
        'featured places': featuredPlacesReducer,
        'approved-requests': approvedRequestRducer,
        'corporate-booking': corporateBookingReducer,
        'rejected-appointments': rejectedAppointmentReducer,
        'cancelled-appointments': cancelledAppointmentReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;