const list = {
    header: [
        {
            id: 'base_fare',
            label: 'Base fare',
            align: 'left',
        },
        {
            id: 'per_km',
            label: 'Per Km',
            align: 'left',
        },
        {
            id: 'per_min',
            label: 'Per min',
            align: 'left',
        },
        {
            id: 'waiting_per_min',
            label: 'Waiting per min',
            align: 'left',
        },
        {
            id: 'rent_per_hour',
            label: 'Rent per hour',
            align: 'left',
        },
        {
            id: 'cancellation_fee',
            label: 'Cancelation fee',
            align: 'left',
        },
        {
            id: 'package.title.en',
            label: 'Package',
            align: 'left'
        },
        {
            id: 'class.title.en',
            label: 'Car Class',
            align: 'left'
        }
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};

const show = {
    fields: [
        {
            type: "mixed",
            name: "package.title",
            label: "Package",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "mixed",
            name: "class.title",
            label: "Class",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "number",
            name: "waiting_per_min",
            label: "Waiting per min",
        },
        {
            type: "number",
            name: "rent_per_hour",
            label: "Rent per hour",
        },
        {
            type: "number",
            name: "cancellation_fee",
            label: "Cancellation fee",
        },
        {
            type: "number",
            name: "min_fare",
            label: "Minimum fare",
            null_hide: true
        },
        {
            type: "number",
            name: "base_fare",
            label: "Base fare",
            null_hide: true
        },
        {
            type: "number",
            name: "per_km",
            label: "Per km",
            null_hide: true
        },
        {
            type: "number",
            name: "per_min",
            label: "Per min",
            null_hide: true
        },
    ],
    permission: 'show'
};

const create = {
    fields: [
        {
            type: 'price',
            dropdown: {
                type: 'select',
                async: true,
                name: 'package_id',
                label: 'Package',
                route: 'price-packages',
                required: true
            },
            elements: [
                {
                    type: "number",
                    name: "base_fare",
                    label: "Base fare",
                    required: true
                },
                {
                    type: "number",
                    name: "per_km",
                    label: "Per km",
                    required: true
                },
                {
                    type: "number",
                    name: "per_min",
                    label: "Per min",
                    required: true
                },
                {
                    type: "number",
                    name: "min_fare",
                    label: "Minimum fare",
                    required: true
                },
            ]
        },
        {
            type: "number",
            name: "rent_per_hour",
            label: "Rent per hour",
            required: true
        },
        {
            type: "number",
            name: "waiting_per_min",
            label: "Waiting per min",
            required: true
        },
        {
            type: "number",
            name: "cancellation_fee",
            label: "Cancellation fee",
            required: true
        },
        
        {
            type: 'select',
            async: true,
            name: 'class_id',
            label: 'Class',
            route: 'classes',
            required: true
        },
    ],
    permission: 'create'
};

const edit = {
    fields: [
        {
            type: 'price',
            dropdown: {
                type: 'select',
                async: true,
                name: 'package_id',
                label: 'Package',
                route: 'price-packages',
                required: true
            },
            elements: [
                {
                    type: "number",
                    name: "base_fare",
                    label: "Base fare",
                    required: true
                },
                {
                    type: "number",
                    name: "per_km",
                    label: "Per km",
                    required: true
                },
                {
                    type: "number",
                    name: "per_min",
                    label: "Per min",
                    required: true
                },
                {
                    type: "number",
                    name: "min_fare",
                    label: "Minimum fare",
                    required: true
                },
            ]
        },
        {
            type: "number",
            name: "rent_per_hour",
            label: "Rent per hour",
            required: true
        },
        {
            type: "number",
            name: "waiting_per_min",
            label: "Waiting per min",
            required: true
        },
        {
            type: "number",
            name: "cancellation_fee",
            label: "Cancellation fee",
            required: true
        },
        
        {
            type: 'select',
            async: true,
            name: 'class_id',
            label: 'Class',
            route: 'classes',
            required: true
        },
    ],
    permission: 'edit'
}

const search = {
    fields: [
        {
            type: "number",
            name: "base_fare",
        },
        {
            type: "number",
            name: "per_km",
        },
        {
            type: "number",
            name: "per_min",
        },
        {
            type: "number",
            name: "waiting_per_min",
        },
        {
            type: "number",
            name: "rent_per_hour",
        },
        {
            type: "number",
            name: "cancellation_fee",
        },
    ]
}

const csv = {
    headers: [
        {
            key: 'base_fare',
            label: 'Base fare',
        },
        {
            key: 'per_km',
            label: 'Per Km',
        },
        {
            key: 'per_min',
            label: 'Per min',
        },
        {
            key: 'waiting_per_min',
            label: 'Waiting per min',
        },
        {
            key: 'rent_per_hour',
            label: 'Rent per hour',
        },
        {
            key: 'cancellation_fee',
            label: 'Cancelation fee',
        },
    ],
}

const data = { list, create, edit, show, search, csv }

export default data;