import React, { Fragment, useEffect, useState } from "react";
import Square from "../../layouts/Square";
import Breadcrumbs from "../../layouts/Breadcrumbs";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PermissionsManager from "../../layouts/PermissionsManager";
import { useDispatch, useSelector } from "react-redux";
import Tracking from "../../layouts/Tracking";
import { list } from "../../../features/dashboard/actions";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const Index = () => {
  const page = "Dashboard";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  const user = useSelector((state) => state.auth.user);
  const sub_matches = useMediaQuery("(max-width:1300px)");

  const { items, itemsLoading } = useSelector(
    (state) => state[page.toString().toLocaleLowerCase()]
  );

  const [busyAnchorEl, setBusyAnchorEl] = useState(null);
  const [odnrtAnchorEl, setOdnrtAnchorEl] = useState(null);
  const [onlineAnchorEl, setOnlineAnchorEl] = useState(null);
  const [onTripAnchorEl, setOnTripAnchorEl] = useState(null);
  const [offlineDriversAnchorEl, setofflineDriversAnchorEl] = useState(null);

  const busy_open = Boolean(busyAnchorEl);
  const odnrt_open = Boolean(odnrtAnchorEl);
  const online_open = Boolean(onlineAnchorEl);
  const onTrip_open = Boolean(onTripAnchorEl);
  const offline_open = Boolean(offlineDriversAnchorEl);

  const busy_id = busy_open ? "busy-popover" : undefined;
  const odnrt_id = odnrt_open ? "odnrt-popover" : undefined;
  const onTrip_id = onTrip_open ? "ontrip-popover" : undefined;
  const online_drivers_id = online_open ? "drivers-popover" : undefined;
  const offline_drivers_id = offline_open ? "offline-popover" : undefined;

  const handleClick = (event) => {
    setOnlineAnchorEl(event.currentTarget);
  };
  const handleBusyClick = (event) => {
    setBusyAnchorEl(event.currentTarget);
  };
  const handleOdnrtClick = (event) => {
    setOdnrtAnchorEl(event.currentTarget);
  };
  const handleOnTripClick = (event) => {
    setOnTripAnchorEl(event.currentTarget);
  };
  const handleOfflineDriversClick = (event) => {
    setofflineDriversAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOnlineAnchorEl(null);
  };
  const handleBusyClose = () => {
    setBusyAnchorEl(null);
  };
  const handleodnrtClose = () => {
    setOdnrtAnchorEl(null);
  };
  const handleOnTripClose = () => {
    setOnTripAnchorEl(null);
  };
  const handleOfflineDriversClose = () => {
    setofflineDriversAnchorEl(null);
  };

  const update_info = () => {
    dispatch(list());
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      update_info();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (itemsLoading) {
      dispatch(list());
    }
  }, [itemsLoading]);

  const ago = (inputDate) => {
    const inputDateTime = new Date(inputDate);
    const currentDateTime = new Date();

    const differenceInMillis = currentDateTime - inputDateTime;

    const hours = Math.floor(differenceInMillis / (1000 * 60 * 60));
    const remainingMinutes = Math.floor(
      (differenceInMillis % (1000 * 60 * 60)) / (1000 * 60)
    );
    const remainingSeconds = Math.floor(
      (differenceInMillis % (1000 * 60)) / 1000
    );

    return `${hours}H ${remainingMinutes}Min ${remainingSeconds}Sec ago`;
  };

  return (
    user?.Permissions && (
      <PermissionsManager
        action="list"
        name={page.toLowerCase()}
        permissions={user?.Permissions}
      >
        <Box height="100%" mt={15}>
          <Breadcrumbs
            page={page}
            text={"Dashboard & statistics"}
            main={true}
          />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            {/* Title */}
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              alignSelf={{ xs: "start", sm: "center" }}
            >
              <Typography variant="h5" mr={1}>
                {page}
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{ display: "flex", flexDirection: matches ? "column" : "row" }}
            gap={2}
          >
            {/* boxes box */}
            <Box sx={{ maxWidth: sub_matches ? "50%" : "60%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: matches ? "column" : "row",
                }}
                mb={7}
                gap={4}
              >
                <Box
                  flex={1}
                  sx={{
                    display: "flex",
                    flexDirection: sub_matches ? "column" : "row",
                  }}
                  gap={2}
                >
                  <Square
                    onclick={() => {
                      navigate("/cms/registrations");
                    }}
                    title={"TODAY REGISTRATIONS"}
                    background_color={"#32c5d2"}
                    icon_type={"Groups"}
                    value={items?.today_registration}
                    full_width={sub_matches}
                  />
                  <Square
                    onclick={() => {
                      navigate("/cms/registrations?is_ios=false");
                    }}
                    title={"TODAY ANDROID REGISTRATIONS"}
                    background_color={"#e7505a"}
                    icon_type={"Adb"}
                    value={items?.today_android}
                    full_width={sub_matches}
                  />
                </Box>
                <Box
                  flex={1}
                  sx={{
                    display: "flex",
                    flexDirection: sub_matches ? "column" : "row",
                  }}
                  gap={2}
                >
                  <Square
                    onclick={() => {
                      navigate("/cms/registrations?is_ios=true");
                    }}
                    title={"TODAY IOS REGISTRATIONS"}
                    background_color={"#8E44AD"}
                    icon_type={"Apple"}
                    value={items?.today_ios}
                    full_width={sub_matches}
                  />
                  <Square
                    onclick={() => {
                      const currentDate = dayjs();
                      const date_from = currentDate
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss");
                      const date_to = currentDate
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss");
                      navigate(
                        `/cms/requestsreport?date.from=${date_from}&&date.to=${date_to}`
                      );
                    }}
                    title={"TODAY REQUESTS"}
                    background_color={"#3598dc"}
                    icon_type={"ShoppingCart"}
                    value={items?.today_requests}
                    full_width={sub_matches}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: matches ? "column" : "row",
                }}
                mb={7}
                gap={4}
              >
                <Box
                  flex={1}
                  sx={{
                    display: "flex",
                    flexDirection: sub_matches ? "column" : "row",
                  }}
                  gap={2}
                >
                  <Square
                    describedby={online_drivers_id}
                    onclick={
                      items?.online_drivers && items?.online_drivers?.length > 0
                        ? handleClick
                        : null
                    }
                    title={"Online Drivers Ready"}
                    background_color={"#32c5d2"}
                    icon_type={"ODR"}
                    value={items?.ODR}
                    full_width={sub_matches}
                    is_icon={false}
                  />
                  <Popover
                    id={online_drivers_id}
                    open={
                      online_open &&
                      items?.online_drivers &&
                      items?.online_drivers?.length > 0
                    }
                    anchorEl={onlineAnchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {items?.online_drivers?.map((el, index) => (
                        <Typography key={index} sx={{ p: 2 }}>
                          {el.driverName} - {el?.car?.name}
                        </Typography>
                      ))}
                    </div>
                  </Popover>
                  <Square
                    describedby={busy_id}
                    onclick={
                      items?.busy_drivers && items?.busy_drivers?.length > 0
                        ? handleBusyClick
                        : null
                    }
                    title={"Online Driver busy"}
                    background_color={"#e7505a"}
                    icon_type={"ODB"}
                    value={items?.ODB}
                    full_width={sub_matches}
                    is_icon={false}
                  />
                  <Popover
                    id={busy_id}
                    open={
                      busy_open &&
                      items?.busy_drivers &&
                      items?.busy_drivers?.length > 0
                    }
                    anchorEl={busyAnchorEl}
                    onClose={handleBusyClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {items?.busy_drivers?.map((el, index) => (
                        <Typography key={index} sx={{ p: 2 }}>
                          {el.driverName} - {el?.car?.name}
                        </Typography>
                      ))}
                    </div>
                  </Popover>
                </Box>
                <Box
                  flex={1}
                  sx={{
                    display: "flex",
                    flexDirection: sub_matches ? "column" : "row",
                  }}
                  gap={2}
                >
                  <Square
                    describedby={onTrip_id}
                    onclick={
                      items?.drivers_on_trip &&
                      items?.drivers_on_trip?.length > 0
                        ? handleOnTripClick
                        : null
                    }
                    title={"Online drivers on trip"}
                    background_color={"#8E44AD"}
                    icon_type={"ODOT"}
                    value={items?.ODOT}
                    full_width={sub_matches}
                    is_icon={false}
                  />
                  <Popover
                    id={onTrip_id}
                    open={
                      onTrip_open &&
                      items?.drivers_on_trip &&
                      items?.drivers_on_trip?.length > 0
                    }
                    anchorEl={onTripAnchorEl}
                    onClose={handleOnTripClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {items?.drivers_on_trip?.map((el, index) => (
                        <Typography key={index} sx={{ p: 2 }}>
                          {el.driver?.driverName} - {el.driver?.car?.name}
                        </Typography>
                      ))}
                    </div>
                  </Popover>
                  <Square
                    describedby={offline_drivers_id}
                    onclick={
                      items?.offline_drivers &&
                      items?.offline_drivers?.length > 0
                        ? handleOfflineDriversClick
                        : null
                    }
                    title={"Offline drivers"}
                    background_color={"#3598dc"}
                    icon_type={"OFD"}
                    value={items?.OFD}
                    full_width={sub_matches}
                    is_icon={false}
                  />
                  <Popover
                    id={offline_drivers_id}
                    open={
                      offline_open &&
                      items?.offline_drivers &&
                      items?.offline_drivers?.length > 0
                    }
                    anchorEl={offlineDriversAnchorEl}
                    onClose={handleOfflineDriversClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {items?.offline_drivers?.map((el, index) => (
                        <Typography key={index} sx={{ p: 2 }}>
                          {el.driverName}
                        </Typography>
                      ))}
                    </div>
                  </Popover>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: matches ? "column" : "row",
                }}
                mb={7}
                gap={4}
              >
                <Box
                  flex={1}
                  sx={{
                    display: "flex",
                    flexDirection: sub_matches ? "column" : "row",
                  }}
                  gap={2}
                >
                  <Square
                    describedby={odnrt_id}
                    onclick={
                      items?.nrt_drivers && items?.nrt_drivers?.length > 0
                        ? handleOdnrtClick
                        : null
                    }
                    title={"Online driver performing an NRT"}
                    background_color={"#32c5d2"}
                    icon_type={"ODNRT"}
                    value={items?.ODNRT}
                    full_width={sub_matches}
                    is_icon={false}
                  />
                  <Popover
                    id={odnrt_id}
                    open={
                      odnrt_open &&
                      items?.nrt_drivers &&
                      items?.nrt_drivers?.length > 0
                    }
                    anchorEl={odnrtAnchorEl}
                    onClose={handleodnrtClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {items?.nrt_drivers?.map((el, index) => (
                        <Typography key={index} sx={{ p: 2 }}>
                          {el?.driverName}
                        </Typography>
                      ))}
                    </div>
                  </Popover>
                  <Square
                    onclick={() => {
                      navigate("/cms/walk-in");
                    }}
                    title={"TODAY WALKIN"}
                    background_color={"#e7505a"}
                    icon_type={"Hiking"}
                    value={items?.ODB}
                    full_width={sub_matches}
                  />
                </Box>
                <Box
                  flex={1}
                  sx={{
                    display: "flex",
                    flexDirection: sub_matches ? "column" : "row",
                  }}
                  gap={2}
                >
                  <Square
                    onclick={() => {
                      navigate("/cms/voucher-payment");
                    }}
                    title={"TODAY VOUCHER TRIPS"}
                    background_color={"#8E44AD"}
                    icon_type={"Percent"}
                    value={items?.ODOT}
                    full_width={sub_matches}
                  />
                  <Square
                    onclick={() => {
                      navigate("/cms/nrttrips");
                    }}
                    title={"TODAY NRT TRIPS"}
                    background_color={"#3598dc"}
                    icon_type={"NRT"}
                    value={items?.nrt_trips}
                    full_width={sub_matches}
                    is_icon={false}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: matches ? "column" : "row",
                }}
                mb={7}
                gap={4}
              >
                <Box
                  flex={1}
                  sx={{
                    display: "flex",
                    flexDirection: sub_matches ? "column" : "row",
                  }}
                  gap={2}
                >
                  <Square
                    onclick={() => {
                      navigate("/cms/later-requests");
                    }}
                    title={"Booking Later Request"}
                    background_color={"#3598dc"}
                    icon_type={"BLR"}
                    value={items?.booking_later_request_count}
                    full_width={sub_matches}
                    is_icon={false}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: sub_matches ? "column" : "row",
                }}
                mb={7}
                gap={4}
              >
                <Box
                  flex={1}
                  sx={{
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                    maxHeight: 150,
                  }}
                  gap={2}
                >
                  <Square
                    title={"TODAY TRIPS"}
                    background_color={"#32c5d2"}
                    icon_type={"History"}
                    value={items?.today_trips}
                    full_width={true}
                    onclick={() => {
                      navigate("/cms/approved-requests");
                    }}
                  />
                  <Square
                    title={"TOTAL REVENUE OF THE DAY"}
                    background_color={"#8E44AD"}
                    icon_type={"Paid"}
                    value={items?.total}
                    full_width={true}
                  />
                </Box>
              </Box>
            </Box>
            {/* Notifications box */}
            <Box
              flex={1}
              sx={{
                display: "flex",
                flexDirection: sub_matches ? "column" : "row",
                maxHeight: 757,
                height: 1000,
                minWidth: "35%",
                marginBottom: sub_matches ? 15 : 0,
              }}
              gap={2}
            >
              <Paper
                elevation={1}
                style={{
                  width: "100%",
                  padding: 0,
                  height: sub_matches ? "inherit" : "100%",
                }}
              >
                <Card sx={{ width: "100%", height: "100%" }}>
                  <CardHeader title={"Recent Activities"} />
                  <CardContent sx={{ maxHeight: "85%", overflowY: "scroll" }}>
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      {items?.notifications?.map((el, index) => (
                        <Fragment key={index}>
                          <ListItem
                            secondaryAction={
                              <Typography
                                sx={{ marginBottom: 5, fontSize: 13 }}
                              >
                                {ago(new Date(el.created_at))}
                              </Typography>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                {!el?.read && (
                                  <EmojiObjectsIcon
                                    style={{ color: "yellow" }}
                                  />
                                )}
                                {el?.read && <EmojiObjectsIcon />}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={el.title}
                              secondary={el.text}
                            />
                          </ListItem>
                          {index != items?.notifications?.length - 1 && (
                            <Divider />
                          )}
                        </Fragment>
                      ))}
                    </List>
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate("/cms/notifications");
                      }}
                      endIcon={
                        <ArrowCircleUpIcon
                          style={{ transform: "rotate(90deg)" }}
                        />
                      }
                    >
                      See All Records
                    </Button>
                  </CardActions>
                </Card>
              </Paper>
            </Box>
          </Box>

          <Tracking page={"Tracking"} />
        </Box>
      </PermissionsManager>
    )
  );
};

export default Index;
