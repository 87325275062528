const list = {
    header: [
        {
            id: 'image',
            display: true,
            label: 'Image',
            align: 'left',
        },
        {
            id: 'title.en',
            label: 'Title',
            orderBy: 'title.en',
            align: 'left',
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true,
        },
        {
            id: 'featured',
            label: 'featured',
            align: 'left',
            switch: true,
        }
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image",
        },
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "mixed",
            name: "subtitle",
            label: "Subtitle",
            elements: [
                {
                    type: "text",
                    name: "en",
                    rich: true,
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    rich: true,
                    label: "Arabic",
                }
            ],
        },
        {
            type: "mixed",
            name: "btn_text",
            label: "Button Text",
            elements: [
                {
                    type: "text",
                    name: "en",
                    rich: true,
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    rich: true,
                    label: "Arabic",
                }
            ],
        },
    ],
    permission: 'show'
};
const create = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image (220 x 390)",
            required: true,
        }, 
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }, 
        {
            type: "mixed",
            name: "subtitle",
            label: "Subtitle",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    rich: true,
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    rich: true,
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: "mixed",
            name: "btn_text",
            label: "Button Text",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
    ],
    permission: 'create'
};
const edit = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image (220 x 390)",
        }, 
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }, 
        {
            type: "mixed",
            name: "subtitle",
            label: "Subtitle",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    rich: true,
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    rich: true,
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: "mixed",
            name: "btn_text",
            label: "Button Text",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
    ],
    permission: 'edit'
};

const order = {
    fields: [{
        id: 'image',
        display: true,
        label: 'Image',
        align: 'left',
    },
    {
        id: 'title',
        child: 'en',
        label: 'Title',
        align: 'left',
    },],
    permission: 'order'
};

const search = {
    fields: [
        {
            type: "text",
            name: "title.en",
        }
    ]
}

const csv = {
    headers: [
        {
            key: 'image',
            label: 'Image',
        },
        {
            key: 'title.en',
            label: 'Title',
        },
        {
            key: 'publish',
            label: 'Publish',
        },
        {
            key: 'featured',
            label: 'featured',
        }
    ],
}


const data = { list, create, edit, show, order, search, csv }

export default data;