import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Input from '../layouts/Input';

const Price = ({ field, formData, onChange, updateForm, show }) => {
    const { elements, dropdown } = field
    const [hide, setHide] = useState(false);


    const onTypeChange = (selected_package) => {
        if(selected_package?.type == 'hourly') {
            setHide(true);
        } else {
            setHide(false);
        }
    }

    const onFieldChange = (value) => {
        onChange(value);
    };

    return (
        <div>
            <Input field={dropdown} onChange={onTypeChange} value={formData[dropdown?.name]} show={show} updateForm={updateForm} id={dropdown?.id} initial_update={true} />
            {!hide && elements.map((element, i) => (
                <Input field={element} onChange={onFieldChange} value={formData[element.name]} key={i} updateForm={updateForm} show={show} />
            ))}
        </div>
    )
}

export default Price