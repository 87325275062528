import { createAsyncThunk } from "@reduxjs/toolkit";
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from "../../utils/api";
import { setAlert } from "../alerts/actions";

export const list = createAsyncThunk("cancel-reasons/list", async (params = { page: 0, limit: 10, orderBy: null, orderDirection: null, filter: null, search: null }) => {
    try {
        const page = params.page ? params.page : 0
        const limit = params.limit ? params.limit : 10
        const res = await api.get(`/admin/cancel-reasons`, { params });
        
        return { ...res.data, page, limit };
    } catch (error) {
        console.log(error);
    }
});
export const create = createAsyncThunk("cancel-reasons/create", async ({ data, navigate }, { dispatch }) => {
    try {
        const res = await api.post('/admin/cancel-reasons', data);
        dispatch(list());
        dispatch(setAlert({ message: 'Cancel reason Added successfully', alertType: 'success' }))
        if (navigate)
            navigate(`/cms/cancel-reasons`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const get = createAsyncThunk("cancel-reasons/get", async (id, { dispatch }) => {
    try {
        const res = await api.get(`/admin/cancel-reasons/${id}`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const edit = createAsyncThunk("cancel-reasons/edit", async ({ id, data, navigate }, { dispatch }) => {
    try {
        await api.put(`/admin/cancel-reasons/${id}`, data);
        dispatch(setAlert({ message: 'cancel reason updated successfully', alertType: 'success' }))
        dispatch(list());
        if (navigate)
            navigate(`/cms/cancel-reasons`);
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});


export const remove = createAsyncThunk("cancel-reasons/remove", async (id, { dispatch }) => {
    try {
        await api.delete(`/admin/cancel-reasons/${id}`);
        dispatch(setAlert({ message: 'cancel reason removed successfully', alertType: 'error' }))
        dispatch(list());
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const bulk_remove = createAsyncThunk("cancel-reasons/bulk-remove", async ({ data }, { dispatch }) => {
    try {
        await api.post(`/admin/cancel-reasons/delete`, data);
        dispatch(setAlert({ message: 'cancel-reasons removed successfully', alertType: 'error' }))
        dispatch(list());
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});