import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '../../layouts/Breadcrumbs'

import { Box, Button, Divider, Grid, IconButton, Paper, Stack, Switch, Typography } from '@mui/material'
import Icon from '../../layouts/Icon';
import Input from '../../layouts/Input';
import { list } from '../../../features/permissions/actions';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { create, edit, get } from '../../../features/roles/actions';
import PermissionsManager from '../../layouts/PermissionsManager';


const Form = ({ type }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { id } = useParams();
    const permissions = useSelector((store) => store.permissions.items);
    const user = useSelector((state) => state.auth.user);
    const permissionsLoading = useSelector((store) => store.permissions.itemsLoading);

    const item = useSelector((store) => store.roles.item);

    const [Permissions, setPermissions] = useState([]);
    const [Names, setNames] = useState([]);

    const [formData, setFormData] = useState({
        name: "",
        permissions: {},
    });

    useEffect(() => {
        let form = {}
        for (const key in item) {
            if (formData[key] !== undefined) {
                form = { ...form, [key]: item[key] }
            }
        }
        setFormData({ ...formData, ...form })
    }, [item])

    useEffect(() => {
        if (type === "edit") {
            dispatch(get(id));
        } else {
            setFormData({
                name: "",
                permissions: {},
            });
        }
    }, []);

    useEffect(() => {
        if (!permissionsLoading) {
            const names = [];
            const tmp = {};
            permissions.forEach((permission) => {
                if (names.includes(permission.name)) {
                    tmp[permission.name].push(permission);
                } else {
                    names.push(permission.name);
                    tmp[permission.name] = [permission];
                }
            });

            setNames(names);
            setPermissions(tmp);
        }
    }, [permissions]);

    useEffect(() => {

        dispatch(list({ page: -1, limit: -1 }));

    }, []);

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const updateForm = (key, value) => {
        setFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (type === "create") {
            dispatch(create({ data: formData, navigate }),);
        } else {
            dispatch(edit({ id, data: formData, navigate }));
        }
    }

    const updateFormPermissions = (id, checked) => {
        setFormData((prev) => {
            return {
                ...prev,
                permissions: {
                    ...prev.permissions,
                    [id]: checked,
                },
            };
        });
    };

    const contains = (arr, key, value) => {
        return arr.filter(item => item[key] == value).length > 0;
    }


    return user?.Permissions && <PermissionsManager action="form" name={'roles'} permissions={user?.Permissions}>
        <Box height="100%" >
            <Breadcrumbs page={'Roles'} text={type === 'create' ? 'Create new' : 'Update'} />
            {/* Header */}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={4}>
                {/* Title */}
                <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: "start", sm: "center" }}>
                    <IconButton component='a' href={'/cms/roles'} aria-label="arrow-back" sx={{ border: "2px solid", borderRadius: "50%" }} disableRipple size="small">
                    <Icon type={'ArrowBack'} />
                    </IconButton>
                    <Typography variant="h5" mr={1}>
                        {type === 'create' ? "Create New" : "Edit"}
                    </Typography>
                </Stack>

                {/* Buttons */}
                <Stack direction="row" spacing={2} mt={{ xs: 2, sm: 0 }} alignSelf={{ xs: "end", sm: "center" }}>
                    {/* <Button component={RouterLink} to="add" variant="contained" startIcon={<AddIcon />} sx={{ borderRadius: 0 }}>
                        Edit
                    </Button>
                    <Button variant="outlined" startIcon={<TuneIcon />} sx={{ borderRadius: 0 }} onClick={toggle(true)}  >
                                Filter
                    </Button> */}
                </Stack>
            </Stack>

            {/* Body */}
            <Paper component='form' elevation={0} mt={2} sx={{ width: '100%', borderRadius: 0, height: { md: "75vh", xs: "60vh" }, padding: 4 }} onSubmit={(e) => onSubmit(e)}>
                <Box sx={{ width: '100%', overflowY: 'auto', borderRadius: 0, height: "95%" }}>
                    {/* {fields.map((field, i) => (
                        <Input field={field} onChange={onChange} value={formData[field.name]} key={i} updateForm={updateForm} />
                    ))} */}
                    <Input field={{
                        type: 'text',
                        name: 'name',
                        label: 'Name',
                        required: true
                    }} onChange={onChange} value={formData.name} updateForm={updateForm} />
                    <Grid2 container spacing={2}>
                        <Grid2 item xs={3}>
                            <Typography textTransform={'capitalize'} fontWeight={600}>List: </Typography>
                            <Typography textTransform={'capitalize'} >view table</Typography>
                        </Grid2>
                        <Grid2 item xs={3}>
                            <Typography textTransform={'capitalize'} fontWeight={600}>View: </Typography>
                            <Typography textTransform={'capitalize'} >view single row</Typography>
                        </Grid2>
                        <Grid2 item xs={3}>
                            <Typography textTransform={'capitalize'} fontWeight={600}>Form: </Typography>
                            <Typography textTransform={'capitalize'} >create and update</Typography>
                        </Grid2>
                        <Grid2 item xs={3}>
                            <Typography textTransform={'capitalize'} fontWeight={600}>Remove: </Typography>
                            <Typography textTransform={'capitalize'} >delete and bulk delete</Typography>
                        </Grid2>
                    </Grid2>
                    <Divider />
                    {/* Permissions */}
                    <Box paddingY={3}>
                        <Typography fontWeight={600}>Permissions</Typography>
                        {Names.map((name, index) => {
                            return (
                                <div key={index}>
                                    <Grid2 container spacing={2} alignItems="center" marginY={2}>
                                        <Grid2 xs={3}>
                                            <Typography textTransform={'capitalize'} >{Permissions[name][0].display_name}</Typography>
                                        </Grid2>
                                        <Grid2 xs={9}>
                                            <Stack alignItems={'center'} direction='row'>
                                                {Permissions[name].map((permission, index1) => (
                                                    <Box paddingX={3}>
                                                        <Typography textTransform={'capitalize'}>
                                                            {permission.action}
                                                        </Typography>
                                                        <div>
                                                            <Switch
                                                                // contains(formData.permissions, 'id', permission.id)
                                                                checked={formData.permissions[permission.id] ? formData.permissions[permission.id] : false}
                                                                onChange={(event) => {
                                                                    updateFormPermissions(
                                                                        permission.id,
                                                                        event.target.checked
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </Box>
                                                ))}</Stack>
                                        </Grid2>
                                    </Grid2>
                                    <Divider />
                                </div>
                            );
                        })}
                    </Box>


                </Box>
                <Stack direction="row" width="100%" spacing={1} alignItems="center" justifyContent='center'>
                    <Button type="submit" variant="contained" sx={{ borderRadius: 0, padding: '8px 48px', textTransform: 'none' }}>
                        Save
                    </Button>
                </Stack>
            </Paper>
        </Box>
    </PermissionsManager>
}

export default Form