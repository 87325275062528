import Input from '../../layouts/Input';
import React, { useEffect, useState } from 'react';
import { initialState } from '../../../utils/utils';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import PermissionsManager from '../../layouts/PermissionsManager';
import { Single, Bulk } from '../../../features/user-push/actions';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';

const Index = () => {
    // Other variables
    const page = 'Push notification (User)';

    // redux variables
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { single, bulk } = useSelector((state) => state.userpush.properties);

    // state variables
    const [bulkFormData, setBulkFormData] = useState(null);
    const [singleFormData, setSingleFormData] = useState(null);

    useEffect(() => {
        if(bulk) {
            setBulkFormData(initialState(bulk?.fields));
        }
        if(single) {
            setSingleFormData(initialState(single?.fields))
        }
    }, []);

    const onBulkSubmit = (event) => {
        // send bulk notification
        event.preventDefault();
        const data = bulkFormData;
        if(data.user_ids.length) {
            data.user_ids = data.user_ids.map(el => el.value);
        }
        dispatch(Bulk({ data }));
    };

    const onSingleSubmit = (event) => {
        // send single notification
        event.preventDefault();
        const data = singleFormData;
        dispatch(Single({ data }));
    };
    
    const onBulkChange = (e) => {
        setBulkFormData({ ...bulkFormData, [e.target.id]: e.target.value });
    };
    
    const onSingleChange = (e) => {
        setSingleFormData({ ...singleFormData, [e.target.id]: e.target.value })
    };
    
    const updateBulkForm = (key, value) => {
        // 1- check if new value includes all
        if(key == 'user_ids' && value.some(el => el.value == 'all')){
            // if yes 
            // check old values if includes all
            const old_values = [...(bulkFormData?.user_ids ?? [])];
            if(old_values.some(el => el.value == 'all')){
                // if yes remove (all) keep new values
                setBulkFormData((prev) => {
                    return { ...prev, [key]: value.filter(el => el.value != 'all') };
                });
            } else {
                // if not remove remove all values keep (all) 
                setBulkFormData((prev) => {
                    return { ...prev, [key]: value.filter(el => el.value == 'all') };
                });
            }
        } else {
            // if no update form
            setBulkFormData((prev) => {
                return { ...prev, [key]: value };
            });
        }
    };
    
    const updateSingleForm = (key, value) => {
        setSingleFormData((prev) => {
            return { ...prev, [key]: value };
        })
    };

    return user?.Permissions && <PermissionsManager action="form" name={'userpush'} permissions={user?.Permissions}>
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Box height='100%'>
                {/* Header */}
                <Breadcrumbs page={page.toString()} />
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={1}>
                    
                    {/* Title */}
                    <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: 'start', sm: 'center' }}>
                        <Typography variant='h5' mr={1}>
                            {page}
                        </Typography>
                    </Stack>

                </Stack>

                {/* Forms */}
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center">
                    {/* Bulk Push Notification Form */}
                    <Paper component='form' elevation={0} mt={2} sx={{ width: '100%', borderRadius: 0, height: { md: '75vh', xs: '60vh' }, padding: 4 }} onSubmit={(e) => onBulkSubmit(e)}>
                        <Typography variant='h6' mr={1} mb={1}>Bulk Push Notification</Typography>
                        {
                            bulkFormData && <>
                                <Box sx={{ width: '100%', overflowY: 'auto', borderRadius: 0, height: '96%' }}>
                                    {bulk?.fields?.map((field, i) => (
                                        <Input field={field} onChange={onBulkChange} value={bulkFormData[field?.name]} key={i} updateForm={updateBulkForm} />
                                    ))}
                                </Box>
                                <Stack direction="row" width="100%" spacing={1} alignItems="center" justifyContent='center'>
                                    <Button type="submit" variant="contained" sx={{ borderRadius: 0, padding: '8px 48px', textTransform: 'none' }}>
                                        Send
                                    </Button>
                                </Stack>
                            </>
                        }
                    </Paper>
                    {/* Single Push Notification Form */}
                    <Paper component='form' elevation={0} mt={2} sx={{ width: '100%', borderRadius: 0, height: { md: '75vh', xs: '60vh' }, padding: 4 }} onSubmit={(e) => onSingleSubmit(e)}>
                        <Typography variant='h6' mr={1} mb={1}>Single Push Notification</Typography>
                        {
                            singleFormData && <>
                                <Box sx={{ width: '100%', overflowY: 'auto', borderRadius: 0, height: '96%' }}>
                                    {single?.fields?.map((field, i) => (
                                        <Input field={field} onChange={onSingleChange} value={singleFormData[field?.name]} key={i} updateForm={updateSingleForm} />
                                    ))}
                                </Box>
                                <Stack direction="row" width="100%" spacing={1} alignItems="center" justifyContent='center'>
                                    <Button type="submit" variant="contained" sx={{ borderRadius: 0, padding: '8px 48px', textTransform: 'none' }}>
                                        Send
                                    </Button>
                                </Stack>
                            </>
                        }
                    </Paper>
                </Stack>
            </Box>
        </div>
    </PermissionsManager>
}

export default Index;