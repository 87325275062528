const list = {
    header: [
        {
            id: 'feature.en',
            label: 'English',
            orderBy: 'feature.en',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'feature.ar',
            label: 'Arabic',
            orderBy: 'feature.ar',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'publish',
            label: 'Publish',
            switch: true
        }
    ],
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "mixed",
            name: "feature",
            label: "feature",
            elements: [
                {
                    type: "text",
                    name: "en",
                    rich: true,
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    rich: true,
                    label: "Arabic",
                }
            ],
        },
    ]
};
const create = {
    fields: [
        {
            type: "mixed",
            name: "feature",
            label: "feature",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }
    ]
};
const edit = {
    fields: [
        {
            type: "mixed",
            name: "feature",
            label: "feature",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
    ]
};


const search = {
    fields: [
        {
            type: "text",
            name: "feature.en",
        },
        {
            type: "text",
            name: "feature.ar",
        }
    ]
}

const csv = {
    headers: [
        {
            label: 'English',
            key: 'feature.en',
        },
        {
            label: 'Arabic',
            key: 'feature.ar',
        },
    ]
};

const data = { list, create, edit, show, search, csv }

export default data;