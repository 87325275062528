import { createSlice } from "@reduxjs/toolkit";
import properties from "./properties"
import { getUser, login, logout, verify  } from "./actions";

const initialState = {
    is_authenticated: false,
    loading: true,
    user: null,
    properties
};

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => {
            state.status = "idle";
            state.form_errors = null;
            state.message = null;
        },
    },
    extraReducers: (builder) => {
        // LOGIN
        builder.addCase(login.pending, (state) => {
            state.loading = true
        })
            .addCase(login.fulfilled, (state, { payload }) => {
                state.loading = false
            })
            .addCase(login.rejected, (state) => {
                state.loading = false
            })
            .addCase(verify.pending, (state) => {
                state.loading = true
            })
            .addCase(verify.fulfilled, (state, { payload }) => {
                state.is_authenticated = true;
                state.loading = false
            })
            .addCase(verify.rejected, (state) => {
                state.loading = false
            })
            .addCase(logout.pending, (state) => {
                state.loading = true
            })
            .addCase(logout.fulfilled, (state, { payload }) => {
                state.is_authenticated = false;
                state.loading = false
                state.user = null
            })
            .addCase(logout.rejected, (state) => {
                state.loading = false
            })
            // GET USER
            .addCase(getUser.pending, (state) => {
                state.loading = true
            })
            .addCase(getUser.fulfilled, (state, { payload }) => {
                state.user = payload;
                state.is_authenticated = true;
                state.loading = false
            })
            .addCase(getUser.rejected, (state) => {
                state.loading = false
            })

    }
});

export const { reset } = slice.actions;

export default slice.reducer;