
const edit = {
    fields: [
        {
            type: "text",
            name: "customer_id",
            label: "Customer ID",
            required: true,
        }, {
            type: "text",
            name: "name",
            label: "Name",
            required: true,
        }, {
            type: "password",
            name: "password",
            label: "Password",
            required: true,
        }
    ],
    main: true,
    breadcrumb: 'SMS Settings',
    title: 'SMS Settings',
};

const data = { edit }

export default data;