import React from 'react'
import { Outlet } from 'react-router-dom'
import { list, get, edit } from '../../../features/approved-requests/actions';

const index = () => {

    const auto_sync = true;
    const page = "approved-requests";
    const title = "Approved Later Requests";
    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Outlet context={[{ page, list, get, edit, title }]} />
        </div>
    )
}

export default index