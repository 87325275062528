
const edit = {
    fields: [
        {
            type: "number",
            name: "duration",
            label: "Duration (per second)",
            required: true,
        }, {
            type: "image",
            name: "image",
            label: "Splash Screen",
            required: false,
        }
    ],
    main: true,
    breadcrumb: 'Splash Screen Settings',
    title: 'Splash Screen Settings',
};

const data = { edit }

export default data;