const list = {
    header: [
        {
            id: 'receiver.full_name',
            label: 'Receiver Full Name',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'notification.title',
            label: 'Title',
            align: 'left',
            fallback: 'N/A'
        },
    ],
    permission: 'list',
    hide: {
        create: true,
    },
    actions: ['show'],
};

const data = { list };

export default data;