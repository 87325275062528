const list = {
    header: [
        {
            id: 'code',
            label: 'Code',
            orderBy: 'code',
            align: 'left',
        },
        {
            id: 'value',
            label: 'Percentage %',
            align: 'left',
        },
        {
            id: 'is_used',
            label: 'Is Used',
            align: 'left',
            switch: true,
            disabled: true
        },
        {
            id: 'created_at',
            label: 'Date Added',
            align: 'left',
        },
        {
            id: 'expiration_date',
            label: 'Expiration Date',
            align: 'left'
        },
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "text",
            name: "code",
            label: "Code",
        },
        {
            type: 'number',
            name: 'value',
            label: 'Percentage %'
        },
        {
            type: 'text',
            name: 'created_at',
            label: 'Date Added'
        },
        {
            type: 'date',
            name: 'expiration_date',
            label: 'Expiration Date',
            past_disabled: true,
        }
    ],
    permission: 'show'
};
const create = {
    fields: [
        {
            type: "number",
            name: "quantity",
            label: "No. of codes(*)",
            required: true,
        },
        {
            type: 'number',
            name: 'value',
            label: 'Percentage %',
            required: true
        },
        {
            type: 'date',
            name: 'expiration_date',
            label: 'Expiration Date',
            past_disabled: true
        },
    ],
    permission: 'create'
};
const edit = {
    fields: [
        {
            type: "text",
            name: "value",
            label: "Percentage %",
        },
        {
            type: 'date',
            name: 'expiration_date',
            label: 'Expiration Date',
            past_disabled: true
        },
    ],
    permission: 'edit'
};


const search = {
    fields: [
        {
            type: "text",
            name: "code",
        }
    ]
}

const csv = {
    headers: [
        {
            key: 'code',
            label: 'Code',
        },
        {
            key: 'value',
            label: 'Percentage %',
        },
        {
            key: 'is_used',
            label: 'Is Used',
        },
        {
            key: 'created_at',
            label: 'Date Added',
        },
        {
            key: 'expiration_date',
            label: 'Expiration Date',
        },
    ]
}


const data = { list, create, edit, show, search, csv }

export default data;