const list = {
    header: [
        {
            id: 'text.en',
            label: 'English',
            orderBy: 'text.en',
            align: 'left',
        },
        {
            id: 'text.ar',
            label: 'Arabic',
            orderBy: 'text.ar',
            align: 'left',
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true,
        }
    ],
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "mixed",
            name: "text",
            label: "Text",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
    ]
};
const create = {
    fields: [
        {
            type: "mixed",
            name: "text",
            label: "Text",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }
    ]
};
const edit = {
    fields: [
        {
            type: "mixed",
            name: "text",
            label: "Text",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
    ]
};


const search = {
    fields: [
        {
            type: "text",
            name: "reason.en",
        },
        {
            type: "text",
            name: "reason.ar",
        }
    ]
}

const order = {
    fields: [
        {
            id: 'text',
            child: 'en',
            label: 'text',
            align: 'left',
        },
    ],
    permission: 'order'
};


const data = { list, create, edit, show, search, order }

export default data;