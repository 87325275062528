import { createAsyncThunk } from "@reduxjs/toolkit";
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from "../../utils/api";
import { setAlert } from "../alerts/actions";



export const get = createAsyncThunk("social/get", async (id, { dispatch }) => {
    try {
        const res = await api.get(`/admin/social`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const edit = createAsyncThunk("social/edit", async ({ data, navigate }, { dispatch }) => {
    try {
        await api.put(`/admin/social`, data);
        dispatch(setAlert({ message: 'Social media settings updated successfully', alertType: 'success' }))
        navigate(`/cms/social media`);
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});
