const list = {
  header: [
    {
      id: "user.fullName",
      label: "Customer Name",
      order: false,
      fallback: "N/A",
    },

    {
      id: "pickup.location_name",
      label: "Pickup Location",
      fallback: "N/A",
      order: false,
    },
    {
      id: "destination.location_name",
      label: "Drop off Location",
      fallback: "N/A",
      order: false,
    },
    {
      id: "driver.driverName",
      label: "Driver Name",
      fallback: "N/A",
      order: false,
    },
    {
      id: "class.title.en",
      label: "Car Class",
      fallback: "N/A",
      order: false,
    },
    {
      id: "total_distance",
      label: "Total Distance (KM)",
      fallback: "N/A",
      order: false,
    },
    {
      id: "price.base_fare",
      label: "Base Fare (QAR)",
      fallback: "N/A",
      order: false,
    },
    {
      id: "total_time",
      label: "Total Time (min)",
      fallback: "N/A",
      order: false,
    },
    {
      id: "total",
      label: "Trip Total (QAR)",
      fallback: "N/A",
      order: false,
    },
    {
      id: "package.title.en",
      label: "Request Type",
      fallback: "N/A",
      order: false,
    },
    {
      id: "status",
      label: "Request Status",
      fallback: "N/A",
      order: false,
    },
    {
      id: "payment_type.type.en",
      label: "Payment Type",
      fallback: "N/A",
      order: false,
    },
  ],
  permission: "list",
  hide: {
    create: true,
  },
  actions: [],
};

const search = {
  fields: [
    {
      type: "text",
      name: "$user.first_name$",
    },
    {
      type: "text",
      name: "$user.last_name$",
    },
  ],
};

const filter = {
  fields: [
    {
      type: "text",
      name: "guest.fullname",
      label: "Customer Name",
    },
    {
      type: "select",
      async: true,
      name: "driver_id",
      route: "drivers-list",
      label: "Driver",
    },
    {
      type: "select",
      name: "class_id",
      label: "Car Class",
      async: true,
      route: "classes-list",
    },
    {
      type: "text",
      name: "total_distance",
      label: "Total Distance",
      async: true,
    },
    {
      type: "text",
      name: "$price.base_fare$",
      label: "Base fare",
    },{
        type: "text",
        name: "total",
        label: "Trip Total",
      },
  

    {
      type: "select",
      async: true,
      name: "package_id",
      route: "packages",
      label: "Packages",
    },

    {
      type: "date",
      name: "date.from",
      label: "Date From",
    },
    {
      type: "date",
      name: "date.to",
      label: "Date To",
    },
    {
      type: "select",
      name: "status",
      label: "Status",
      values: [
        { value: "done", label: "Done" },
        { value: "waiting", label: "Waiting" },
        { value: "accepted", label: "Accepted" },
        { value: "canceled", label: "Canceled" },
        { value: "in progress", label: "In Progress" },
        { value: "payment-pending", label: "Payment Pending" },
      ],
    },
    {
      type: "select",
      async: true,
      name: "payment_id",
      route: "payment_methods",
      label: "Payment Methods",
    },
  ],
};

const csv = {
  headers: list.header.map((item) => ({
    key: item.id,
    label: item.label,
  })),
};
const data = { list, search, csv, filter };

export default data;
