import { createAsyncThunk } from "@reduxjs/toolkit";
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from "../../utils/api";
import { setAlert } from "../alerts/actions";

export const list = createAsyncThunk("blackout/list", async (params = { page: 0, limit: 10, orderBy: null, orderDirection: null, filter: null, search: null }) => {
    try {
        const page = params.page ? params.page : 0
        const limit = params.limit ? params.limit : 10
        const res = await api.get(`/admin/blackout`, { params });
        
        return { ...res.data, page, limit };
    } catch (error) {
        console.log(error);
    }
});

export const create = createAsyncThunk("blackout/create", async ({ data, navigate }, { dispatch }) => {
    try {
        const res = await api.post('/admin/blackout', data);
        dispatch(list({ filter: { startDate: data.startDate, endDate: data.endDate } }));
        dispatch(setAlert({ message: 'Blackout day Added successfully', alertType: 'success' }))
        if (navigate)
            navigate(`/cms/blackout`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const remove = createAsyncThunk("blackout/remove", async (data, { dispatch }) => {
    try {
        const { id, startDate, endDate } = data;
        await api.delete(`/admin/blackout/${id}`);
        dispatch(setAlert({ message: 'Blackout day removed successfully', alertType: 'error' }))
        dispatch(list({ filter: { startDate, endDate } }));
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});