
const list = {
    header: [
     
        {
            id: 'driverName',
            label: 'Driver Name',
            orderBy: 'driverName',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'car.name',
            label: 'Car type',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'car.plate',
            label: 'Car Plate',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'created_at',
            label: 'Registration Date',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'email',
            label: 'Email',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'country_code',
            label: 'Country Code',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'mobile_number',
            label: 'Mobile Number',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'average_rating',
            label: 'Rate',
            align: 'left',
            rate: true,
            order: false,
            fallback: 'N/A'
        },    
        {
            id: 'online',
            label: 'Driver Status',
            align: 'left',
            bool: true,
            true_text: 'Online',
            false_text: 'Offline',
            fallback: 'N/A'
        },
        {
            id: 'last_login',
            label: 'Last Login',
            align: 'left',
            fallback: 'N/A'
        },
      
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};

const show = {
    fields: [
        {
            type: 'image',
            name: 'image',
            label: 'Image'
        },
        {
            type: "text",
            name: "country_code",
            label: "Country Code",
            required: true
        },
        {
            type: "number",
            name: "mobile_number",
            label: "Mobile Number",
            required: true,
        },
        {
            type: "select",
            name: "gender",
            label: "Gender",
            required: true,
            values: [
                { value: 0, label: 'Male' },
                { value: 1, label: 'Female' }
            ],
        },
        {
            type: "mixed",
            name: "first_name",
            label: "First Name",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "mixed",
            name: "last_name",
            label: "Last Name",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "text",
            name: "email",
            label: "Email",
            required: true
        },
        {
            type: 'text',
            name: 'dob',
            label: 'Date of birth',
            required: true
        },
        {
            type: "mixed",
            name: "car",
            label: "Car",
            elements: [
                {
                    type: 'text',
                    name: 'name',
                    label: 'Name'
                },
                {
                    type: "text",
                    name: "plate",
                    label: "Plate Number",
                },
            ],
        },
        {
            type: "switch",
            name: "block",
            label: "Block",
        },
    ],
    permission: 'show'
};

const create = {
    fields: [
        {
            type: 'image',
            name: 'image',
            label: 'Image',
            required: true
        },
        {
            type: "text",
            name: "country_code",
            label: "Country Code (+974)",
            required: true,
            default_value: '+',
            max_length: 4
        },
        {
            type: "number",
            name: "mobile_number",
            label: "Mobile Number",
            required: true,
        },
        {
            type: "select",
            name: "gender",
            label: "Gender",
            required: true,
            values: [
                { value: 0, label: 'Male' },
                { value: 1, label: 'Female' }
            ],
        },
        {
            type: "mixed",
            name: "first_name",
            label: "First Name",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: "mixed",
            name: "last_name",
            label: "Last Name",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: 'email',
            name: 'email',
            label: 'Email',
            required: true
        },
        {
            type: 'text',
            name: 'dob',
            label: 'Date of birth (dd/MM/YYYY)',
            required: true
        },
        {
            type: 'select',
            async: true,
            name: 'car_id',
            label: 'Car',
            route: 'cars'
        },
        {
            type: 'password',
            name: 'password',
            label: 'Password',
            required: true
        },
        {
            type: 'password',
            name: 'confirm_password',
            label: 'Confirm Password',
            required: true
        }
    ],
    permission: 'create'
};

const edit = {
    fields: [
        {
            type: 'image',
            name: 'image',
            label: 'Image',
            required: false
        },
        {
            type: "text",
            name: "country_code",
            label: "Country Code (+974)",
            required: true,
            max_length: 4
        },
        {
            type: "number",
            name: "mobile_number",
            label: "Mobile Number",
            required: true,
        },
        {
            type: "select",
            name: "gender",
            label: "Gender",
            required: true,
            values: [
                { value: 0, label: 'Male' },
                { value: 1, label: 'Female' }
            ],
        },
        {
            type: "mixed",
            name: "first_name",
            label: "First Name",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: "mixed",
            name: "last_name",
            label: "Last Name",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: 'email',
            name: 'email',
            label: 'Email',
            required: true
        },
        {
            type: 'text',
            name: 'dob',
            label: 'Date of birth (dd/MM/YYYY)',
            required: true
        },
        {
            type: 'select',
            async: true,
            name: 'car_id',
            label: 'Car',
            route: 'cars?driver_id=',
            multiple: false,
            with_id: true
        },
        {
            type: "switch",
            name: "block",
            label: "Block",
            required: true,
        },
        {
            type: 'password',
            name: 'password',
            label: 'Password',
        },
        {
            type: 'password',
            name: 'confirm_password',
            label: 'Confirm Password',
        }
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: "text",
            name: "first_name.en",
        },
        {
            type: "text",
            name: "last_name.en",
        },
        {
            type: 'text',
            name: 'mobile_number'
        },
        {
            type: 'text',
            name: 'email'
        }
    ]
}

const filter = {
    fields: [
        {
            type: 'text',
            name: 'first_name.en',
            label: 'First  Name'
        },
        {
            type: 'text',
            name: 'last_name.en',
            label: 'Last  Name'
        },
        {
            type: 'text',
            name: 'email',
            label: 'Email'
        },
        {
            type: 'number',
            name: 'mobile_number',
            label: 'Mobile Number'
        },
        
        {
            type: 'select',
            name: 'online',
            label: 'Online',
            values: [
                {value: true, label: 'Online'},
                {value: false, label: 'Offline'},
            ]
        },
        
        {
            type: 'date',
            name: 'date.from',
            label: 'Date From',
        },
        {
            type: 'date',
            name: 'date.to',
            label: 'Date To',
        }
    ]
}

const csv = {
    headers: list.header.map(item => ({
      key: item.id,
      label: item.label,
    }))
  };


const data = { list, create, edit, show, search, csv, filter }

export default data;