const list = {
    header: [
        {
            id: 'full_name',
            label: 'Name',
            align: 'left',
            orderBy: 'first_name',
        },
        {
            id: 'email',
            label: 'Email',
            align: 'left',
        },
        {
            id: 'block',
            label: 'Block',
            align: 'left',
            switch: true,
        }
    ],
    actions: ['bulk remove', 'show', 'delete', 'update']
};
const show = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image",
        }, {
            type: "text",
            name: "first_name",
            label: "First Name",
        },
        {
            type: "text",
            name: "last_name",
            label: "Last Name",
        },

        {
            type: "email",
            name: "email",
            label: "Email",
        },

        {
            type: "switch",
            name: "block",
            label: "Block",
        },
    ]
};
const create = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image",
        },
        {
            type: "text",
            name: "first_name",
            label: "First Name",
            required: true
        },
        {
            type: "text",
            name: "last_name",
            label: "Last Name",
            required: true
        },
        {
            type: "email",
            name: "email",
            label: "Email",
            required: true
        },
        {
            type: "select",
            async: true,
            name: "roles",
            label: "Role",
            multiple: true,
            required: true
        },
        {
            type: "password",
            name: "password",
            label: "Password",
            required: true
        },
    ]
};
const edit = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image",
            required: false
        },
        {
            type: "text",
            name: "first_name",
            label: "First Name",
            required: true
        },
        {
            type: "text",
            name: "last_name",
            label: "Last Name",
            required: true
        },
        {
            type: "email",
            name: "email",
            label: "Email",
            required: true
        },
        {
            type: "select",
            async: true,
            multiple: true,
            name: "Roles",
            label: "Role",
            required: true
        },
        {
            type: "password",
            name: "password",
            label: "Password",
        },

    ]
};
const filter = {
    fields: [
        {
            type: "text",
            name: "first_name",
            label: "First Name",
        },
        {
            type: "text",
            name: "last_name",
            label: "Last Name",
        },
        {
            type: "email",
            name: "email",
            label: "Email",
        },

    ]
};


const search = {
    fields: [{
        type: "text",
        name: "first_name",
    },
    {
        type: "text",
        name: "last_name",
    },
    {
        type: "email",
        name: "email",
    },
    ]
}

const csv = {
    headers: [
        {
            label: 'Name',
            key: 'full_name',
        },
        {
            label: 'Email',
            key: 'email',
        },
        {
            label: 'Block',
            key: 'block',
        }
    ]
}

const data = { list, create, edit, filter, show, search, csv }

export default data;