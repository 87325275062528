import { createSlice } from "@reduxjs/toolkit";
import properties from "./properties"
import { list, get, count, count_waiting } from "./actions";

// State on app load and refresh
const initialState = {
    items: [], // will hold the list of items
    count: null,
    unread: 0,
    new: 0,
    waiting: null,
    playSound: false,
    itemsLoading: true,
    item: null, // will hold one item for edit
    itemLoading: true,
    page_number: 0,
    show_popup: false,
    limit: 10,
    properties
};

// Create the Users Slice
const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        // Resets the state but keeps the data
        reset: (state) => {
            state.items = null// will hold the list of items
            state.itemsLoading = false
            state.item = null// will hold one item for edit
            state.itemLoading = false
        },
        hide: (state) => {
            state.show_popup = false;
        }
    },
    // pending: request send but response hasn't returned yet
    // fullfilled: response returned with successfull status code
    // rejected: request failed or response returned with error status code
    extraReducers: (builder) => {
        builder.addCase(list.pending, (state) => {
            // state.itemsLoading = true
        })
            .addCase(list.fulfilled, (state, { payload }) => {
                state.items = payload.data;
                state.count = payload.count;
                state.page_number = payload.page;
                state.limit = payload.limit;
                state.itemsLoading = false
            })
            .addCase(list.rejected, (state) => {
                state.itemsLoading = false
            })

            .addCase(get.pending, (state) => {
                state.itemLoading = true;
            })
            .addCase(get.fulfilled, (state, { payload }) => {
                state.itemLoading = false;
                state.item = payload.data;
            })
            .addCase(get.rejected, (state) => {
                state.itemLoading = false;

            })
            
            .addCase(count.pending, (state) => {
                state.itemLoading = true;
            })
            .addCase(count.fulfilled, (state, { payload }) => {
                state.itemLoading = false;
                state.unread = payload.data;
            })
            .addCase(count.rejected, (state) => {
                state.itemLoading = false;
            })

            .addCase(count_waiting.pending, (state) => {
                state.itemLoading = true;
            })
            .addCase(count_waiting.fulfilled, (state, { payload }) => {
                state.itemLoading = false;
                if(payload.data.waiting > 0 && payload.data.waiting > state.waiting) {
                    state.show_popup = true;
                }
                if(payload.data.waiting > 0){
                    state.waiting = payload.data.waiting;
                } else {
                    state.waiting = null;
                }
                if(state.new < payload.data.new) {
                    state.playSound = true;
                } else {state.playSound = false}
                state.new = payload.data.new;
            })
            .addCase(count_waiting.rejected, (state) => {
                state.itemLoading = false;
            })
    },
});

// Export the slice actions
export const { reset, hide } = notificationsSlice.actions;

// Export the slice reducer
export default notificationsSlice.reducer;
