import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Footer from '../layouts/Footer'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, login, verify } from '../../features/auth/actions';
import Icon from '../layouts/Icon';
import data from '../../features/sms/properties';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [pinInfo, setPinInfo] = useState(null);
    const [pin, setPin] = useState(null);
    const { loading, user, is_authenticated } = useSelector((state) => state.auth);

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [visibility, setVisibility] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        const result = await dispatch(login({ data: formData, navigate }));
        if(result.payload != 'Error') {
            setPinInfo({...result.payload});
            setOpen(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setPin(null);
    }

    const handleSubmit = () => {
        dispatch(verify({data: { type: pinInfo?.type, pin: pin}, verification_token: pinInfo?.token, navigate}));
        setOpen(false);
        setPin(null);
    }


    useEffect(() => {
        if (loading && !is_authenticated) {
            dispatch(getUser())
        }
        if (is_authenticated) {
            navigate('/cms')
        }
    }, [loading, user, is_authenticated, dispatch, navigate])

    return (
        <Grid
            component="main"
            backgroundColor="#F6F7FB"
            height="100vh"
            width="100%"
            container
            alignItems='start'
            justifyContent='center'
        >
            <Grid component={Stack} item xs={10} sm={6} md={5} lg={2} mt={2} sx={{ backgroundColor: "#F6F7FB", borderRadius: 0, height: '100%' }} alignItems="center" justifyContent='start' direction="column">
                <Typography variant='h1' fontSize="1.625rem" fontWeight={600} lineHeight={1.5} color="primary" textAlign='center' marginTop={5}>Welcome to <b>Prestige</b> Dashboard!</Typography>
                <Paper component='form' sx={{ width: '100%', borderRadius: 0, padding: { lg: "3rem", xs: "2rem" }, marginY: '70px', boxShadow: '0 2px 5px 2px rgba(0, 0, 0, 0.1)' }} onSubmit={onSubmit}>
                    <Typography variant='h3' fontSize="1.0625rem" fontWeight={600} lineHeight={1.5} textAlign="center" color="#454655" marginBottom='2rem'>Login with your credentials</Typography>
                    <div className="form-control">
                        <input className="form-input" type="email" required={true} name='email' value={formData.email} onChange={(e) => onChange(e)} placeholder="Email" />
                    </div>
                    <div className="form-control">
                        <div className="form-input-password">
                            <input type={!visibility ? "password" : "text"} required={true} name='password' id='password' value={formData.password} onChange={(e) => onChange(e)} placeholder="Password" />
                            <IconButton
                                edge="start"
                                onClick={() => { setVisibility(!visibility) }}
                                size="small"
                                sx={{ padding: 0 }}
                                disableRipple
                            >

                                {!visibility ? <Icon type={'Visibility'} color={'gray'} /> : <Icon type={'VisibilityOff'} color={'gray'} />}
                            </IconButton>
                        </div>
                    </div>
                    <Stack direction="row" width="100%" spacing={1} alignItems="center" justifyContent='center'>
                        <Button type="submit" variant="contained" sx={{ borderRadius: 0, padding: '8px 48px', textTransform: 'none', marginTop: 2 }}>
                            <b>Login</b>
                        </Button>
                    </Stack>
                </Paper>
                <Footer direction={'column'} justifyContent={'center'} />
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Enter Verification PIN</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the 4-digit verification PIN sent to your email.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Verification PIN"
                        type="text"
                        fullWidth
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid >
    )
}

export default Login