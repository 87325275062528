const list = {
    header: [
        {
            id: 'user.fullName',
            label: 'Customer Name',
            orderBy: 'user:first_name',
            align: 'left',  
            fallback: 'N/A'  
        },
        {
            id: 'user.mobile_number',
            label: 'Mobile Number',
            orderBy: 'user:mobile_number',
            align: 'left',
            fallback: 'N/A'
        },
       
        {
            id: 'user.email',
            label: 'Email',
            orderBy: 'user:email',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'body',
            label: 'Message',
            maxLength: 50,
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'created_at',
            label: 'Date',
            align: 'left',
            fallback: 'N/A'
        }
    ],
    actions: ['bulk remove', 'show', 'delete'],
    hide: {
        breadcrumbs: false,
        create: true,
        count: false,
        title: false
    }
};

const show = {
    fields: [
        {
            type: "text",
            name: 'user.fullName',
            label: 'Customer Name',
            child: 'fullName',
        },
        {
            type: "text",
            name: 'user.fullMobile',
            label: 'Mobile Number',
            child: 'mobile_number',
        },
        {
            type: "text",
            name: 'user.email',
            label: 'Email',
            child: 'email',
        },
        {
            type: "mixed",
            name: "feedback_type.text",
            child: 'text',
            label: "Type",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "text",
            name: "body",
            label: "Message",
        },
    ],
    hide: {
        breadcrumbs: false,
        edit: true,
        back: false,
        title: false
    }
};

const filter = {
    fields: [
        {
            type: "text",
            name: "$user.first_name$",
            label: "Customer's First Name",
        },
        {
            type: "text",
            name: "$user.last_name$",
            label: "Customer's Last Name",
        },
      
        {
            type: "tel",
            name: "$user.mobile$",
            label: "Customer's Mobile Number",
        },
        {
            type: "email",
            name: "$user.email$",
            label: "Customer's Email",
        },
        {
            type: 'date',
            name: 'date.from',
            label: 'Date From',
        },
        {
            type: 'date',
            name: 'date.to',
            label: 'Date To',
        }
    ]
};

const search = {
    fields: [
        {
            type: "text",
            name: "$user.first_name$",
        },
        {
            type: "text",
            name: "$user.last_name$",
        },
        // { Add search relation to json
        //     type: 'text',
        //     name: '$feedback_type.text.en$'
        // },
        {
            type: "text",
            name: "$user.mobile_number$",
        },
        {
            type: "text",
            name: "$user.email$",
        },
        {
            type: "text",
            name: "body",
        },
    ]
};

const csv = {
    headers: list.header.map(item => ({
      key: item.id,
      label: item.label,
    }))
  };

const data = { list, filter, show, search, csv }

export default data;