const list = {
    header: [
        {
            id: 'icon',
            display: true,
            label: 'Icon',
            align: 'left',
        },
        {
            id: 'title.en',
            label: 'Title',
            orderBy: 'title.en',
            align: 'left',
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true,
        },
        {
            id: 'available',
            label: 'Available',
            align: 'left',
            switch: true,
        }
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "image",
            name: "icon",
            label: "Icon",
        },
        {
            type: "image",
            name: "image",
            label: "Image",
        }, 
        {
            type: "text",
            name: "type",
            label: "Type",
            required: true,
        },
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "mixed",
            name: "subtitle",
            label: "Subtitle",
            elements: [
                {
                    type: "text",
                    name: "en",
                    rich: true,
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    rich: true,
                    label: "Arabic",
                }
            ],
        },
        {
            type: "mixed",
            name: "description",
            label: "Description",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: 'number',
            name: 'hours',
            label: 'Hours'
        }
    ],
    permission: 'show'
};
const create = {
    fields: [
        {
            type: "image",
            name: "icon",
            label: "Icon",
            required: true,
        }, 
        {
            type: "image",
            name: "image",
            label: "Image",
        }, 
        {
            type: "select",
            name: "type",
            label: "Type",
            required: true,
            values: [
                { value: 'hourly', label: 'Hourly'},
                { value: 'airport', label: 'Airport'},
                { value: 'single', label: 'Single'},
            ]
        },
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }, 
        {
            type: "mixed",
            name: "subtitle",
            label: "Subtitle",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    rich: true,
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    rich: true,
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: "mixed",
            name: "description",
            label: "Description",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    rich: true,
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    rich: true,
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: 'number',
            name: 'hours',
            label: 'Hours',
            depend_on: 'type',
            depend_value: 'hourly',
            depend_action: 'show'
        }
    ],
    permission: 'create'
};
const edit = {
    fields: [
        {
            type: "image",
            name: "icon",
            label: "Icon",
        }, 
        {
            type: "image",
            name: "image",
            label: "Image",
        }, 
        {
            type: "select",
            name: "type",
            label: "Type",
            required: true,
            values: [
                { value: 'hourly', label: 'Hourly'},
                { value: 'airport', label: 'Airport'},
                { value: 'single', label: 'Single'},
            ]
        },
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }, {
            type: "mixed",
            name: "subtitle",
            label: "Subtitle",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    rich: true,
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    rich: true,
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: "mixed",
            name: "description",
            label: "Description",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    rich: true,
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    rich: true,
                    required: true,
                }
            ],
            required: true,
        },
        {
            type: 'number',
            name: 'hours',
            label: 'Hours',
            depend_on: 'type',
            depend_value: 'hourly',
            depend_action: 'show'
        }
    ],
    permission: 'edit'
};

const order = {
    fields: [{
        id: 'icon',
        display: true,
        label: 'Icon',
        align: 'left',
    },
    {
        id: 'title',
        child: 'en',
        label: 'Title',
        align: 'left',
    },],
    permission: 'order'
};

const search = {
    fields: [
        {
            type: "text",
            name: "title.en",
        }
    ]
}

const csv = {
    headers: [
        {
            key: 'icon',
            display: true,
        },
        {
            key: 'image',
            display: true,
        },
        {
            key: 'title.en',
            label: 'Title',
        },
        {
            key: 'publish',
            label: 'Publish',
        },
        {
            key: 'available',
            label: 'Available',
        }
    ]
}


const data = { list, create, edit, show, order, search, csv }

export default data;