const list = {
    header: [
        {
            id: 'driver.fullName',
            label: 'Driver',
            orderBy: 'driver.fullName',
            align: 'left'
        },
        {
            id: 'car.plate',
            label: 'Plate number',
            align: 'left'
        },
        {
            id: 'car.class.title.en',
            label: 'Model',
            align: 'left'
        },
        {
            id: 'mileage',
            label: 'Mileage',
            align: 'left',
        },
        {
            id: 'created_at',
            label: 'Date',
            align: 'left'
        }
    ],
    hide: {
        create: true
    },
    permission: 'list',
    actions: ['show']
}

const show = {
    fields: [
        {
            type: "text",
            name: 'driver.fullName',
            label: "Driver"
        },
        {
            type: 'text',
            name: 'created_at',
            label: 'Date'
        },
        {
            type: 'text',
            name: 'car.plate',
            label: 'Plate_number'
        },
        {
            type: 'mixed',
            name: 'car.class.title',
            label: 'Model',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                }
            ]
        },
        {
            type: 'number',
            name: 'mileage',
            label: 'Mileage'
        },
        {
            type: 'number',
            name: 'level_before',
            label: 'Level before'
        },
        {
            type: 'number',
            name: 'level_after',
            label: 'Level after'
        },
        {
            type: 'number',
            name: 'fuel_price',
            label: 'Fuel price at real time'
        },
    ],
    permission: 'show'
};

const search = {
    fields: [
        {
            type: 'text',
            name: '$driver.fullName.en$'
        }
    ]
}

const filter = {
    fields: [
        {
            type: 'select',
            async: true,
            name: 'driver_id',
            route: 'drivers-list',
            label: 'Driver',
            required: true
        },
        {
            type: 'select',
            async: true,
            name: 'car_id',
            route: 'cars-list',
            label: 'Cars',
            required: true
        },
        {
            type: 'date',
            name: 'date.from',
            label: 'Date From',
        },
        {
            type: 'date',
            name: 'date.to',
            label: 'Date To',
        }
    ]
}

const csv = {
    headers: [
        {
            key: 'driver.fullName',
            label: 'Driver',
        },
        {
            key: 'car.plate',
            label: 'Plate number',
        },
        {
            key: 'car.class.title.en',
            label: 'Model',
        },
        {
            key: 'mileage',
            label: 'Mileage',
        },
    ]
}

const data = { list, show, search, csv, filter };

export default data;