import Icon from './Icon';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { logout } from '../../features/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { count } from '../../features/notifications/actions';
import { AppBar, Badge, Box, Button, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material'


const Navbar = ({ drawerWidth, handleDrawerToggle }) => {
    const audioRef = useRef(null);
    const dispatch = useDispatch();
    const [notificationNumber, setNotificationNumber] = useState(0);
    const unread = useSelector(state => state.notifications.unread)
    const user = useSelector(state => state.auth.user)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (unread && unread != notificationNumber) {
            setNotificationNumber(unread);
            playSound();
        }
    }, [unread]);

    const playSound = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    useEffect(() => {
        dispatch(count());
        const intervalId = setInterval(() => {
            dispatch(count());
        }, 15000);

        return () => {
            clearInterval(intervalId);
        }
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <AppBar
            position="fixed"
            sx={{
                width: { lg: `calc(100% - ${drawerWidth}px)` },
                ml: { lg: `${drawerWidth}px` },
                backgroundColor: '#fff',
                boxShadow: 'none',
                borderBottom: "1px solid #DDE1E5"
            }}
        >
            <Toolbar color="#454655">

                <IconButton
                    color="#1C1C38"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { lg: 'none' } }}
                >
                    <Icon type={'Menu'} color={'gray'} />
                </IconButton>
                <Typography color="#454655" variant="h6" noWrap component="div" sx={{ display: { lg: 'none' }, flexGrow: 1 }}>
                    <Link href="/" color="inherit" underline="none" ><b>Prestige</b></Link>
                </Typography>

                <div style={{visibility: 'hidden', width: 0, height: 0, position: 'fixed', left: -50000, top: -10000 }}>
                    <audio ref={audioRef}>
                        <source src="https://tedmob-dop-files.s3.amazonaws.com/prestige/cms-notification/horn.mp3" type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>

                <Box sx={{ width: { lg: '100%' }, textAlign: { lg: 'end' } }}>
                    <Link
                        id='notification button'
                        href='/cms/notifications'
                        mr={1}
                        sx={{ textTransform: "none", color: "#454655", focusVisible: 'none', fontSize: "1.1rem" }}
                    >
                        <Badge badgeContent={unread} color='info' >
                            <Icon type={'Notifications'} color={'gray'} />
                        </Badge>
                    </Link>
                    <Button
                        size='large'
                        id="user-button"
                        disableRipple
                        aria-controls={open ? 'user-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ textTransform: "none", color: "#454655", focusVisible: 'none', fontSize: "1.1rem" }}
                        endIcon={<Icon type={'MoreVert'} color={'gray'} />}
                    >
                        {user?.full_name}
                    </Button>
                    <Menu
                        id="user-menu"
                        aria-labelledby="user-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem component={RouterLink} to="/cms/profile">
                            <ListItemIcon>
                                <Icon type={'Person'} color={'gray'} fontSize={'small'} />
                            </ListItemIcon>
                            <ListItemText>Profile</ListItemText>

                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <Icon type={'Settings'} color={'gray'} fontSize={'small'} />
                            </ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            dispatch(logout())
                            handleClose()
                        }}>
                            <ListItemIcon>
                                <Icon type={'Logout'} color={'gray'} fontSize={'small'} />
                            </ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    )
}




export default Navbar