const list = {
    header: [
        {
            id: 'driver.fullName',
            label: 'Driver',
            orderBy: 'driver.fullName',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'level_before',
            label: 'Before Gauge',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'level_after',
            label: 'After Gauge',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'mileage',
            label: 'Mileage',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'car.plate',
            label: 'Car Plate',
            align: 'left',
            fallback: 'N/A'
        },
      
    
        {
            id: 'time',
            label: 'Date',
            align: 'left',
            fallback: 'N/A'
        }
    ],
    hide: {
        create: true
    },
    permission: 'list',
    actions: ['show']
}

const show = {
    fields: [
        {
            type: "text",
            name: 'driver.fullName',
            label: "Driver"
        },
        {
            type: 'text',
            name: 'time',
            label: 'Date'
        },
        {
            type: 'text',
            name: 'car.plate',
            label: 'Plate_number'
        },
        {
            type: 'mixed',
            name: 'car.class.title',
            label: 'Model',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                }
            ]
        },
        {
            type: 'number',
            name: 'mileage',
            label: 'Mileage'
        },
        {
            type: 'number',
            name: 'level_before',
            label: 'Level before'
        },
        {
            type: 'number',
            name: 'level_after',
            label: 'Level after'
        },
        {
            type: 'number',
            name: 'fuel_price',
            label: 'Fuel price at real time'
        },
    ],
    permission: 'show'
};

const search = {
    fields: [
        {
            type: 'text',
            name: 'driver.fullName.en'
        }
    ]
}

const csv = {
    headers: list.header.map(item => ({
      key: item.id,
      label: item.label,
    }))
  };

const filter = {
    fields: [
        {
            type: 'select',
            async: true,
            name: 'driver_id',
            route: 'drivers-list',
            label: 'Driver',
            required: true
        },
        {
            type: 'number',
            name: 'level_before',
            label: 'Before Gauge'
        },
        {
            type: 'number',
            name: 'level_after',
            label: 'After Gauge'
        },
        {
            type: 'number',
            name: 'mileage',
            label: 'Mileage'
        },
        {
            type: 'text',
            name: '$car.plate$',
            label: 'Car Plate'
        },
        {
            type: 'select',
            async: true,
            name: 'car_id',
            route: 'cars-list',
            label: 'Cars',
            required: true
        },
        {
            type: 'date',
            name: 'date.from',
            label: 'Date From',
        },
        {
            type: 'date',
            name: 'date.to',
            label: 'Date To',
        }
    ]
}

const data = { list, show, search, csv, filter };

export default data;