const list = {
    header: [

        {
            id: 'title',
            label: 'Title',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'image',
            label: 'Image',
            align: 'left',
            display: true,
            fallback: 'N/A'
        },
        {
            id: 'text',
            label: 'Message',
            align: 'left',
            fallback: 'N/A'
        },

        {
            id: 'type',
            label: 'Type',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'is_driver',
            label: 'Driver',
            align: 'left',
            bool: true,
            true_text: 'Driver Notification',
            false_text: 'User Notification',
            fallback: 'N/A'
        },
        {
            id: 'created_at',
            label: 'Date Created',
            align: 'left',
            fallback: 'N/A'
        }
    ],
    permission: 'list',
    hide: {
        create: true
    },
    actions: ['show'],
};
const show = {
    fields: [
        {
            type: "text",
            name: "title",
            label: "Title"
        },
        {
            type: "text",
            name: "text",
            label: "Text",
            rich: true
        },
        {
            type: "text",
            name: "message",
            label: "Message",
            rich: true
        },
        {
            type: "text",
            name: "type",
            label: "Type"
        },
    ],
    buttons: [
        {
            component: 'NotificationReceivers',
            parent: 'notifications',
            permission: 'show',
        },
    ],
    permission: 'show'
};

const search = {
    fields: [
        {
            type: "text",
            name: "title",
        },
        {
            type: "text",
            name: "message",
        },
        {
            type: "text",
            name: "text",
        },
        {
            type: "text",
            name: "type",
        },
    ]
}
const filter = {
    fields: [
        {
            type: 'text',
            name: 'title',
            label: 'Title',
            async: true,
        },
        {
            type: 'text',
            name: 'text',
            label: 'Message',
            async: true,

        },
        {
            type: 'date',
            name: 'date.from',
            label: 'Date From',
        },
        {
            type: 'date',
            name: 'date.to',
            label: 'Date To',
        }
    ]
}

const csv = {
    headers: list.header.map(item => ({
        key: item.id,
        label: item.label,
    }))
};

const data = { list, show, search, csv, filter };

export default data;