import React from 'react'
import { Outlet } from 'react-router-dom'
import { list, get } from '../../../features/rejected-appointments/actions';

const index = () => {
    const auto_sync = true;
    const page = "rejected-appointments";
    const title = "Canceled Active Bookings";
    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Outlet context={[{ page, list, get,title, auto_sync }]} />
        </div>
    )
}

export default index