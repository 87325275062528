import { Button, IconButton, Stack, TextField } from "@mui/material"
import { useState } from "react";
import Icon from '../../layouts/Icon';

const Search = ({ filter, search, dispatch, list, formData, setFormData, order, setOrder }) => {
    const [searched, setSearched] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        if (formData !== "") {
            const data = { keys: search?.fields.map((item) => item.name).join(','), value: formData };
            const filterData = {};
            for (const key in filter) {
                if (`${filter[key]}`.length > 0) {
                    filterData[key] = filter[key]
                    continue;
                }
            }
            dispatch(list({ filter: filterData, search: data }),);
            setOrder([])
            setSearched(true);
        }
    }

    const clear = (e) => {
        e.preventDefault();
        setFormData('')
        setOrder([])
        setSearched(false);
        const filterData = {};
        for (const key in filter) {
            if (`${filter[key]}`.length > 0) {
                filterData[key] = filter[key]
                continue;
            }
        }
        dispatch(list({ filter: filterData }));
    }

    return (
        <Stack className="form-control" mb={2} direction="row" justifyContent="flex-end" alignItems="center" component="form" onSubmit={onSubmit}>
            <input className="form-input" type="text" required value={formData} onChange={(e) => setFormData(e.target.value)} placeholder="Search..." style={{ height: '37px', maxWidth: '270px' }} />
            <Button type="submit" variant="contained" startIcon={<Icon type={'Search'} />} sx={{ borderRadius: 0, textTransform: "none", marginLeft: 2 }}>
                Search
            </Button>
            {searched && <Button variant="contained" color="error" startIcon={<Icon type={'SearchOff'} />} sx={{ borderRadius: 0, textTransform: "none", marginLeft: 2 }} onClick={clear}>
                Clear
            </Button>}
        </Stack>
    )
}

export default Search