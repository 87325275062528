const list = {
    header: [
        {
            id: 'reason.en',
            label: 'English',
            orderBy: 'feature.en',
            align: 'left',
        },
        {
            id: 'reason.ar',
            label: 'Arabic',
            orderBy: 'feature.ar',
            align: 'left',
        },
    ],
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "mixed",
            name: "reason",
            label: "Reason",
            elements: [
                {
                    type: "text",
                    name: "en",
                    rich: true,
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    rich: true,
                    label: "Arabic",
                }
            ],
        },
    ]
};
const create = {
    fields: [
        {
            type: "mixed",
            name: "reason",
            label: "Reason",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }
    ]
};
const edit = {
    fields: [
        {
            type: "mixed",
            name: "reason",
            label: "Reason",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        },
    ]
};


const search = {
    fields: [
        {
            type: "text",
            name: "reason.en",
        },
        {
            type: "text",
            name: "reason.ar",
        }
    ]
}

const csv = {
    headers: [
        {
            key: 'reason.en',
            label: 'English',
        },
        {
            key: 'reason.ar',
            label: 'Arabic',
        },
    ]
}


const data = { list, create, edit, show, search, csv }

export default data;