import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { Badge, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Typography } from '@mui/material';
import { authorized } from '../../utils/utils';
import { count_waiting } from '../../features/notifications/actions';
import { hide } from '../../features/notifications/slice';

import Icon from './Icon';

import { useDispatch, useSelector } from 'react-redux';

const Sidebar = ({ drawerWidth, mobileOpen, handleDrawerToggle }) => {
    const audioRef = useRef(null);
    const location = useLocation()
    const [open, setOpen] = useState(null);
    // const [newOpen, setNewOpen] = useState(null);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const waiting = useSelector(state => state.notifications.waiting);
    const play = useSelector(state => state.notifications.playSound);
    const show_popup = useSelector(state => state.notifications.show_popup);

    const sidebar = useSelector((state => state.sidebar.list));
    const user = useSelector((state) => state.auth.user);

    const handleClick = (label) => {
        setOpen({ ...open, [label]: !open[label] });
    };

    const playSound = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    useEffect(() => {
        if(play) {
            playSound();
            // setNewOpen(true);
        }
    }, [play]);

    useEffect(() => {
        dispatch(count_waiting());
        const intervalId = setInterval(() => {
            dispatch(count_waiting());
        }, 3000);

        return () => {
            clearInterval(intervalId);
        }
    }, []);

    useEffect(() => {
        let res = {};
        sidebar.forEach((item) => {
            if (item.route)
                res[item.label] = location.pathname.split('/')[2] ? (location.pathname.split('/')[2].split('%20').join(' ') === item.route) : false
            else {
                if (item.open)
                    res[item.label] = true
                else
                    item.children.forEach((child) => {
                        if (!res[item.label])
                            res[item.label] = location.pathname.split('/')[2] ? (location.pathname.split('/')[2].split('%20').join(' ') === child.route) : false

                    })
            }
        })
        setOpen(res)
    }, [sidebar])

    const drawer =
        user?.Permissions && <>
            <Toolbar >
                <Typography align="center" width="100%" display="none" color="#454655" variant="h6" noWrap component="div" sx={{ display: { lg: 'block' } }}>
                    <Link href="#" color="inherit" underline="none" ><b>Prestige</b></Link>
                </Typography>
            </Toolbar>
            <Divider />
            {open && <List subheader={
                <ListSubheader component="div" id="navigation">
                    Navigation
                </ListSubheader>
            } color="#454655">

                {
                    sidebar.filter((s) => s.children ? (s.children.filter((c) => authorized(user?.Permissions, (c.route_permission ? c.route_permission.toLowerCase() : c.route.toLowerCase()))).length > 0) : authorized(user?.Permissions, (s.route_permission ? s.route_permission.toLowerCase() : s.route.toLowerCase()))).map((item, i) =>
                        <div key={i}>
                            <ListItem disablePadding>
                                {item.route && authorized(user?.Permissions, (item.route_permission ? item.route_permission.toLowerCase() : item.route.toLowerCase()), item.permission ? item.permission : 'list') &&
                                    <ListItemButton component={RouterLink} to={item.route} selected={location.pathname.split('/')[2] ? (location.pathname.split('/')[2] === item.route) : false}>
                                        {item.icon &&
                                            <ListItemIcon>
                                                <Icon type={item.icon} color={item.color ? item.color : 'primary'} />
                                            </ListItemIcon>
                                        }
                                        <ListItemText primaryTypographyProps={{ fontSize: '0.8rem' }} primary={item.label} />
                                    </ListItemButton>
                                    ||
                                    <ListItemButton onClick={() => handleClick(item.label)}>
                                        {item.icon &&
                                            <ListItemIcon>
                                                <Icon type={item.icon} color={item.color ? item.color : 'primary'} />
                                            </ListItemIcon>
                                        }
                                        <ListItemText primaryTypographyProps={{ fontSize: '0.8rem' }} primary={item.label} />
                                        {open[item.label] ? <Icon type='ExpandLess' /> : <Icon type='NavigateBefore' />}
                                    </ListItemButton>
                                }
                            </ListItem>
                            {item.children &&
                                <Collapse in={open[item.label]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.children.filter(item => authorized(user?.Permissions, (item.route_permission ? item.route_permission.toLowerCase() : item.route.toLowerCase()), item.permission ? item.permission : 'list')).map((child, j) =>
                                            <ListItemButton key={j} sx={{ pl: 4 }} component={RouterLink} to={child.route} selected={location.pathname.split('/')[2] ? (location.pathname.split('/')[2].replace('%20', " ") === child.route) : false}>
                                                {child.icon &&
                                                    <ListItemIcon>
                                                        <Icon type={child.icon} color={child.color ? child.color : 'primary'} />
                                                    </ListItemIcon>
                                                }
                                                <ListItemText primaryTypographyProps={{ fontSize: '0.8rem' }} primary={child.label} />
                                                {child.indicator && <Badge badgeContent={waiting} color='info' />}
                                            </ListItemButton>
                                        )}
                                    </List>
                                </Collapse>}
                        </div>
                    )
                }
            </List>}
        </>

    const handleClose = () => {
        dispatch(hide())
    }
    
    // const handleNewClose = () => {
    //     setNewOpen(false);
    // }

    const handleConfirm = () => {
        dispatch(hide());
        navigate('./later-requests');
    }
    
    // const handleNewConfirm = () => {
    //     setNewOpen(false);
    //     navigate('./requests');
    // }

    const renderPopup = () => {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 0 }}
            >
                <DialogTitle id="alert-dialog-title" >
                    New Later Request
                </DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description">
                        A new later request is waiting approval
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button onClick={handleClose} autoFocus color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm}>View</Button>
                </DialogActions>
            </Dialog>
        );
    }

    // const renderNewTripPopup = () => {
    //     return (
    //         <Dialog
    //             open={newOpen}
    //             onClose={handleNewClose}
    //             aria-labelledby="alert-dialog-title"
    //             aria-describedby="alert-dialog-description"
    //             sx={{ borderRadius: 0 }}
    //         >
    //             <DialogTitle id="alert-dialog-title" >
    //                 New Later Request
    //             </DialogTitle>
    //             <DialogContent >
    //                 <DialogContentText id="alert-dialog-description">
    //                     A new request is waiting to be assigned
    //                 </DialogContentText>
    //             </DialogContent>
    //             <DialogActions >
    //                 <Button onClick={handleNewClose} autoFocus color="error">
    //                     Cancel
    //                 </Button>
    //                 <Button onClick={handleNewConfirm}>View</Button>
    //             </DialogActions>
    //         </Dialog>
    //     );
    // };

    return (
        <>
            <Box
                component="nav"
                sx={{ width: { sm: 0 }, flexShrink: { sm: 0 } }}
                aria-label="navigation"
            >
                <div style={{visibility: 'hidden', width: 0, height: 0, position: 'fixed', left: -50000, top: -10000 }}>
                    <audio ref={audioRef}>
                        <source src="https://tedmob-dop-files.s3.amazonaws.com/prestige/cms-notification/horn.mp3" type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
                {show_popup && renderPopup()}
                {/* {renderNewTripPoppup()} */}
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer

                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    )
}

export default Sidebar