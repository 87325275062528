import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '../../layouts/Breadcrumbs'

import { Box, Button, IconButton, Paper, Stack, Typography } from '@mui/material'
import Icon from '../../layouts/Icon';
import Input from '../../layouts/Input';
import PermissionsManager from '../../layouts/PermissionsManager';
import { authorized } from '../../../utils/utils';
import DynamicComponent from '../../layouts/DynamicComponent';


const Show = () => {

    const dispatch = useDispatch();
    let { id } = useParams();

    const [{ page, list, create, get, edit }] = useOutletContext();

    const fields = useSelector((state) => state[page.toString().toLowerCase()].properties.show.fields);
    const buttons = useSelector((state) => state[page.toString().toLowerCase()].properties.show.buttons);
    const user = useSelector((state) => state.auth.user);
    const hide = useSelector((state) => state[page.toString().toLowerCase()].properties.show.hide);
    const item = useSelector((state) => state[page.toString().toLowerCase()].item);

    useEffect(() => {
        dispatch(get(id));
    }, []);

    const getFieldValue = (object, path) => {
        if (!object) {
            return null;
        }
        if (path.split('.').length <= 1) {
            return object[path];
        }
        let firstField = path.split('.')[0];
        let newPathArr = path.split('.');
        newPathArr.shift();
        let newPath = newPathArr.length > 1 ? newPathArr.join('.') : newPathArr.join('');
        let newObject = object[firstField];

        return getFieldValue(newObject, newPath);
    };


    return user?.Permissions && <PermissionsManager action="show" name={page.toLowerCase()} permissions={user?.Permissions}>
        <Box height="100%" >
            {hide?.breadcrumbs !== true && <Breadcrumbs page={page.toString()} text={'Show'} />}
            {/* Header */}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={4}>
                {/* Title */}
                <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: "start", sm: "center" }}>
                    {(hide?.back !== true && authorized(user.Permissions, page.toLowerCase(), 'list')) && <IconButton component={RouterLink} to={`/cms/${page.toString().toLowerCase()}`} aria-label="arrow-back" sx={{ border: "2px solid", borderRadius: "50%" }} disableRipple size="small">
                        <Icon type={'ArrowBack'} />
                    </IconButton>}
                    {hide?.title !== true && <Typography variant="h5" mr={1}>
                        {'Show'}
                    </Typography>}
                </Stack>

                {/* Buttons */}
                <Stack direction="row" spacing={2} mt={{ xs: 2, sm: 0 }} alignSelf={{ xs: "end", sm: "center" }}>
                    {(hide?.edit !== true && authorized(user.Permissions, page.toLowerCase(), 'form')) && <Button component={RouterLink} to='edit' variant="contained" startIcon={<Icon type={'Edit'} />} sx={{ borderRadius: 0 }}>
                        Edit
                    </Button>}
                    {buttons?.map(
                        ({ component, permission, permission_page, parent }) => {
                            return (
                                <>
                                    {authorized(
                                        user.Permissions,
                                        permission_page ?? page.toLowerCase(),
                                        permission
                                    ) && (
                                            <DynamicComponent name={component} parent={parent} />
                                        )}
                                </>
                            );
                        }
                    )}
                </Stack>
            </Stack>

            {/* Body */}
            <Paper elevation={0} mt={2} sx={{ width: '100%', borderRadius: 0, height: { md: "75vh", xs: "60vh" }, padding: 4 }}>
                {item && <Box sx={{ width: '100%', overflowY: 'auto', borderRadius: 0, height: "95%" }}>
                    {fields.map((field, i) =>
                        // <Input field={field} value={field?.child?.length > 0 ? item[field.name][field.child] : item[field.name]} show={true} />
                        <Input field={field} value={field?.child?.length > 0 ? getFieldValue(item, field.name) : getFieldValue(item, field.name)} show={true} />
                    )}
                </Box>}
            </Paper>
        </Box>
    </PermissionsManager >
}

export default Show