import React from 'react'
import { Outlet } from 'react-router-dom'
import { list, get, create, edit, remove, bulk_remove } from '../../../features/vouchers/actions';

const index = () => {

    const page = "Vouchers";
    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Outlet context={[{ page, list, create, get, edit, remove, bulk_remove }]} />
        </div>
    )
}

export default index;