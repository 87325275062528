const list = {
    header: [
        {
            id: 'image',
            display: true,
            order: false,
            label: 'Image',
            align: 'left',
        },
        {
            id: 'title.en',
            label: 'Title',
            orderBy: 'title.en',
            align: 'left',
        },
    ],
    actions: ['show', 'update'],
};
const show = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image",
        },
        {
            type: "text",
            name: "type",
            label: "Type",
        },
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    rich: true,
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    rich: true,
                    label: "Arabic",
                }
            ],
        },
        {
            type: "mixed",
            name: "body",
            label: "Body",
            elements: [
                {
                    type: "text",
                    name: "en",
                    rich: true,
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    rich: true,
                    label: "Arabic",
                }
            ],
        }
    ]
};
const create = {
    fields: [
        {
            type: "image",
            name: "image (264 x 390)",
            label: "Image",
            required: true,
        },
        {
            type: "text",
            name: "type",
            label: "Type",
            required: true,
        }, {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }, {
            type: "mixed",
            name: "body",
            label: "Body",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    rich: true,
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    rich: true,
                    required: true,
                }
            ],
            required: true,
        }
    ]
};
const edit = {
    fields: [
        {
            type: "image",
            name: "image (264 x 390)",
            label: "Image",
        },
        {
            type: "text",
            name: "type",
            label: "Type",
            required: true,
        }, {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }, {
            type: "mixed",
            name: "body",
            label: "Body",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    rich: true,
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    rich: true,
                    required: true,
                }
            ],
            required: true,
        }
    ]
};

const search = {
    fields: [
        {
            type: "text",
            name: "title.en",
        },
        {
            type: "text",
            name: "type",
        },
    ]
}


const data = { list, create, edit, show, search }

export default data;