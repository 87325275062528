const list = {
    header: [
        {
            id: 'name.en',
            label: 'Company Name',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'email',
            label: 'Company Email',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'start',
            label: 'Contract start',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'end',
            label: 'Contract Expiration',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'auth',
            label: 'Auth Code',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'total_employees',
            label: 'Total Employees',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'active_employees',
            label: 'Active Employees',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'created_at',
            label: 'Date Added',
            align: 'left',
            fallback: 'N/A'
        }, 
       
    ],
    permissions: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};

const show = {
    fields: [
        {
            type: 'mixed',
            name: 'name',
            label: 'Name',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                }
            ]
        },
        {
            type: 'text',
            name: 'email',
            label: 'Email'
        },
        {
            type: 'date',
            name: 'contract_start',
            label: 'Contract start',
        },
        {
            type: 'date',
            name: 'contract_expiration',
            label: 'Contract expiration',
        },
    ],
    buttons: [
        {
            component: 'CompanyEmployee',
            parent: 'corporates',
            permission: 'show'
        },
        {
            component: 'CompanyRequests',
            parent: 'corporates',
            permission: 'show'
        }
    ],
    permission: 'show'
};

const create = {
    fields: [
        {
            type: 'mixed',
            name: 'name',
            label: 'Name',
            required: true,
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    required: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    required: true
                }
            ]
        },
        {
            type: 'email',
            name: 'email',
            label: 'Email',
            required: true
        },
        {
            type: 'date',
            name: 'contract_start',
            label: 'Contract start',
            required: true
        },
        {
            type: 'date',
            name: 'contract_expiration',
            label: 'Contract expiration',
            required: true
        },
    ],
    permission: 'create'
};

const edit = {
    fields: [
        {
            type: 'mixed',
            name: 'name',
            label: 'Name',
            required: true,
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    required: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    required: true
                }
            ]
        },
        {
            type: 'email',
            name: 'email',
            label: 'Email',
            required: true
        },
        {
            type: 'date',
            name: 'contract_start',
            label: 'Contract start',
            required: true
        },
        {
            type: 'date',
            name: 'contract_expiration',
            label: 'Contract expiration',
            required: true
        },
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: 'text',
            name: 'name.en'
        }
    ]
};

const csv = {
    headers: list.header.map(item => ({
      key: item.id,
      label: item.label,
    }))
  };

const filter = {
    fields: [
        {
            type: 'text',
            name: 'name.en',
            label: 'Company Name'
        },
        {
            type: 'text',
            name: 'email',
            label: 'Company Email'
        },
        {
            type: 'text',
            name: 'total_employees',
            label: 'Total Employee'
        },
     
        {
            type: 'date',
            name: 'date.from',
            label: 'Contract Start',
        },
        {
            type: 'date',
            name: 'date.to',
            label: 'Contract Expiration',
        }
    ]
}

const data = { list, show, create, edit,  search, csv, filter };

export default data;