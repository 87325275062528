const list = {
    header: [
        {
            id: 'fullName',
            label: 'Name',
        },
        {
            id: 'registered_at',
            label: 'Registration Date'
        }, {
            id: 'email',
            label: 'Email',
        },
        {
            id: 'country_code',
            label: 'Country code',
        },
        {
            id: 'mobile_number',
            label: 'Mobile',
        },
       
       
        {
            id: 'last_login',
            label: 'Last Login'
        },
        {
            id: 'block',
            label: 'Blocked',
            align: 'center',
            switch: true
        },
    ],
    permission: 'list',
    hide: {
        create: true
    },
    actions: ['delete'],
};

const edit = {
    fields: [
        {
            type: "text",
            name: "first_name", 
            label: "First name",
            required: true
        },
        {
            type: "text",
            name: "last_name", 
            label: "Last name",
            required: true
        },
        {
            type: 'password',
            name: 'password',
            label: 'Password',
        },
        {
            type: 'password',
            name: 'confirm_password',
            label: 'Confirm Password',
        },
        {
            type: 'select',
            async: true,
            name: 'corporate_id',
            route: 'corporates',
            label: 'Corporate'
        },
    ]
};

const search = {
    fields: [
        {
            type: 'text',
            name: 'first_name',
        },
        {
            type: 'text',
            name: 'last_name',
        },
        {
            type: 'text',
            name: 'mobile_number',
        },
        {
            type: 'text',
            name: 'email',
        },
    ]
};

const filter = {
    fields: [

        {
            type: 'text',
            name: 'fullName',
            label: 'Name'
        },
        {
            type: 'text',
            name: 'email',
            label: 'Email'
        },
        {
            type: 'number',
            name: 'mobile_number',
            label: 'Mobile Number'
        },
        {
            type: 'date',
            name: 'from',
            label: 'From',
        },
        {
            type: 'date',
            name: 'to',
            label: 'To',
        }
    ]
}

const csv = {
    headers: list.header.map(item => ({
      key: item.id,
      label: item.label,
    }))
  };

const data = { list, search, csv, filter ,edit}

export default data;