const list = {
    header: [
        {
            id: 'name',
            label: 'Name',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'class.title.en',
            label: 'Class',
            orderBy: 'class.title.en',
            align: 'left',
            order: false,
            fallback: 'N/A'

        },
        {
            id: 'plate',
            label: 'Plate',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'image',
            label: 'Image',
            align: 'left',
            order: false,
            display: true,
            fallback: 'N/A'
        },
        {
            id: 'year',
            label: 'Year',
            align: 'left',
            fallback: 'N/A'
        },
        {
            id: 'color',
            label: 'Color',
            align: 'left',
            order: false,
            fallback: 'N/A'
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true,
        }
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};

const show = {
    fields: [
        {
            type: "text",
            name: "name",
            label: "Name",
        },
        {
            type: "mixed",
            name: "class.title",
            label: "Class",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ]
        },
        {
            type: 'image',
            name: 'image',
            label: 'Image (square)',
        },
        {
            type: "mixed",
            name: "description",
            label: "Description",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "text",
            name: "color",
            label: "Color",
        },
        {
            type: 'text',
            name: 'plate',
            label: 'Plate',
        },
        {
            type: 'number',
            name: 'year',
            label: 'Year',
        },
        {
            type: 'number',
            name: 'mileage',
            label: 'Mileage',
        },
        {
            type: 'number',
            name: 'top_speed',
            label: 'Top Speed',
        },
        {
            type: 'text',
            name: 'engine_size',
            label: 'Engine Size',
        },
        {
            type: 'text',
            name: 'max_power',
            label: 'Max power',
        },
        {
            type: "switch",
            name: "publish",
            label: "Publish",
        },
    ],
    permission: 'show'
};

const create = {
    fields: [
        {
            type: "text",
            name: "name",
            label: "Name",
            required: true
        },     
       
   
        {
            type: 'image',
            name: 'image',
            label: 'Image',
            required: true
        },
        {
            type: "mixed",
            name: "description",
            label: "Description",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "text",
            name: "color",
            label: "Color",
            required: true
        },
        {
            type: 'text',
            name: 'plate',
            label: 'Plate',
            required: true
        },
        {
            type: 'number',
            name: 'year',
            label: 'Year',
            required: true
        },
        {
            type: 'number',
            name: 'mileage',
            label: 'Mileage',
            required: true
        },
        {
            type: 'number',
            name: 'top_speed',
            label: 'Top Speed',
            required: true
        },
        {
            type: 'text',
            name: 'engine_size',
            label: 'Engine Size',
        },
        {
            type: 'text',
            name: 'max_power',
            label: 'Max power',
        },
        {
            type: 'select',
            async: true,
            name: 'car_features',
            route: 'features',
            label: 'Car features',
            multiple: true,
        },
        {
            type: "select",
            async: true,
            name: "class_id",
            label: "Class",
            required: true,
            route: 'classes'
        }, 
        {
            type: "switch",
            name: "publish",
            label: "Publish",
        },
    ],
    permission: 'create'
};

const edit = {
    fields: [
        {
            type: "text",
            name: "name",
            label: "Name",
            required: true
        },
        {
            type: "text",
            name: "plate",
            label: "Plate",
            required: true
        }, 
        {
            type: "select",
            async: true,
            name: "class_id",
            label: "Class",
            required: true,
            route: 'classes'
        }, 
        {
            type: "select",
            async: true,
            name: "class_id",
            label: "Class",
            required: true,
            route: 'classes'
        }, 
        {
            type: 'image',
            name: 'image',
            label: 'Image',
        },
        {
            type: "mixed",
            name: "description",
            label: "Description",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "text",
            name: "color",
            label: "Color",
            required: true
        },
        {
            type: 'text',
            name: 'plate',
            label: 'Plate',
            required: true
        },
        {
            type: 'number',
            name: 'year',
            label: 'Year',
            required: true
        },
        {
            type: 'number',
            name: 'mileage',
            label: 'Mileage',
            required: true
        },
        {
            type: 'number',
            name: 'top_speed',
            label: 'Top Speed',
            required: true
        },
        {
            type: 'text',
            name: 'engine_size',
            label: 'Engine Size',
        },
        {
            type: 'text',
            name: 'max_power',
            label: 'Max power',
        },
        {
            type: 'select',
            async: true,
            name: 'car_features',
            route: 'features',
            label: 'Car features',
            multiple: true,
        },
        {
            type: "switch",
            name: "publish",
            label: "Publish",
        },
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: "text",
            name: "name",
        },
        {
            type: "text",
            name: "class",
        },
        {
            type: "text",
            name: "plate",
        },
        {
            type: "number",
            name: "year",
        }
    ]
}

const filter = {
    fields: [
        {
            type: 'text',
            name: 'name',
            label: 'Name'
        },
        {
            type: 'select',
            name: 'class_id',
            label: 'Car Class',
            async: true,
            route: 'classes-list'
        },
        {
            type: 'text',
            name: 'plate',
            label: 'Plate'
        },
        {
            type: 'text',
            name: 'color',
            label: 'Color'
        },
      
        {
            type: 'text',
            name: 'year',
            label: 'Year'
        },
        {
            type: 'select',
            name: 'publish',
            label: 'Publish',
            values: [
                {value: true, label: 'Published'},
                {value: false, label: 'Not Published'},
            ]
        },
    ]
}


const csv = {
    headers: list.header.map(item => ({
      key: item.id,
      label: item.label,
    }))
  };


const data = { list, create, edit, show, search, csv, filter }

export default data;