import { createAsyncThunk } from "@reduxjs/toolkit";
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from "../../utils/api";
import { setAlert } from "../alerts/actions";

export const list = createAsyncThunk("notifications/list", async (params = { page: 0, limit: 10, orderBy: null, orderDirection: null, filter: null, search: null }) => {
    try {
        const page = params.page ? params.page : 0
        const limit = params.limit ? params.limit : 10
        const res = await api.get(`/admin/notifications`, { params });
        
        
        return { ...res.data, page, limit };
    } catch (error) {
        console.log(error);
    }
});

export const get = createAsyncThunk("notifications/get", async (id, { dispatch }) => {
    try {
        const res = await api.get(`/admin/notifications/${id}`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const count = createAsyncThunk("notifications/count", async (id, { dispatch }) => {
    try {
        const res = await api.get(`/admin/notifications/count/unread`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const count_waiting = createAsyncThunk("notifications/waiting", async (id, { dispatch }) => {
    try {
        const res = await api.get(`/admin/notifications/count/waiting`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});