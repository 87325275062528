import React, { useState } from "react";
import { PhoneNumberUtil } from "google-libphonenumber";
import "./style.css";

function DeleteAccount() {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [isDeleted, setIsDeleted] = useState(false); // State to track successful deletion
  const phoneUtil = PhoneNumberUtil.getInstance();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setError("");
  };

  const validatePhoneNumber = (number) => {
    try {
      // if number doesn't have a + sign, add it
      if (!number.startsWith("+")) {
        number = `+${number}`;
      }
      const parsedNumber = phoneUtil.parseAndKeepRawInput(number);
      return phoneUtil.isValidNumber(parsedNumber);
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validatePhoneNumber(inputValue)) {
      // fetch api call to this same url with the phone number
      // if successful, set isDeleted
      fetch("/api/auth/delete-request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifier: inputValue }),
      });
      setIsDeleted(true); // Set the state to indicate success
    } else {
      setError("Invalid phone number. Please enter a valid phone number.");
    }
  };

  return (
    <div className="delete-account-container">
      {/* logo */}
      <img src="/logo.svg" alt="logo" className="logo" />
      {isDeleted ? (
        <div className="success-message">
          <h2>The delete request for your account has been received successfully</h2>
        </div>
      ) : (
        <form className="delete-account-form" onSubmit={handleSubmit}>
          <h1 className="delete-account-title">
            We're sorry to see you leave. Our support team will contact you later.
          </h1>
          <label className="delete-account-label">
            Enter your phone number to delete your account{" "}
          </label>

          <input
            type="text"
            className="delete-account-input"
            value={inputValue}
            onChange={handleInputChange}
            required
          />
          {error && <p className="delete-account-error">{error}</p>}
          <button type="submit" className="delete-account-submit-button">
            Submit
          </button>

          <p className="delete-account-note">
            Deleting your account will remove all your information from our
            database. This cannot be undone.
          </p>
        </form>
      )}
    </div>
  );
}

export default DeleteAccount;
