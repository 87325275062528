import Select from 'react-select'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Divider, Typography } from '@mui/material';

const SelectList = ({ field, value, onChange, updateForm, show, id = null, filter=null, initial_update = false }) => {
    const { label, name } = field

    const [options, setOptions] = useState([]);
    const [initialOptions, setInitialOptions] = useState([]);

    const getData = async () => {
        if (field.async) {
            const res = await axios.get(`/admin/select/${field.route ? field.route : name}${field?.with_id && id ? id : ''}`)
            setOptions(res.data.data);
            setInitialOptions(res.data.data);
        } else {
            setOptions(field.values ? field.values : []);
            setInitialOptions(field.values ? field.values : [])
        }
    }
    useEffect(() => {
        if (!show){
            getData();
        }
    }, [show]);

    useEffect(() => {
        if(initial_update && options?.length > 0) {
            let selected_value = null;
            if(field?.multiple) {
                selected_value = options?.filter((a) => value?.some((b) => a?.value == b?.value));  
            } else {
                selected_value = options?.filter((option) => (option?.value == value))[0]
            } 
            onChange(selected_value);
        }
    }, [options, initial_update]);

    useEffect(() => {
        if(filter != null) {
            const opt = [...initialOptions].filter(el => el?.filter == filter);
            setOptions(opt);
        } else {
            getData();
        }
    }, [filter])

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "0px",
            transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
            color: '#454655',
            fontSize: '14px',
            lineHeight: '24px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '14px',
            lineHeight: '24px',
        }),
        input: (provided, state) => ({
            ...provided,
            boxSizing: "border-box",
            color: '#454655',
            background: 'transparent',
            fontSize: '14px',
            lineHeight: '24px',
            outline: 'none',
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',

        }),
        multiValue: (provided, state) => ({
            ...provided,
            borderRadius: "0",
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            borderRadius: "0",
        }),
        placeholder: (provided, state) => ({
            ...provided,
            fontSize: '14px',
            lineHeight: '24px',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "0",
            width: "99%",
            marginLeft: "5px"
        }),
    };

    return (
        show ?
            <Box mb={3} >
                <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                    {field.label}
                </Typography>
                <Typography mb={3} >
                    {field.values ? field?.values?.filter((option) => (option.value === parseInt(value)))[0]?.label : value}
                </Typography>
                <Divider />
            </Box> :
            <div className="form-control">
                <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={name}>{label}</label>
                <Select
                    options={options}
                    onChange={(value) => {
                        if(initial_update) {
                            onChange(value);
                        }
                        updateForm(name, field?.multiple ? value : value.value);
                        if(field?.with_type) {
                            updateForm('type', value.type);
                        }
                    }}
                    styles={customStyles}
                    placeholder={field.placeholder ? field.placeholder : ''}
                    isMulti={field?.multiple}
                    isDisabled={field?.disabled}
                    menuPlacement="auto"
                    value={field?.multiple ? options?.filter((a) => value?.some((b) => a?.value == b?.value)) : options?.filter((option) => (option?.value == value))[0]}
                />
            </div>
    )
}

export default SelectList