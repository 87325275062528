const list = {
    header: [
        {
            id: 'text.en',
            label: 'Type',
            orderBy: 'text.en',
            align: 'left',
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true,
        },
    ],
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "mixed",
            name: "text",
            label: "Type",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
    ]
};
const create = {
    fields: [
        {
            type: "mixed",
            name: "text",
            label: "Type",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }
    ]
};
const edit = {
    fields: [
        {
            type: "mixed",
            name: "text",
            label: "Type",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
            required: true,
        }
    ]
};

const search = {
    fields: [

        {
            type: "text",
            name: "text.en",
        },
    ]
}


const data = { list, create, edit, show, search }

export default data;