import React from 'react';
import dayjs from 'dayjs';
import { Box, Divider, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';


const Date = ({ field, value, onChange, show }) => {
    const { label, name, past_disabled = false, future_disabled = false, with_time = false } = field;
    return (
        show ?
            <Box mb={3} >
                <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                    {field.label}
                </Typography>
                <Typography mb={3} >
                    {value}
                </Typography>
                <Divider />
            </Box> :
            <div className="form-control">
                <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={name}>{label}</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {
                      with_time ? 
                        (
                          <DateTimePicker
                              required={field?.required}
                              value={dayjs(value)}
                              name={name}
                              id={name}
                              className='form-input'
                              sx= {{
                                border: 'none'
                              }}
                              disablePast = {past_disabled}
                              disableFuture = {future_disabled}
                              onChange={(newValue) => {
                                  const date = newValue.format('YYYY-MM-DD HH:mm:ss');
                                  onChange({target: { id: name, value: date } })
                              }}
                              label=""
                              format="DD/MM/YYYY HH:mm:ss"
                          />
                        ) 
                      : 
                        (
                          <DatePicker
                              required={field?.required}
                              value={dayjs(value)}
                              name={name}
                              id={name}
                              sx={{
                                border: 'none'
                              }}
                              className='form-input'
                              disablePast = {past_disabled}
                              disableFuture = {future_disabled}
                              onChange={(newValue) => {
                                  const date = newValue.format('YYYY-MM-DD');
                                  onChange({target: { id: name, value: date } })
                              }}
                              label=""
                              format="YYYY-MM-DD"
                          />
                        )
                    }
                </LocalizationProvider>    
            </div>

    )
}

export default Date