import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { list } from '../../features/tracking/actions';
import { GoogleMap, Marker, useJsApiLoader, InfoWindowF } from '@react-google-maps/api';

const container_style = {
    width: '100%',
    height: '85%'
};
const defaultCenter = {
    lat: 25.2548,
    lng: 51.4839
};

const Tracking = ({ page }) => {
    // redux variables
    const dispatch = useDispatch();
    const { items, itemsLoading } = useSelector((state) => state[page.toString().toLowerCase()]);

    // Maps variables
    const [map, setMap] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);
    const [markerInfo, setMarkerInfo] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [driversLocations, setDriversLocations] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);

    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4',
    //     nonce: 'sdsdadsaadas'
    // });

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4',
        nonce: 'sdsdadsaadas',
        libraries: ['places']
    })

    const update_locations = () => {
        dispatch(list());
    }

    // Get Current location of the logged in user on screen load
    useEffect(() => {
        // Use browser's geolocation API to get the current location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                error => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }

        const intervalId = setInterval(() => {
            update_locations()
        }, 10000);

        return () => {
            clearInterval(intervalId);
        }
    }, []);

    // retrive data on screen load
    useEffect(() => {
        if (itemsLoading) {
            dispatch(list());
        }
    }, [itemsLoading]);

    useEffect(() => {
        if (driversLocations.length && currentLocation) {
            setIsLoading(false);
        }
    }, [driversLocations, currentLocation]);

    // map data to match the maps requirement
    useEffect(() => {
        if (items && items?.length) {
            const locations = items.map(el => ({ location: el.location, name: el.driverName, mobile: el.fullMobile, online: el.online, car: el.car ?? null }));
            setDriversLocations(locations);
        }
    }, [items]);

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onMarkerClick = (location, info) => {
        // Show the InfoWindow when marker is hovered
        setInfoWindow(location);
        setMarkerInfo(info);
    };

    const onInfoWindowClose = () => {
        // Close the InfoWindow when the close button is clicked
        setInfoWindow(null);
    };

    return (
        isLoading ?
            (isLoaded && <GoogleMap
                mapContainerStyle={container_style}
                center={defaultCenter}
                zoom={11}
            >
            </GoogleMap>)
            :
            (isLoaded && <GoogleMap
                mapContainerStyle={container_style}
                center={defaultCenter}
                zoom={9}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{ streetViewControl: false }}
            >
                {driversLocations.map((el, index) => <Marker
                    key={index}
                    position={el.location}
                    label={el.name}
                    icon={el?.online ? {
                        url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png', // URL to the blue marker icon
                        scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
                    } : null}
                    onMouseOver={() => onMarkerClick(el.location, el)}
                    onMouseOut={onInfoWindowClose}
                />)}
                <Marker
                    onMouseOver={() => onMarkerClick(currentLocation, null)}
                    position={currentLocation}
                    label={"My Location"}
                />
                {infoWindow && (
                    <InfoWindowF
                        position={infoWindow}
                        onCloseClick={onInfoWindowClose}
                        options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                    >
                        {markerInfo ? (
                            <div>
                                <h2>
                                    {markerInfo?.name}
                                    <span style={{ color: markerInfo?.online ? 'green' : 'red' }}>
                                        ({markerInfo?.online ? 'Online' : 'Offline'})
                                    </span>
                                </h2>
                                <p><b>mobile: </b> {markerInfo?.mobile}</p>
                                {markerInfo?.car && (
                                    <>
                                        <h3>Car Info: </h3>
                                        <p><b>Name: </b> {markerInfo?.car?.name} ({markerInfo?.car?.year})</p>
                                        <p><b>Plate: </b> {markerInfo?.car?.plate}</p>

                                    </>
                                )}
                            </div>
                        ) : (
                            <div>
                                <p>Your Current location</p>
                            </div>
                        )}
                    </InfoWindowF>
                )}
            </GoogleMap>)
    )
}

export default Tracking;