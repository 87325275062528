
const edit = {
    fields: [
        {
            type: "text",
            name: "host",
            label: "Host",
            required: true,
        }, {
            type: "email",
            name: "username",
            label: "Username",
            required: true,
        }, {
            type: "password",
            name: "password",
            label: "Password",
            required: true,
        }, {
            type: "text",
            name: "port",
            label: "Port",
            required: true,
        }
    ],
    main: true,
    breadcrumb: 'SMTP Settings',
    title: 'SMTP Settings',
};

const data = { edit }

export default data;