import { createSlice } from "@reduxjs/toolkit";
import { list } from "./actions";

// State on app load and refresh
const initialState = {
    items: {
        ODR: 0,
        ODB: 0,
        OFD: 0,
        ODOt: 0,
        ODNRT: 0,
        total: 0,
        walkin: 0,
        today_ios: 0,
        nrt_trips: 0,
        today_trips: 0,
        today_android: 0,
        voucher_trips: 0,
        today_requests: 0,
        today_registration: 0,
        nrt_drivers: [],
        busy_drivers: [],
        notifications: [],
        online_drivers: [],
        offline_drivers: [],
        drivers_on_trip: [],
    }, // will hold the list of items
    count: null,
    itemsLoading: true,
    item: null, // will hold one item for edit
    itemLoading: true,
    page_number: 0,
    limit: 10,
};

// Create the Users Slice
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        // Resets the state but keeps the data
        reset: (state) => {
            state.items = null// will hold the list of items
            state.itemsLoading = false
            state.item = null// will hold one item for edit
            state.itemLoading = false
        },
    },
    // pending: request send but response hasn't returned yet
    // fullfilled: response returned with successfull status code
    // rejected: request failed or response returned with error status code
    extraReducers: (builder) => {
        builder.addCase(list.pending, (state) => {
            // state.itemsLoading = true
        })
            .addCase(list.fulfilled, (state, { payload }) => {
                state.items = payload.data;
                state.itemsLoading = false
            })
            .addCase(list.rejected, (state) => {
                state.itemsLoading = false
            })
    },
});

// Export the slice actions
export const { reset } = dashboardSlice.actions;

// Export the slice reducer
export default dashboardSlice.reducer;
