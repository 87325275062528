import { createAsyncThunk } from "@reduxjs/toolkit";
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from "../../utils/api";
import { setAlert } from "../alerts/actions";

export const list = createAsyncThunk("gates/list", async (params = { page: 0, limit: 10, orderBy: null, orderDirection: null, filter: null, search: null }) => {
    try {
        const page = params.page ? params.page : 0
        const limit = params.limit ? params.limit : 10
        const res = await api.get(`/admin/gate`, { params });
        
        return { ...res.data, page, limit };
    } catch (error) {
        console.log(error);
    }
});

export const create = createAsyncThunk("gates/create", async ({ data, navigate }, { dispatch }) => {
    try {
        const res = await api.post('/admin/gate', data);
        dispatch(list());
        dispatch(setAlert({ message: 'Gate Added successfully', alertType: 'success' }))
        if (navigate)
            navigate(`/cms/gates`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const get = createAsyncThunk("gates/get", async (id, { dispatch }) => {
    try {
        const res = await api.get(`/admin/gate/${id}`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const edit = createAsyncThunk("gates/edit", async ({ id, data, navigate }, { dispatch }) => {
    try {
        
        await api.put(`/admin/gate/${id}`, data);
        dispatch(setAlert({ message: 'Gate updated successfully', alertType: 'success' }))
        dispatch(list());
        if (navigate)
            navigate(`/cms/gates`);
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const remove = createAsyncThunk("gates/remove", async (id, { dispatch }) => {
    try {
        await api.delete(`/admin/gate/${id}`);
        dispatch(setAlert({ message: 'Gate removed successfully', alertType: 'error' }))
        dispatch(list());
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const bulk_remove = createAsyncThunk("gates/bulk-remove", async ({ data }, { dispatch }) => {
    try {
        await api.post(`/admin/gate/delete`, data);
        dispatch(setAlert({ message: 'Gates removed successfully', alertType: 'error' }))
        dispatch(list());
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});