import React from 'react'
import { Outlet } from 'react-router-dom'
import { list, get } from '../../../features/notifications/actions';

const index = () => {

    const page = "Notifications";
    const auto_sync = true
    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Outlet context={[{ page, list, get, auto_sync }]} />
        </div>
    )
}

export default index;