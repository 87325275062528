const bulk = {
    fields: [
        {
            type: 'select',
            async: true,
            name: 'driver_ids',
            label: 'Drivers',
            multiple: true,
            route: 'drivers-player'
        },
        {
            type: 'text',
            name: 'subject',
            label: 'Subject',
            required: true,
        },
        {
            type: 'text',
            name: 'message',
            label: 'Message',
            required: true
        },
        {
            type: 'image',
            name: 'image',
            label: 'Image',
        },
    ]
};

const single = {
    fields: [
        {
            type: 'select',
            async: true,
            name: 'driver_id',
            label: 'Driver',
            route: 'drivers-mobile'
        },
        {
            type: 'text',
            name: 'subject',
            label: 'Subject',
            required: true,
        },
        {
            type: 'text',
            name: 'message',
            label: 'Message',
            required: true
        },
        {
            type: 'image',
            name: 'image',
            label: 'Image',
        },
    ]
}

const data = { bulk, single };

export default data;