const list = {
    header: [
        {
            id: 'title.en',
            label: 'Title',
            align: 'left',
        },
        {
            id: 'icon',
            label: 'Icon',
            align: 'left',
            display: true
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true
        },
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "mixed",
            name: "service.title",
            label: "Service",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "image",
            name: "icon",
            label: "Icon",
        },
    ],
    permission: 'show'
};
const create = {
    fields: [
        {
            type: "mixed",
            name: "title",
            label: "Title",
            required: true,
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
        },
        {
            type: 'select',
            async: true,
            name: 'service_id',
            label: 'Service',
            route: 'services',
            required: true,
        },
        {
            type: "image",
            name: "icon",
            label: "Icon",
            required: true,
        },
    ],
    permission: 'create'
};
const edit = {
    fields: [
        {
            type: "mixed",
            name: "title",
            label: "Title",
            required: true,
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
        },
        {
            type: 'select',
            async: true,
            name: 'service_id',
            label: 'Service',
            route: 'services'
        },
        {
            type: "image",
            name: "icon",
            label: "Icon",
        },
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: "text",
            name: "title.en",
        },
        {
            type: "text",
            name: "service.title.en",
        },
    ]
}

const order = {
    fields: [
        {
            id: 'image',
            display: true,
            label: 'Image',
            align: 'left',
        },
        {
            id: 'title',
            child: 'en',
            label: 'Title',
            align: 'left',
        },
    ],
    permission: 'order'
};

const csv = {
    headers: [
        {
            key: 'title.en',
            label: 'Title',
        },
        {
            key: 'icon',
            label: 'Icon',
        },
        {
            key: 'publish',
            label: 'Publish',
        },
    ]
}

const data = { list, create, order, edit, show, search, csv }

export default data;