import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '../../layouts/Breadcrumbs'

import { Box, Button, IconButton, Paper, Stack, Typography } from '@mui/material'
import Icon from '../../layouts/Icon';
import Input from '../../layouts/Input';
import { authorized, initialState } from '../../../utils/utils';
import PermissionsManager from '../../layouts/PermissionsManager';
import DynamicComponent from '../../layouts/DynamicComponent';



const Form = ({ type }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { id } = useParams();

    const [{ page, list, create, get, edit }] = useOutletContext();

    const properties = useSelector((state) => state[page.toString().toLowerCase()].properties[type]);
    const { fields, buttons } = properties;
    const user = useSelector((state) => state.auth.user);
    const item = useSelector((state) => state[page.toString().toLowerCase()].item);

    const [formData, setFormData] = useState(null);

    const onChange = (e) => {
        if (e?.target) {
            setFormData({ ...formData, [e.target.id]: e.target.value });
        }
    };

    const updateForm = (key, value) => {
        setFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (type === "create") {
            dispatch(create({ data: formData, navigate }),);
        } else {
            dispatch(edit({ id, data: formData, navigate }));
        }
    }

    useEffect(() => {
        if (type === "edit") {
            dispatch(get(id));
        } else {
            setFormData(initialState(fields));
        }
    }, []);

    useEffect(() => {
        if (item) {
            if ((item.id == id) || properties.main) {
                let form = initialState(fields);
                for (const key in item) {
                    if (form[key] !== undefined) {
                        form = { ...form, [key]: item[key] }
                    }
                }
                setFormData(form)
            }
        }
    }, [item])

    return user?.Permissions && <PermissionsManager action="form" name={page.toLowerCase()} permissions={user?.Permissions}>
        <Box height="100%" >
            <Breadcrumbs page={page.toString()} main={properties.main} text={properties.breadcrumb ? properties.breadcrumb : (type === 'create' ? 'Create new' : 'Update')} />
            {/* Header */}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={4}>
                {/* Title */}
                <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: "start", sm: "center" }}>
                    {(properties.main || !authorized(user.Permissions, page.toLowerCase(), 'list')) ? <></> : <IconButton component={RouterLink} to={`/cms/${page.toString().toLowerCase()}`} aria-label="arrow-back" sx={{ border: "2px solid", borderRadius: "50%" }} disableRipple size="small">
                        <Icon type={'ArrowBack'} color={'gray'} />
                    </IconButton>}
                    <Typography variant="h5" mr={1}>
                        {properties.title ? properties.title : (type === 'create' ? "Create New" : "Edit")}
                    </Typography>
                </Stack>

                {/* Buttons */}
                <Stack
                    direction="row"
                    spacing={2}
                    mt={{ xs: 2, sm: 0 }}
                    alignSelf={{ xs: "end", sm: "center" }}
                >
                    {buttons?.map(
                        ({ component, permission, permission_page, parent }) => {
                            return (
                                <>
                                    {authorized(
                                        user.Permissions,
                                        permission_page ?? page.toLowerCase(),
                                        permission
                                    ) && (
                                            <DynamicComponent name={component} parent={parent} />
                                        )}
                                </>
                            );
                        }
                    )}
                    {/* <Button component={RouterLink} to="add" variant="contained" startIcon={<AddIcon />} sx={{ borderRadius: 0 }}>
                        Edit
                    </Button>
                    <Button variant="outlined" startIcon={<TuneIcon />} sx={{ borderRadius: 0 }} onClick={toggle(true)}  >
                                Filter
                    </Button> */}
                </Stack>
            </Stack>

            {/* Body */}
            <Paper component='form' elevation={0} mt={2} sx={{ width: '100%', borderRadius: 0, height: { md: "75vh", xs: "60vh" }, padding: 4 }} onSubmit={(e) => onSubmit(e)}>
                {formData && <><Box sx={{ width: '100%', overflowY: 'auto', borderRadius: 0, height: "95%" }}>
                    {fields.map((field, i) => {
                        let depend_on_value = null;
                        if (field.depend_on) {
                            depend_on_value = formData[field.depend_on];
                            switch (field.depend_action) {
                                case 'show': {
                                    return depend_on_value == field.depend_value ? (
                                        <Input formData={formData} field={field} onChange={onChange} value={formData[field.name]} key={i} updateForm={updateForm} id={id} />
                                    ) : null;
                                }
                                case 'hide': {
                                    return depend_on_value != field.depend_value ? (
                                        <Input formData={formData} field={field} onChange={onChange} value={formData[field.name]} key={i} updateForm={updateForm} id={id} />
                                    ) : null;
                                }
                                case 'filter': {
                                    return <Input formData={formData} field={field} onChange={onChange} value={formData[field.name]} key={i} updateForm={updateForm} id={id} filter={depend_on_value} />
                                }
                            }
                        } else {
                            return (
                                <Input field={field} formData={formData} onChange={onChange} value={formData[field.name]} key={i} updateForm={updateForm} id={id} />
                            );
                        }
                    })}
                </Box>
                    <Stack direction="row" width="100%" spacing={1} alignItems="center" justifyContent='center'>
                        <Button type="submit" variant="contained" sx={{ borderRadius: 0, padding: '8px 48px', textTransform: 'none' }}>
                            Save
                        </Button>
                    </Stack></>}
            </Paper>
        </Box>
    </PermissionsManager >

}

export default Form