import { createSlice } from "@reduxjs/toolkit";
import properties from "./properties"
import { create, list, get } from "./actions";

// State on app load and refresh
const initialState = {
    items: [], // will hold the list of items
    count: null,
    itemsLoading: true,
    item: null, // will hold one item for edit
    itemLoading: true,
    page_number: 0,
    limit: 10,
    properties
};

// Create the Users Slice
const gateSlice = createSlice({
    name: "gates",
    initialState,
    reducers: {
        // Resets the state but keeps the data
        reset: (state) => {
            state.items = null// will hold the list of items
            state.itemsLoading = false
            state.item = null// will hold one item for edit
            state.itemLoading = false
        },
    },
    // pending: request send but response hasn't returned yet
    // fullfilled: response returned with successfull status code
    // rejected: request failed or response returned with error status code
    extraReducers: (builder) => {
        builder.addCase(list.pending, (state) => {
            // state.itemsLoading = true
        })
            .addCase(list.fulfilled, (state, { payload }) => {
                state.items = payload.data;
                state.count = payload.count;
                state.page_number = payload.page;
                state.limit = payload.limit;
                state.itemsLoading = false
            })
            .addCase(list.rejected, (state) => {
                state.itemsLoading = false
            })

            .addCase(get.pending, (state) => {
                state.itemLoading = true;
            })
            .addCase(get.fulfilled, (state, { payload }) => {
                state.itemLoading = false;
                state.item = payload.data;
            })
            .addCase(get.rejected, (state) => {
                state.itemLoading = false;
            })

            .addCase(create.pending, (state) => {

            })
            .addCase(create.fulfilled, (state, { payload }) => {

            })
            .addCase(create.rejected, (state) => {
            })
    },
});

// Export the slice actions
export const { reset } = gateSlice.actions;

// Export the slice reducer
export default gateSlice.reducer;
