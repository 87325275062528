const list = {
    header: [
        {
            id: 'title.en',
            label: 'Title',
            align: 'left',
        },
        {
            id: 'subtitle.en',
            label: 'Subtitle',
            align: 'left',
        },
        {
            id: 'image',
            label: 'Image',
            align: 'left',
            display: true
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true
        },
        {
            id: 'available',
            label: 'available',
            align: 'left',
            switch: true
        },
    ],
    permission: 'list',
    hide: {
        create: true
    },
    actions: ['show', 'update'],
};
const show = {
    fields: [
        {
            type: "mixed",
            name: "title",
            label: "Title",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "mixed",
            name: "subtitle",
            label: "Subtitle",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
        {
            type: "image",
            name: "icon",
            label: "Icon",
        },
        {
            type: "image",
            name: "image",
            label: "Image",
        },
        {
            type: "mixed",
            name: "header",
            label: "Header",
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                }
            ],
        },
    ],
    permission: 'show'
};
const create = {
    fields: [
        {
            type: "mixed",
            name: "title",
            label: "Title",
            required: true,
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
        },
        {
            type: "mixed",
            name: "subtitle",
            label: "Subtitle",
            required: true,
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
        },
        {
            type: "image",
            name: "icon",
            label: "Icon",
            required: true,
        },
        {
            type: "image",
            name: "image",
            label: "Image (264 x 390)",
            required: true,
        },
        {
            type: "mixed",
            name: "header",
            label: "Header",
            required: true,
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
        },
    ],
    permission: 'create'
};
const edit = {
    fields: [
        {
            type: "mixed",
            name: "title",
            label: "Title",
            required: true,
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
        },
        {
            type: "mixed",
            name: "subtitle",
            label: "Subtitle",
            required: true,
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
        },
        {
            type: "image",
            name: "icon",
            label: "Icon",
        },
        {
            type: "image",
            name: "image",
            label: "Image (264 x 390)",
        },
        {
            type: "mixed",
            name: "header",
            label: "Header",
            required: true,
            elements: [
                {
                    type: "text",
                    name: "en",
                    label: "English",
                    required: true,
                }, {
                    type: "text",
                    name: "ar",
                    label: "Arabic",
                    required: true,
                }
            ],
        },
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: "text",
            name: "title.en",
        },
        {
            type: "text",
            name: "subtitle.en",
        },
        {
            type: "text",
            name: "header.en",
        },
    ]
}

const order = {
    fields: [{
        id: 'image',
        display: true,
        label: 'Image',
        align: 'left',
    },
    {
        id: 'title',
        child: 'en',
        label: 'Title',
        align: 'left',
    },],
    permission: 'order'
};

const csv = {
    headers: [
        {
            label: 'Title',
            key: 'title.en'
        },
        {
            label: 'Subtitle',
            key: 'subtitle.en'
        },
        {
            key: 'publish',
            label: 'Publish'
        }
    ]
}

const data = { list, create, order, edit, show, search, csv }

export default data;