import { createAsyncThunk } from "@reduxjs/toolkit";
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from "../../utils/api";
import { setAlert } from "../alerts/actions";

export const Single = createAsyncThunk("userpush/single", async ({ data }, { dispatch }) => {
    try {
        const res = await api.post('/admin/userpush/single', data);
        if(res.data.status === 'success') {
            dispatch(setAlert({ message: 'Notification sent successfully', alertType: 'success' }))
        } else {
            dispatch(setAlert({ message: 'Something went wrong!', alertType: 'error' }))
        }
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const Bulk = createAsyncThunk("userpush/bulk", async ({ data }, { dispatch }) => {
    try {
        const res = await api.post(`/admin/userpush/bulk`, data);
        if(res.data.status === 'success') {
            dispatch(setAlert({ message: 'Notification sent successfully', alertType: 'success' }))
        } else {
            dispatch(setAlert({ message: 'Something went wrong!', alertType: 'error' }))
        }
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});