import { Box, Paper, Typography } from '@mui/material';
import React from 'react'
import Icon from './Icon';

const Square = ({title, background_color, icon_type, value, full_width, is_icon = true, onclick = null, describedby}) => {
    return (
        <Paper aria-describedby={describedby} onClick={onclick} elevation={1} style={{ width: full_width ? '100%' : '50%', padding: 20, cursor: onclick ? 'pointer' : 'default' }}>
            <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{title}</Typography>
            <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }} mt={2} flex={1} gap={2}>
                <div style={{ width: 60, height: 60, background: background_color, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                    { is_icon ? <Icon type={icon_type} style={{ color: 'white' }} fontSize={'large'} /> : icon_type }
                </div>
                <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>{value}</Typography>
            </Box>
        </Paper>
    )
}

export default Square;